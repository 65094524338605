import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { UsersApi } from '../api';
import { PhotoType } from '../model';
import { useProfile } from './useProfile';

export function usePhotos() {
  const queryClient = useQueryClient();
  const { profile } = useProfile();

  const add = useCallback(
    async (type: PhotoType, photo: File): Promise<void> => {
      if (!profile) {
        return;
      }

      const { image_url, image_url_key } =
        await UsersApi.createPresignedImageUrl(type);
      await fetch(image_url, {
        method: 'PUT',
        body: photo,
      });
      if (type === 'primary') {
        await UsersApi.changeAvatar(profile.id, image_url_key);
      }
      await queryClient.refetchQueries('me');
    },
    [profile, queryClient],
  );

  return {
    add,
  };
}
