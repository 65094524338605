import { FC, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ApiError, CreateOrderParams, OrdersApi } from '../../api';
import { RoutePath } from '../../config';
import { useAppDispatch, useAppSelector, useShoppingCart } from '../../hooks';
import { Order } from '../../model';
import { orderSlice, selectIsAuthorized, selectOrder } from '../../redux';
import { Alert, Button, Money, Paragraph } from '../atoms';
import { Widget } from './Widget';

interface Props {
  mode?: 'cart' | 'checkout';
}

export const ShoppingCartSummary: FC<Props> = ({ mode = 'cart' }) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const order = useAppSelector(selectOrder);
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const navigate = useNavigate();
  const { error, mutate: createOrder } = useMutation<
    Order,
    ApiError<{ country: string }>,
    CreateOrderParams
  >(OrdersApi.create, {
    onSuccess(order) {
      ReactGA.event(
        {
          action: 'purchase',
          category: 'Checkout',
          value: order.amount,
        },
        {
          cart_id: order.cart.id,
          order_id: order.id,
        },
      );
      dispatch(orderSlice.actions.reset());
      queryClient.invalidateQueries('cart');
      navigate(order.url);
    },
  });
  const {
    amount,
    cart_items: items,
    id: shoppingCartId,
    multi_item_savings,
    plan_name,
    tax_amount,
  } = useShoppingCart();

  const onSubmit = useCallback(() => {
    if (mode === 'cart') {
      navigate(isAuthorized ? RoutePath.Checkout : RoutePath.Login);
      return;
    }

    if (shoppingCartId) {
      createOrder({
        cartId: shoppingCartId,
        notes: order.notes,
      });
    }
  }, [createOrder, navigate, mode, isAuthorized, order, shoppingCartId]);

  return (
    <Widget title="Order details">
      {mode === 'cart' && (
        <Paragraph className="mt-0.5 text-navy-70" size="S">
          You can choose date & location during checkout.
        </Paragraph>
      )}
      <ul className="mt-2.5">
        {items.map(item => (
          <li
            className="mb-[12px] flex justify-between items-center"
            key={item.id}>
            <span className="text-navy-70">
              {item.servings}x&nbsp;{item.name}
            </span>
            <span className="text-navy-100 font-semibold">
              <Money amount={Number(item.amount)} />
            </span>
          </li>
        ))}
        {!!tax_amount && (
          <li className="mb-[12px] flex justify-between items-center">
            <span className="text-navy-70">Tax amount</span>
            <span className="text-navy-100 font-semibold">
              <Money amount={Number(tax_amount)} />
            </span>
          </li>
        )}
        {!!multi_item_savings && (
          <li className="mb-[12px] flex justify-between items-center text-green-120">
            <span>Plan savings</span>
            <span className="font-semibold">
              <Money amount={multi_item_savings} />
            </span>
          </li>
        )}
      </ul>
      <hr className="mt-[12px] mb-[12px] border-navy-10" />
      <div className="flex justify-between items-center text-navy-100">
        <span>Order total</span>
        <span>
          <Money amount={Number(amount)} />
        </span>
      </div>
      <p className="mt-[12px] text-navy-50">
        This price includes the travelling fees, the service, the cooking, and
        the cleaning. Ingredients will be separate.
      </p>
      {mode === 'cart' && !!plan_name && (
        <div className="mt-2.5 p-1.5 rounded-lg bg-green-10 text-green-120">
          <Paragraph semiBold size="M">
            Current order plan: {plan_name}
          </Paragraph>
        </div>
      )}
      <Alert className="mt-2.5 mb-0">{error?.getFieldError('country')}</Alert>
      <Button
        block
        disabled={
          mode === 'checkout' &&
          (!order.addressFilled || !order.paymentMethodFilled)
        }
        className="mt-2.5"
        onClick={onSubmit}>
        {mode === 'cart' ? 'Proceed to checkout' : 'Place order'}
      </Button>
    </Widget>
  );
};
