import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { BillingApi } from '../api';
import {
  AddressWidget,
  Content,
  Heading,
  NotesWidget,
  PaymentMethodWidget,
  ShoppingCartSummary,
  StripeWrapper,
} from '../components';
import { useProfile, useShoppingCart } from '../hooks';
import { Layout } from '../layout';

export const CheckoutPage: FC = () => {
  const { data: paymentIntent } = useQuery(
    'payment-intent',
    BillingApi.createPaymentIntent,
  );
  const { isSuccess: isShoppingCartFetched } = useShoppingCart();
  const { profile } = useProfile();

  const stripeElementsOptions = useMemo(
    () =>
      paymentIntent?.client_secret
        ? {
            clientSecret: paymentIntent?.client_secret,
            fonts: [
              {
                cssSrc:
                  'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap',
              },
            ],
          }
        : undefined,
    [paymentIntent],
  );

  return (
    <Layout inner>
      <Content className="py-4 md:py-6">
        <Heading className="mb-3.5 text-navy-100" level={3}>
          Checkout
        </Heading>
        <div className="grid md:grid-cols-[2fr_1fr] md:items-start gap-3.5">
          <div className="grid gap-3.5">
            {isShoppingCartFetched && profile && (
              <AddressWidget profile={profile} />
            )}
            <NotesWidget />
            {profile && stripeElementsOptions && (
              <StripeWrapper options={stripeElementsOptions}>
                <PaymentMethodWidget profile={profile} />
              </StripeWrapper>
            )}
          </div>
          <ShoppingCartSummary mode="checkout" />
        </div>
      </Content>
    </Layout>
  );
};
