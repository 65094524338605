import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { AuthRoute, Paragraph, RegisterForm } from '../components';
import { RoutePath } from '../config';
import { useRoleFromQueryString } from '../hooks';
import { AuthLayout } from '../layout';

export const RegisterPage: FC = () => {
  const role = useRoleFromQueryString();

  return (
    <AuthRoute>
      <AuthLayout>
        <RegisterForm withHeader role="customer" />
        <footer className="mt-2.5">
          <Paragraph className="text-center text-navy-100" size="S">
            Already have an account?&nbsp;
            <Link
              className="text-blue-100"
              to={`${RoutePath.Login}?role=${role}`}>
              Login
            </Link>
          </Paragraph>
        </footer>
      </AuthLayout>
    </AuthRoute>
  );
};
