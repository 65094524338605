import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { Content, Heading, Paragraph } from '../components';
import { RoutePath } from '../config';
import { Layout } from '../layout';

export const TermsPage: FC = () => {
  return (
    <Layout inner>
      <Content className="py-4 md:py-6 text-navy-100">
        <header className="mb-4">
          <Heading level={3}>TERMS AND CONDITIONS</Heading>
          <span className="block text-navy-60">
            Last updated: Apr 20th, 2019
          </span>
        </header>
        <Heading className="mb-3" level={4}>
          1. Contractual Relationship
        </Heading>
        <Paragraph className="mb-1" size="S">
          These Terms of Use ("<em>Terms</em>") govern the access or use by you,
          an individual, from within any country in the world of applications,
          websites, content, products, and services (the "<em>Services</em>")
          made available by ChefNow Inc., a private limited liability company
          established in Canada, having its offices at 12 Rockford Road - 309,
          Toronto M2R 3A2, Ontario, Canada registered at Corporations Canada
          under number 1094854-3 ("<em>GetChefNow</em>").
        </Paragraph>
        <Paragraph className="mb-1" size="S">
          Product License Agreement and End User License Agreement The following
          summary (“Summary of Terms”) is subject to the terms and conditions
          set out in the schedules to this agreement (and together, this Summary
          of Terms and all schedules are the “Agreement”). If any End-User does
          not agree to this Agreement, such End-User shall agree to not use the
          Product and in doing so shall not be granted a license to the Product.
          See “Acceptance of the Terms”, below.
        </Paragraph>
        <Heading className="mt-2 mb-2" level={5}>
          Parties
        </Heading>
        <Paragraph className="mb-1" size="S">
          This Agreement is between: a. ChefNow Inc., a company incorporated
          under the Federal laws of Canada and located at 12 Rockford Road, Apt
          309, Toronto, Ontario M2R 3A2 (the “Company”, and <br />
          b. The “Client” or “End-User” who has registered for the use of the
          Product, and together with the Company, each a “Party”, and
          collectively the “Parties”).
        </Paragraph>
        <Paragraph className="mb-1" size="S">
          Acceptance Of The Agreement in Order to Use the Site.
        </Paragraph>
        <Paragraph className="mb-1" size="S">
          It is important that each End-User read this Agreement carefully. By
          registering for, logging in and/or otherwise accessing or using the
          Product which each End-User agrees to be good and valuable
          consideration, such End-User hereby represents, warrants and signifies
          that such End-User is: (a) at least 18 years of age; (b) has read,
          understood and agrees to be bound by this Agreement as it may be
          amended from time to time; and (c) has read and understood the Company
          Privacy Policy, which can be accessed at
          <Link className="link" to={RoutePath.Pricing}>
            https://www.getchefnow.com/privacypolicy.html
          </Link>{' '}
          (the "Privacy Policy"), the terms of which are incorporated herein by
          reference, and agrees to abide by the Privacy Policy. Client and/or
          End-User further agree to abide by all the terms in the Google Play
          and Apple Store (“App Store”) end user licensing agreements, or any
          other applicable App Store agreement, so long as such agreement does
          not conflict with the terms herein.
        </Paragraph>
        <Heading className="mt-2 mb-2" level={5}>
          Summary of License Terms
        </Heading>
        <Paragraph size="S">
          During the Initial Term or subsequent Renewal Term (as defined below),
          the Company grants to the End-User a non-exclusive, non-transferable,
          non-sub licensable, revocable license to the Product, the features of
          which are described in “Schedule B” to this Agreement. The license can
          be revoked for breach of the Agreement and/or for any of the
          Prohibited Uses as defined below in Schedule “C” to this Agreement,
          examples of which are submission to the Company or any other End-Users
          of objectionable content, and/or acts of abuse to the Company or to
          any other End-Users, and/or of any Prohibited Use of the product,
          among others). Complete license terms are contained in Schedule “A” to
          this Agreement.
        </Paragraph>
        <Heading className="mt-2 mb-2" level={5}>
          Fees and Timeline
        </Heading>
        <Paragraph size="S">
          The Fees for use of the Product as amended from time to time are set
          out at{' '}
          <Link className="link" to={RoutePath.Pricing}>
            https://www.getchefnow.com/pricing.html
          </Link>
          . All payments shall be made through a third-party service provider
          utilized by the Company. Additional usage fees are charged monthly in
          arrears. We will notify the Client and/or End-User of any price
          changes in accordance with the Amendment provision set out below.
        </Paragraph>
        <Heading className="mt-2 mb-2" level={5}>
          Term
        </Heading>
        <Paragraph size="S">
          This Agreement shall apply to each Client and/or End-User commencing
          from the time that such End-User first registers to use the Product
          (the “Effective Date”) and, subject to the clauses that survive this
          agreement, until termination of the Agreement at the conclusion of a
          one-month period (the “Initial Term”). The Agreement will renew
          automatically on a month-to-month basis thereafter (each a “Renewal
          Term”) until such time as it is terminated in accordance with the
          termination provisions of this Agreement.
        </Paragraph>
        <Heading className="mt-2 mb-2" level={5}>
          As-Is/No Warranty
        </Heading>
        <Paragraph size="S">
          The Product is provided “As-Is” and no refunds will be provided for
          early termination.
        </Paragraph>
        <Heading className="mt-2 mb-2" level={5}>
          Data Usage
        </Heading>
        <Paragraph size="S">
          The Client acknowledges that, and the End-User acknowledges that, to
          aid in supporting the Company business goals and in improving the
          End-User experience, the Company processes End-User User Data through
          third party storage, hosting and data processing services that are
          comparable to the processing services provided by similar companies to
          the Client. A complete list of third party data processing providers
          can be found within the Privacy Policy to this Agreement which can be
          accessed at{' '}
          <Link className="link" to={RoutePath.Privacy}>
            https://www.getchefnow.com/privacypolicy.html
          </Link>
          .
        </Paragraph>
        <Heading className="mt-2 mb-2" level={5}>
          Support
        </Heading>
        <Paragraph size="S">
          For support inquiries, or to report objectionable content, please
          contact the Company by email at{' '}
          <a className="link" href="mailto:support@getchefnow.com">
            support@getchefnow.com
          </a>
        </Paragraph>
        <Heading className="mt-4 mb-4" level={3}>
          Schedule “A” Terms and Conditions to the Product Licence Agreement
        </Heading>
        <Heading className="mt-3 mb-3" level={4}>
          1. PARTIES ACCESSING THE SOFTWARE AGREE TO BE BOUND BY OUR TERMS
        </Heading>
        <Paragraph size="S">
          This Agreement will govern the Client and End-User access of the
          ChefNow Inc. product, whether accessed: (a) on a computer connected to
          the internet at{' '}
          <Link className="link" to={RoutePath.Home}>
            http://www.getchefnow.com/
          </Link>{' '}
          (the “Website”); (b) on the Company social media properties including
          www.twitter.com/getchefnow; and/or (c) by downloaded on a mobile
          hardware device (individually and collectively, (a), (b), and (c) are
          the “Product”), as owned and operated by the Company. No End-User may
          use the Product or accept this Agreement if such End-User is not of a
          legal age to form a binding contract with us. In accepting this
          Agreement, each End-User represents that such End-User has the
          capacity to be bound by them, and/or if such End-User is acting on
          behalf of the Client, that such End-User has the authority to bind
          such company or entity (and in which case Client will refer to the
          company or entity).
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          2. AMENDMENT
        </Heading>
        <Paragraph className="mb-2" size="S">
          Pursuant to the amendment restrictions set out in Applicable Law
          (define below), the Company may add to, discontinue or revise this
          Agreement or any aspect, mode, design, or service provided under the
          Product which include but are not limited to the:
          <ol className="mt-1 ml-4 list-[lower-latin]">
            <li className="mb-[4px]">scope of the features;</li>
            <li className="mb-[4px]">timing of the features;</li>
            <li className="mb-[4px]">
              software/hardware required for access to the Product; and
            </li>
            <li className="mb-[4px]">
              geographic locations or jurisdictions in which certain features
              may be available.
            </li>
          </ol>
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          The Company may amend this Agreement without notice for non-material
          amendments. In the event of a material change during the Initial Term
          or prior to a Renewal Term, we will provide the End-User with fourteen
          (14) days’ notice (or the minimum notice period as required under
          Applicable Law, whichever is greater) of any such change (including
          changes in pricing) via e-mail to the e-mail address supplied to the
          Company by the Client and/or End-User, setting out:
          <ol className="mt-1 ml-4 list-[lower-latin]">
            <li className="mb-[4px]">the new or amended agreement terms;</li>
            <li className="mb-[4px]">how such terms read formerly;</li>
            <li className="mb-[4px]">
              the date of the coming into force of the amendment;
            </li>
            <li className="mb-[4px]">
              the means in which to respond to the notice of amendment and the
              effects of not responding;
            </li>
            <li className="mb-[4px]">
              the option to either terminate the agreement or retain the
              existing agreement unchanged; and
            </li>
            <li className="mb-[4px]">
              the language of this provision with reference to the applicable
              consumer protection legislation rules for amending this Agreement
              and making any additional requirements for amendments as
              prescribed by law (if any).
            </li>
          </ol>
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          It is the Client and/or End-User’s responsibility to send such notices
          of material changes to all End-Users associated with the End-User’s
          account. The Company highly recommends that each Client and/or
          End-User read any amendments carefully. Unless explicit consent is
          required by the law, the Company has the right to assume that each
          Client and/or End-User accessing the Product through the Product
          registration has accepted the change to this Agreement, unless such
          Client and/or End-User notifies the Company to the contrary, no later
          than fourteen (14) days after the amendment comes into force (or the
          minimum number of days as required under Applicable Law, whichever is
          greater), that such Client and/or End-User desires to cancel the
          contract or deregister or unsubscribe from access to the Product.
        </Paragraph>
        <Paragraph size="S">
          The Company will post the most current terms to this Agreement
          conspicuously within the Product and the Client and/or End-User’s use
          of the Product will be subject to the most current terms as posted on
          the Product at such time. It is the Client and/or End-User’s
          responsibility to visit this page to find any updates that may have
          been made to the Agreement. Each Client and/or End-User hereby agrees
          that the Company shall not be liable to such End-User for any
          amendments to the Agreement.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          3. PAYMENT TERMS
        </Heading>
        <Paragraph className="mb-2" size="S">
          All payments shall be made Canadian dollars and made and managed
          through a third-party payment service provider utilized by the
          Company. We will notify the Client and/or End-User of any price
          changes in accordance with the Amendment provision set out below.
          Payment from Client and/or End-Users will be due upon delivery of an
          electronic invoice from the Company to the Client and/or End-User
          monthly, as applicable. If there are any paid components of the
          Product, any amounts payable by the Client and/or End-User hereunder
          which remain unpaid forty five (45) days after an invoice is delivered
          shall bear interest at the rate of one (1) percent per month (up to a
          maximum of ten (10) percent per annum, or the maximum amount allowable
          by law, such interest to be calculated on a daily basis from the date
          that the payment first becomes overdue until the date payment is made
          in full.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          Clients and/or End-Users may be required to provide account
          information for at least one valid debit or credit card through the
          Product (“Debit or Credit Card Information”). The Company will use
          this Debit or Credit Card Information in accordance with this
          Agreement and the Company’s Privacy Policy (
          <Link className="link" to={RoutePath.Privacy}>
            https://www.getchefnow.com/privacypolicy.html
          </Link>
          )
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          In the event that the Client and/or End-User debit or credit card
          information cannot be processed on the date of payment due, the
          Company may at the Company’s discretion, periodically attempt to
          process payment.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          All payment is exclusive of any taxes or duties imposed by applicable
          tax laws in each Client’s and/or End-User’s tax jurisdictions, and the
          Company will not be responsible for any taxes or duties owed by the
          Client and/or End-User.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          4. SUSPENSION AND TERMINATION
        </Heading>
        <Paragraph className="mb-2" size="S">
          Client and/or End-Users may terminate their relationship with the
          Company by notifying the Company via email at{' '}
          <a className="link" href="mailto:support@getchefnow.com">
            support@getchefnow.com
          </a>
          . The Client acknowledges that more than one End-User may be
          registered on behalf of the Client who are not responsible for
          payment, and therefore that services provided by the Product and the
          applicable fees shall continue until the Client has notified the
          Company of the Client’s desire to unsubscribe from the Product, to
          close all End-User accounts associated with the Client, and to cease
          the Client and End-User’s use of the Product. In the event that there
          is a time-limited period of free access and/or a trial period, Clients
          and End-Users that use the Product during a trial period and do not
          register for the Product after the free trial period will have their
          account terminated at the end of the free trial period, unless such
          Client and/or End-User upgrades for a paid license. The Company may
          suspend provision of the Product in the event that the Client and/or
          End-User fails to make any payment when due hereunder, the Client
          ceases to carry on its business in the normal course, or if an event
          of Prohibited Use (as described in Schedule “C” to this Agreement)
          occurs, or if Client or End-User is in material breach of the
          Agreement. Suspension shall have no effect on the payment obligations
          of the Client and/or End-User during the Agreement Term or Renewal
          Term. A suspension event shall be included in the definition of
          End-User and/or Client material breach. The Client and/or End-User
          agrees to pay the Company’s reasonable expenses, including lawyer and
          collection agency fees, incurred in enforcing the Company’s right to
          payment. At the Company’s discretion, the Company may terminate this
          Agreement immediately at any time and for any reason including, but
          not limited to:
          <ol className="mt-1 ml-4 list-[lower-latin]">
            <li className="mb-[4px]">
              an End-User or Client material breach of this Agreement, including
              failure to make payments when due;
            </li>
            <li className="mb-[4px]">
              In the event that amounts payable by the End-User remain unpaid
              fourteen (14) days after an invoice is delivered, the Company may
              terminate the End-User’s subscription to the Product.
            </li>
            <li className="mb-[4px]">
              if the End-User and/or Client has not adhered to any or all the
              provisions of the Agreement (such as a failure to pay fees when
              due) or if it appears that the End-User and/or Client not intend
              to or is unable to comply with the Terms, such determination to be
              made solely at the Company’s discretion;
            </li>
            <li className="mb-[4px]">
              for prolonged inactivity of any unpaid accounts if the End-User
              and/or Client has not logged in to the End-User’s account for a
              period greater than twelve (12) months;
            </li>
            <li className="mb-[4px]">
              if the Company is required to terminate the relationship by law;
            </li>
            <li className="mb-[4px]">
              if the Company receives any notice of or discovers any event of
              Prohibited Use;
            </li>
            <li className="mb-[4px]">
              if provision of the Product is no longer commercially viable for
              the Company; and/or
            </li>
            <li className="mb-[4px]">
              if the Company has changed the Company’s Agreement or Privacy
              Policy (
              <Link className="link" to={RoutePath.Privacy}>
                https://www.getchefnow.com/privacypolicy.html
              </Link>
              ) and has not received the Client and/or End-User’s required
              consent, pursuant to the amendment provision in this Agreement.
            </li>
          </ol>
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          Upon termination of this Agreement with the End-User and/or Client,
          the Company immediately revokes the End-User’s license and/or the
          Client’s license, respectively, use the Product and may block all
          access to the End-User’s account, and may delete all data and
          information associated with the End-User’s account for fourteen (14)
          days after such termination.
        </Paragraph>
        <Heading className="mt-2 mb-2" level={5}>
          Warranty Period and Termination
        </Heading>
        <Paragraph className="mb-2" size="S">
          A limited warranty period may be provided to the Client and/or
          End-User, as described in the Summary of Terms. During this limited
          warranty period, the End-User may terminate this Agreement upon
          written notice within the limited warranty period for any reason. For
          any aspects of the Product that require payment, no refunds will be
          provided for early termination, and the End-User will pay the Fees and
          Expenses in full for the Product and Services provided by the Company
          up to and including the last day of the month following the
          termination date.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          5. USE OF THE PRODUCT
        </Heading>
        <Paragraph className="mb-2" size="S">
          In order to use the Product, the Client and/or End-User must register
          using the Company’s registration page located within the app. The
          End-User understands and agrees that a representative of the Client’s
          organization may register the End-User for an account.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          <strong>Registration Information:</strong> The End-User agrees and
          understands that the End-User is responsible for maintaining the
          confidentiality of the End-User’s password, which, together with the
          End-User’s name and e-mail address (“User ID”), allows the End-User to
          access the Product. The End-User ID and password, together with date
          of birth, address, telephone number, gender and any other contact
          information the End-User provides the Company at the time of signing
          up for the Product comprise the End-User’s “Registration Information.”
          The End-User agrees that all Registration Information provided to the
          Company will be accurate and up-to-date. The End-User agrees to keep
          the End-User’s password secure. The Company will not be liable if the
          Company is unable to retrieve or reset a lost password. If the
          End-User becomes aware of any unauthorized use of the End-User’s
          password or account, the End-User agrees to notify the Company via
          e-mail at{' '}
          <a className="link" href="mailto:support@getchefnow.com">
            support@getchefnow.com
          </a>{' '}
          as soon as possible. The Client and/or End-user may not open an
          account if the Client and/or End-User is a competitor of the Company.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          <strong>Permitted Uses:</strong> The Client and/or End-User agrees to
          use the Product only for purposes that are permitted, both by the
          Agreement and by any applicable law, regulation, or generally accepted
          practices or guidelines, in relevant local, national, and
          international jurisdictions. The Client and/or End-User agrees to
          adhere to any applicable privacy of personal information laws and
          regulations.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          <strong>Unauthorized Access:</strong> The Client and/or End-User
          agrees to only access (or try to access) and use the Product through
          interfaces provided by us. The Client and/or End-User shall not access
          (or try to access) and use the Product through any automated means,
          including, but not limited to, scrapers, scripts, robots, or web
          crawlers. The Client and/or End-User agrees not to use or attempt to
          use another End-User’s account. The Client and/or End-User agrees not
          to impersonate any person or entity, or falsely state or otherwise
          misrepresent the Client and/or End-User, the Client and/or End-User’s
          personal information, or the Client and/or End-User’s affiliations
          with any person or entity.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          <strong>Moderation of content created by the End-User:</strong>
          The Client and/or End-User understands and agrees that although the
          Company is not required to moderate the End-User’s use of the Product,
          it may in its sole judgment review and delete any content in whole or
          in part, for any reason whatsoever, which without limitation, violate
          this Agreement or which might be objectionable, offensive, indecent,
          illegal, or that might violate the rights, harm, or threaten the
          safety of others.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          <strong>Objectionable content created by others:</strong> The Client
          and/or End-User understands that when using the Product, the End-User
          may come across material that the End-User finds objectionable,
          offensive or indecent and agree that the End-User is using the Product
          at the End-User’s own risk. The End-User may alert the Company by
          using the support contact information contained herein.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          <strong>User Responsibility:</strong> The Client and/or End-User
          agrees that the Client and/or End-User is solely responsible for any
          breach of the End-User’s obligations under the Agreement and for the
          consequences of any such breach. The Company has no responsibility to
          the Client and/or End-User or to any third party for such breaches or
          the consequences of such breaches (including losses or damage that we
          may incur).
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          <strong>Technical Requirements:</strong> Use of the Product requires
          internet access through the End-User’s computer and/or other
          electronic devices. The End-User may be required to have the most
          up-to-date IOS operating system to use the Product, and some features
          of the Product may not be accessible with such technologies disabled.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          <strong>User Responsibility for Equipment</strong>The End-User agrees
          to be responsible for obtaining and maintaining any software,
          browsers, hosting services, other equipment and ancillary services
          needed to connect to, access or otherwise use the Product.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          6. PRIVACY
        </Heading>
        <Paragraph className="mb-2" size="S">
          The End-User’s privacy is very important to us. Please review the
          Company’s Privacy Policy (
          <Link className="link" to={RoutePath.Privacy}>
            https://www.getchefnow.com/privacypolicy.html
          </Link>
          ). Our Privacy Policy applies to the collection, use, disclosure,
          retention, protection and accuracy of the End-User’s personal
          information collected for the purposes of the features offered through
          the Product.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          7. THIRD PARTY LINKS
        </Heading>
        <Paragraph className="mb-2" size="S">
          The Product may link to third-party websites and/or resources. Such
          links are provided as a convenience to the End-User only and do not
          imply an endorsement, warranty or guarantee by the Company of any such
          linked website or the company it purports to represent. The Company
          does not assume any responsibility or liability for the availability
          or accuracy of such links, and/or the content products or services
          provided at the destinations of such links. The Client and/or End-User
          is solely responsible for use of any such websites or resources and
          compliance with their policies. Should the End-User elect to enter
          into a binding contract with any such third party website and/or
          resource, the Client and/or End-User agrees to hold the Company
          harmless and hereby release the Company from any liability whatsoever,
          whether arising out of contract, tort or otherwise, for any liability,
          claim, injury, loss or damage suffered as a result of the End-User’s
          actions or the actions of any End-User associated with the End-User’s
          account, offering to accept or having accepted any products or
          services that are available from those sites.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          8. INTELLECTUAL PROPERTY AND RIGHTS
        </Heading>
        <Paragraph className="mb-2" size="S">
          <strong>Rights to content provided by us</strong>. The Client and/or
          End-User acknowledges and understands that we own all right, title and
          interest in: (a) the Product and all improvements, enhancements or
          modifications thereto; (b) the Product and any associated data files;
          and (c) all computer software; advertisements; sponsored content; and
          intellectual property associated with the Product (all such
          information, individually and collectively, being the “Product
          Content”), which the Client and/or End-User may have access to when
          using the Product.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          <strong>Know-How</strong>. Subject to the provisions hereof respecting
          confidentiality and intellectual property, the Company shall be free
          to use any ideas, concepts or know-how developed or acquired by the
          Company during the provision of the Product under this Agreement to
          the extent obtained and retained by the Company’s personnel as
          impressions and general learning (the “Know-How”). Nothing in this
          Agreement shall be construed to preclude the Company from enhancing
          the Product based on the accumulation of Know-How. The Client and/or
          End-User is not required to provide the Company with any comments,
          suggestions, recommendations, bug reports, requests or any other
          feedback (“Feedback”). In the event that the Client and/or End-User do
          provide the Company with Feedback, the Company may use such feedback
          to improve the Product or for any other purpose. Furthermore, the
          Company shall own such Feedback and the Company and its affiliates,
          licensees, clients, partners, third-party providers and other
          authorized entitled may use, license, distribute, reproduce and
          commercialize the Feedback, and the Client and End-User hereby
          assigns, irrevocably, exclusively and on a royalty-free basis, all
          such Feedback to the Company.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          <strong>Limited license:</strong> The Company hereby grants the Client
          and/or End-User a non-exclusive, non-transferable, revocable, limited
          license to use the Product in accordance with this Agreement and the
          License Restrictions set out in the Summary of Terms. This limited
          license is subject to full payment of the Fees when due. This license
          may be revoked by the Company upon breach of this Agreement by the
          Client and/or End-User and shall automatically be revoked upon
          termination or expiration of this Agreement. The Company may, now or
          in the future, own rights to trade-marks, trade names, services marks,
          logos, domain names and other distinctive brand features which we use
          in connection with the operation of the Product (each such feature
          being a “Brand Right” and collectively being the “Brand Rights”). The
          Company does not grant the Client and/or End-User any right or license
          to use any Brand Right other than as expressly set out in this
          Agreement and in other licenses between the Client and/or End-User and
          the Company.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          <strong>Licence to Brand Marks:</strong> The Company and Client and/or
          End-User agree and understand to grant to each other a perpetual,
          non-exclusive, royalty-free licence to use one another’s name, logos,
          and/or trademark (individually and collectively described as the
          “brand”) for the purposes of any press release, advertising, webpage,
          blog or other promotional, advertising or marketing material so long
          as no confidential information is disclosed, and such license shall be
          revocable upon written notice provided in the other in the brand
          owner’s sole discretion, such discretion to be reasonably exercised.
          None of the parties shall not do or allow to be done any act or thing
          that will in any way impair the rights of the other party’s brand.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          9. LIMITATION OF LIABILITY
        </Heading>
        <Paragraph className="mb-2" size="S">
          The Client and the End-Users representing such Client individually and
          collectively hereby agree to release, remise and forever discharge the
          Company and the Company’s directors, employees, officers, and the
          Company’s affiliates, partners, service providers, vendors, and
          contractors and each of their respective agents, directors, officers,
          employees, and all other related persons or entities from any and all
          manner of rights, losses, costs, claims, complaints, demands, debts,
          damages, causes of action, proceedings, liabilities, obligations,
          legal fees, costs and disbursements of any nature whatsoever, and for
          any special, indirect or consequential, incidental or exemplary
          damages, including but not limited to damages for loss of profits,
          goodwill, use data, or other intangible losses (collectively, a
          “Claim”), whether in contract or tort, whether known or unknown, which
          now or hereafter arise from, to the maximum extent allowed by law,
          that relate to, any use of the Product whatsoever.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          <strong>
            THE CLIENT AND END-USER ACKNOWLEDGES AND AGREES THAT: (A) THE
            PRODUCT IS TO BE USED “AS-IS”, WITH NO WARRANTIES ON FITNESS FOR THE
            PURPOSE, MERCHANTABILITY OR OF ANY OTHER KIND, WHETHER EXPRESSED OR
            IMPLIED; AND THE END-USER ACKNOWLEDGES, AGREES AND UNDERSTANDS THAT
            THE END-USER USES THE PRODUCT AT THE END-USER’S OWN RISK; (B) THE
            COMPANY IS HEREBY RELEASED FROM ANY AND ALL LIABILITY FOR USE OF THE
            PRODUCT; AND (C) THE CLIENT AND END-USER HAS REVIEWED THE FOLLOWING
            LIST OF POTENTIAL EVENTS SPECIFICALLY DISCLAIMED AS EXAMPLES FOR
            WHICH THE CLIENT AND END-USER HAS SPECIFICALLY RELEASED THE COMPANY
            FROM ANY AND ALL LIABILITY FOR (AMONG OTHERS):
          </strong>
          <ol className="mt-1 ml-4 list-[lower-latin]">
            <li className="mb-[4px]">
              For non-Product failures: responsibility for any failure of, or
              damages to, any hardware devices, equipment or networks or
              internet access, content or data, or third party applications used
              by the Client and/or End-User in connection with the Product;
            </li>
            <li className="mb-[4px]">
              For communication failures or Product downtime: whether due to
              maintenance or other reasons, any error, inaccuracy, omission,
              interruption, deletion, defect, delay in operation or
              transmission, communications line failure, theft or destruction or
              unauthorized access to, or alteration of, the Product content,
              End-User User Data, use of Product and/or any communications
              between the End-Users and the Product;
            </li>
            <li className="mb-[4px]">
              For loss of access as a result of failure to receive payment:
              account suspension or termination as a result of the End-User’s
              insufficient funds to make payment or payment processing issues
              that are beyond the Company’s control (such as but not limited to,
              power outages, interruptions of cellular service, overzealous
              fraud protection rules applied by the User’s payment card brand or
              acquirer bank, or any other interface from an outside force);
            </li>
            <li className="mb-[4px]">
              For content inaccuracy: any inaccuracy in content and/or
              recommendations by the Company in the Company content and/or the
              End-User and/or Client User Data, and the Client and End-User is
              responsible for ensuring that that the information entered into
              the Company’s system by such End-User is accurate, reliable and
              complete, and agrees that the provision or storage of End-User
              Data through the Product does not constitute the Company’s
              endorsement or warranty as to the accuracy of such content;
            </li>
            <li className="mb-[4px]">
              For compliance with law: the compliance of such End-User Data with
              applicable legislation including privacy legislation, and Client
              and End User agree that the provision or storage of End-User Data
              through the Product does not constitute the Company’s endorsement
              or warranty as to compliance with laws;
            </li>
            <li className="mb-[4px]">
              For online discussions: responsibility or liability for any
              user-generated commentary, ratings or reviews of the Client and/or
              End-User and/or an employee of a Client and/or End-User posted to
              the Product, or any consequences as a result of the ratings or
              reviews of an employee, including but not limited to termination
              of an employee, and ratings and reviews posted to the Product DO
              NOT reflect the Company’s views;
            </li>
            <li className="mb-[4px]">
              For monitoring: liability for monitoring the Product or for
              unauthorized or unlawful content on the Product or use of the
              Product by any of the Product’s End-Users;
            </li>
            <li className="mb-[4px]">
              For non-infringement: direct or indirect, express or implied
              representation or warranty as to title and non-infringement of
              intellectual property in relation to the Product.
            </li>
            <li className="mb-[4px]">
              For processing of content: liability for any damages that may
              arise by the Client’s use and/or direct or third-party processing
              of End-User Data, and the Client and/or End-User further agrees
              and acknowledges that the Company is not liable for any damages
              that may arise if Client and/or End-User Data is misdirected in
              error, subject to, subject to the Company’s legal requirements
              relating to the protection of personal information under
              Applicable Law;
            </li>
            <li className="mb-[4px]">
              For communications directly or through the Company’s Product:
              liability for any damages that may arise for communications
              received to the Client and/or End-User through the User’s access
              to the Product and/or for the posting of information on the
              Product, Website, blog, account or any affiliated social media,
              including but not limited to, Client and/or End-User Data,
              pictures, written reviews, personal information, and/or comments
              made from the End-User’s personal point of view;
            </li>
            <li className="mb-[4px]">
              For unauthorized activities: unauthorized activities directed
              towards the Product or its Client and/or End-Users including
              identity theft, fraud or unauthorized access; viruses, denial of
              service attacks, and any items that are included in the definition
              of Prohibited Use as set out as Schedule “C” to this Agreement;
              and/or
            </li>
            <li className="mb-[4px]">
              For force majeure: any force majeure event as described in this
              Agreement and/or any matter beyond the Company’s reasonable
              control.
            </li>
            <li className="mb-[4px]">
              In the event that there is a finding of liability pursuant to the
              dispute resolution provisions of this Agreement that is contrary
              to the foregoing, the Client and End-Users agree that such damages
              shall be limited in the aggregate for all Claims related to all of
              the End-Users registered on behalf of a Client and the Client
              itself (individually and collectively, the Claimants) to the fees
              or charges which the Claimants have paid for the Product, if any,
              in the previous invoice for the services giving rise to the claim,
              whether or not any or all of the Claimants have been advised of
              the possibility of such damages or such Claim was reasonably
              foreseeable and notwithstanding the sufficiency or insufficiency
              of any remedy provided for herein.
            </li>
          </ol>
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          10. INDEMNIFICATION
        </Heading>
        <Paragraph className="mb-2" size="S">
          To the extent permitted by applicable laws, the Client and/or End-User
          agree that the Client and/or End-User will defend, indemnify and hold
          harmless the Company and the Company’s officers, directors,
          shareholders, employees, agents and representatives, from and against
          any and all damages, judgments, liability, costs and expenses
          (including without limitation any reasonable legal fees), in whole or
          in part arising out of or attributable to: (a) generally, for the
          Client and/or End-User’s breach of this Agreement; the End-User’s
          access to and/or use of the Product; and any loss of, or damage to,
          any property, or injury to, or death of, any person (including the
          Client and/or End-User) caused by the Client and/or End-User’s access
          to and/or use of the Product; and (b) specifically, for the End-User’s
          breach of the intellectual property rights of any third party to this
          Agreement; and/or (c) for any Prohibited Use.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          The End-User agrees that the Client and/or End-User will be solely
          responsible for all activities that occur under the End-User’s
          account, whether the End-User is aware of them or not. The End-user
          and/or Client agrees to hold the Company harmless and release the
          Company from any loss or liability whatsoever that the End-User and/or
          Client may incur as a result of someone other than the Client and/or
          End-User using the End-User’s password or account, either with or
          without the Client and/or End-User’s knowledge. The Client and/or
          End-User agree to indemnify the Company for any damages, third party
          claims or liabilities whatsoever that the Company may incur as a
          result of activities that occur on or through the End-User’s account,
          whether or not the Client and/or End-User were directly or personally
          responsible.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          11. GOVERNING LAW AND FORUM OF DISPUTES
        </Heading>
        <Paragraph className="mb-2" size="S">
          The Client and/or End-User agree that the laws of the province of
          Ontario and the Federal laws of Canada as applicable therein, without
          regard to the principles of conflict of laws (“Applicable Law”), will
          govern this Agreement and any dispute of any sort that may arise
          between the Client and/or End-User and us. With respect to any
          disputes or claims, the Client and/or End-User agree not to commence
          or prosecute any action in connection therewith other than in the
          province of Ontario, and the Client and/or End-User hereby consent to
          and waive all defenses of lack of personal jurisdiction and forum non
          conveniens with respect to venue and jurisdiction in the provincial
          courts of Ontario. The Client and/or End-User agree to pay reasonable
          attorneys' fees and court costs incurred by the Company to collect any
          unpaid amounts owed by the Client and/or End-User.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          12. EXPENSES
        </Heading>
        <Paragraph className="mb-2" size="S">
          Other than in the event of a dispute, in which case the apportionment
          of expenses shall be determined pursuant to the dispute resolution
          rules, each Party shall be responsible for its own legal fees and
          other expenses incurred in connection with the negotiation of these
          terms (if any) and the performance of any of such Party’s obligations
          hereunder.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          13. FORCE MAJEURE
        </Heading>
        <Paragraph className="mb-2" size="S">
          The Client and/or End-User agree that the Company are not liable for a
          delay or failure in performance of the Product or the provisions of
          this Agreement caused by reason of any occurrence of unforeseen events
          beyond the Company’s reasonable control, including but not limited to,
          acts of God, natural disasters, power failures, server failures, third
          party service provider failures or service interruptions, embargo,
          labour disputes, lockouts and strikes, riots, war, floods,
          insurrections, legislative changes, and governmental actions.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          14. SEVERABILITY
        </Heading>
        <Paragraph className="mb-2" size="S">
          If any portion of this Agreement is deemed unlawful, void or
          unenforceable by any arbitrator or court of competent jurisdiction,
          this Agreement as a whole shall not be deemed unlawful, void or
          unenforceable, but only that portion of this Agreement that is
          unlawful, void or unenforceable shall be stricken from this Agreement.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          15. HEADINGS
        </Heading>
        <Paragraph className="mb-2" size="S">
          The insertions of headings are for convenient reference only and are
          not to affect the interpretation of this Agreement.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          16. ASSIGNMENT OF AGREEMENT
        </Heading>
        <Paragraph className="mb-2" size="S">
          The Client and/or End-User may not, without the Company’s prior
          written consent, assign the Agreement, in whole or in part, either
          voluntarily or by operation of law, and any attempt to do so will be a
          material default of the Agreement and will be void. The Company may
          assign this Agreement to a third party at any time in the Company’s
          sole discretion. The Agreement will be binding upon and will inure to
          the benefit of the respective parties hereto, their respective
          successors in interest, legal representatives, heirs and assigns.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          17. WAIVER
        </Heading>
        <Paragraph className="mb-2" size="S">
          The Client and/or End-User agrees that if the Company does not
          exercise or enforce any legal right or remedy which is contained in
          this Agreement or which the Company has the benefit of under any
          Applicable Law, this will not be taken to be a formal waiver of the
          Company’s rights and that those rights or remedies will still be
          available to us. Waivers must be in written form and signed by an
          authorized representative of the Company.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          18. SURVIVAL OF AGREEMENT
        </Heading>
        <Paragraph className="mb-2" size="S">
          All covenants, agreements, representations and warranties made in this
          Agreement shall survive the End-User’s acceptance of this Agreement
          and the termination of this Agreement.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          19. ENTIRE AGREEMENT
        </Heading>
        <Paragraph className="mb-2" size="S">
          The Product License Agreement and its schedules will constitute the
          entire agreement between the Company and the Client and/or End-User
          with respect to the subject matter hereof, and all prior oral or
          written agreements, representations or statements with respect to such
          subject matter are superseded hereby. In the event of a conflict
          between this Agreement and the Privacy Policy (
          <Link className="link" to={RoutePath.Privacy}>
            https://www.getchefnow.com/privacypolicy.html
          </Link>
          ), the terms and conditions found herein shall prevail.
        </Paragraph>
        <Heading className="mt-3 mb-3" level={4}>
          20. CONTACT
        </Heading>
        <Paragraph className="mb-2" size="S">
          By providing the Company with the End-User’s e-mail address, whether
          provided directly by the End-User or by the Client on behalf of the
          End-User, the End-User agrees to receive all required notices
          electronically, to that e-mail address or by mobile notifications via
          the Product. It is the End-User’s responsibility to update or change
          that address, as appropriate.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          If the Client and/or End-User have any questions or comments regarding
          this Agreement, please contact the Company’s head office by email at
          <a className="link" href="mailto:support@getchefnow.com">
            support@getchefnow.com
          </a>
          .
        </Paragraph>

        <Heading className="mt-4 mb-4" level={3}>
          Schedule “B”
        </Heading>
        <Heading className="mt-3 mb-3" level={4}>
          Product Scope and Features
        </Heading>
        <Paragraph className="mb-2" size="S">
          The Product is limited to the following features and License
          Restrictions contained in the version available during the Initial
          Term and subsequent Renewal Terms, and includes:
          <ol className="mt-1 ml-4 list-decimal">
            <li className="mb-[4px]">Finding available chefs</li>
            <li className="mb-[4px]">Scheduling available chefs</li>
            <li className="mb-[4px]">
              Paying the available chef on behalf of the customer
            </li>
          </ol>
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          A complete list of features for this current version as well as
          License Restrictions can be found at
          <Link className="link" to={RoutePath.Pricing}>
            https://www.getchefnow.com/pricing.html
          </Link>
        </Paragraph>

        <Heading className="mt-4 mb-4" level={3}>
          Schedule “C”
        </Heading>
        <Heading className="mt-3 mb-3" level={4}>
          Prohibited Uses
        </Heading>
        <Paragraph className="mb-2" size="S">
          The Client and/or End-Users may use the Product only for lawful
          purposes, and may not use the Product in any manner that:
          <ol className="mt-1 ml-4 list-[lower-latin]">
            <li className="mb-[4px]">
              breaches any applicable local, national or international law or
              regulation;
            </li>
            <li className="mb-[4px]">
              may in any way be considered harassment to another person or
              entity;
            </li>
            <li className="mb-[4px]">
              may in any way be unlawful or fraudulent, or has any unlawful or
              fraudulent purpose or effect;
            </li>
            <li className="mb-[4px]">
              may in any way damage, disable, overburden, and/or impair the
              Product server, or any network connected to the Product server,
              and/or interfere with any other party’s use or enjoyment of the
              Product;
            </li>
            <li className="mb-[4px]">
              is in any way abusive, defamatory, misleading, fraudulent,
              pornographic or otherwise explicit in nature or written in bad
              faith;
            </li>
            <li className="mb-[4px]">
              harms or attempts to harm minors in any way;
            </li>
            <li className="mb-[4px]">
              will abuse either verbally, physically, written or other abuse
              (including threats of abuse or retribution) of any Product
              customers, employees, members, or officers;
            </li>
            <li className="mb-[4px]">
              will cause sensitive personal information records of any
              individual (including, but not limited to, credit card
              information, personal health records, military information, and
              passport information) to be attained or used illegally or in an
              unauthorized manner;
            </li>
            <li className="mb-[4px]">
              will decipher, decompile, disassemble, reconstruct, translate,
              reverse engineer, or discover any of the intellectual property or
              ideas, algorithms, file formats, programming, or interoperability
              interfaces underlying the Product;
            </li>
            <li className="mb-[4px]">
              will modify, rent, lease, loan, sell, distribute or create any
              derivative products or services (or parts of services products or
              services) based on the Product Content that the End-User does not
              own or to which the End-User has rights, or to create derivative
              works based on the Product;
            </li>
            <li className="mb-[4px]">
              will infringe upon the Company’s intellectual property or adapt,
              reproduce, publish or distribute copies of any information or
              material found on the Product in any form (including by e-mail or
              other electronic means), without the Company’s prior written
              consent;
            </li>
            <li className="mb-[4px]">
              is for the benefit of or permit a third party, who is not a
              registered End-User, to use the Product;
            </li>
            <li className="mb-[4px]">
              will attempt to gain unauthorized access to, or disrupt the
              integrity or performance of the Product or the data contained
              therein;
            </li>
            <li className="mb-[4px]">
              will use the Product to upload, post, link to, email, transmit, or
              otherwise make available any material that contains software
              viruses, or any other computer code, files or programs designed to
              interrupt, destroy, or limit the functionality of any computer
              software or hardware or any telecommunications equipment
            </li>
            <li className="mb-[4px]">
              will the post or distribute any computer program that damages,
              detrimentally interferes with, surreptitiously intercepts, or
              expropriates any system, data, or personal information
            </li>
            <li className="mb-[4px]">
              will disrupt the functioning of the Product, in any manner; and/or
            </li>
            <li className="mb-[4px]">
              in the event that the Product is being used on a mobile device,
              shall not be used in a way that distracts the End-User and/or
              prevents the End-User from obeying traffic or safety laws,
            </li>
            <li className="mb-[4px]">
              and any of the foregoing (“Prohibited Uses”) may, once
              investigated, result in immediate account termination for an
              End-User account and agreement and/or termination of an agreement
              with the Client who has registered the account for such End-User.
            </li>
          </ol>
        </Paragraph>
      </Content>
    </Layout>
  );
};
