import { format } from 'date-fns';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Address, Chef, User } from '../model';
import { UpdateChefDTO, UserDTO, UsersApi } from './../api';

export interface ChefOnboardingFormFields extends Address {
  actionPhoto: File | null;
  birthDate: Date | null;
  cuisines: string[];
  description: string;
  location?: google.maps.places.AutocompletePrediction;
  profilePhoto: File | null;
  step: number;
}

export function useChefOnboardingFlow(
  profile: User,
  fields: Array<keyof ChefOnboardingFormFields> = [],
) {
  const queryClient = useQueryClient();
  const form = useFormContext<ChefOnboardingFormFields>();
  const { mutate: updateChef } = useMutation<Chef, Error, UpdateChefDTO>(
    UsersApi.updateChef.bind(undefined, profile.id),
    {
      onSuccess() {
        moveForward();
      },
    },
  );
  const { mutate: updateUser } = useMutation<User, Error, UserDTO>(
    UsersApi.update.bind(undefined, profile.id),
    {
      onSuccess() {
        queryClient.refetchQueries('me');
      },
    },
  );

  const moveForward = useCallback(
    () => form.setValue('step', form.getValues('step') + 1),
    [form],
  );

  const goToNextStep = useCallback(async () => {
    const data = form.getValues();
    console.log('debug: fields ', data);

    if (!(await form.trigger(fields))) {
      return;
    }

    await updateUser({
      address: data.address,
      date_of_birth: data.birthDate
        ? format(data.birthDate, 'yyyy-MM-dd')
        : undefined,
      city: data.city,
      country_iso_code: data.country_iso_code,
      country: data.country,
      location_id: String(data.location?.place_id),
      state: data.state,
      zipcode: data.zipcode,
    });

    await updateChef({
      about: data.description,
      cuisines: data.cuisines.filter(Boolean),
      dish_specialities: [],
    });
  }, [form, fields, updateChef, updateUser]);

  return {
    form,
    goToNextStep,
  };
}
