import { UploadTwo } from '@icon-park/react';
import classNames from 'classnames';
import {
  ChangeEvent,
  DragEvent,
  FC,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';

interface Props {
  isPreviewVisible?: boolean;
  label?: string;
  name: string;
  required?: boolean;
}

export const UploadImage: FC<Props> = ({
  isPreviewVisible = false,
  label,
  name,
  required = false,
}) => {
  const { formState, getFieldState, register, setValue, watch } =
    useFormContext();
  const { invalid } = getFieldState(name, formState);
  const inputRef = useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState(false);
  register(name, {
    required,
    validate: {
      lessThan1MB: (file: File | null) =>
        !file ||
        file.size / 1024 / 1024 < 1 ||
        'File size should be less than one megabyte',
    },
  });
  const value = watch(name);

  const handleDrag = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (e.type === 'dragleave') {
        setDragActive(false);
      }
    },
    [setDragActive],
  );

  const setFile = useCallback(
    (file?: File) => {
      if (file) {
        setValue(name, file);
      }
    },
    [name, setValue],
  );

  const handleDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      setFile(e.dataTransfer.files?.[0]);
    },
    [setDragActive, setFile],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setFile(e.target.files?.[0]);
    },
    [setFile],
  );

  return (
    <div>
      {Boolean(label) && (
        <span className="input-label">
          {label}
          {required && '*'}
        </span>
      )}
      <input
        accept="image/png, image/jpeg"
        className="hidden"
        onChange={handleChange}
        ref={inputRef}
        type="file"
      />
      <div className="flex flex-col md:flex-row gap-2 md:gap-2.5">
        {isPreviewVisible && (
          <>
            {value ? (
              <img
                alt="Preview"
                className="center flex-[0_0_64px] w-[64px] h-[64px] rounded-md border border-navy-20"
                src={URL.createObjectURL(value)}
              />
            ) : (
              <div className="center flex-[0_0_64px] w-[64px] h-[64px] rounded-md border border-navy-20">
                <UserIcon className="text-blue-100" />
              </div>
            )}
          </>
        )}
        <div
          className={classNames(
            'center flex-auto h-[116px] rounded-md border',
            dragActive
              ? 'border-dashed border-blue-100'
              : 'border-navy-20 bg-white',
            invalid && 'border-red-100 bg-red-10',
          )}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}>
          <div className="text-center text-navy-60">
            <UploadTwo
              className="inline-block mx-auto mb-1 text-navy-100"
              size={28}
            />
            <p>
              <button
                className="text-blue-100 font-semibold text-lg cursor-pointer"
                onClick={() => {
                  inputRef.current?.click();
                }}
                type="button">
                Click to upload
              </button>{' '}
              <span className="text-lg">or drag and drop</span>
              <br />
              PNG or JPG files only (max 1MB)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
