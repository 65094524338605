import { addDays, differenceInDays, format, setHours } from 'date-fns';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { UsersApi } from '../../api';
import { Configuration } from '../../config';
import {
  useAppDispatch,
  usePhoneUtil,
  usePlaces,
  useShoppingCart,
} from '../../hooks';
import { Address, User } from '../../model';
import { orderSlice } from '../../redux';
import { Alert, Button } from '../atoms';
import {
  DateField,
  LocationField,
  PhoneField,
  TimeSlotField,
  Widget,
} from '../molecules';

interface Props {
  profile: User;
}

interface FormFields extends Address {
  date?: Date;
  location?: google.maps.places.AutocompletePrediction;
  phone: string;
  time: string;
}

export const AddressWidget: FC<Props> = ({ profile }) => {
  const { getPlaceDetails } = usePlaces();
  const dispatch = useAppDispatch();
  const { setDate, start_date_time } = useShoppingCart();
  const phoneUtil = usePhoneUtil();
  const queryClient = useQueryClient();
  const phoneNumber = useMemo(
    () =>
      profile.phone
        ? phoneUtil.parse(
            profile.phone,
            profile.country_iso_code ?? Configuration.defaultCountryISOCode,
          )
        : undefined,
    [phoneUtil, profile.country_iso_code, profile.phone],
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [success, setSuccess] = useState(
    Boolean(start_date_time && profile.address && profile.phone),
  );
  const { mutate: changeAddress } = useMutation(
    (address: Address) => UsersApi.changeAddress(profile!.id, address),
    {
      onSuccess(response) {
        if ('error' in response) {
          setErrorMessage(response.error);
        } else {
          queryClient.setQueryData('me', response);
          dispatch(orderSlice.actions.fillAddress());
          setSuccess(true);
        }
      },
    },
  );
  const startDateTime = start_date_time
    ? new Date(start_date_time)
    : addDays(new Date(), 8);

  const form = useForm<FormFields>({
    defaultValues: {
      address: profile.address,
      country_iso_code:
        profile.country_iso_code ?? Configuration.defaultCountryISOCode,
      date: startDateTime,
      location_id:
        profile.location_id && profile.location_id.length > 0
          ? profile.location_id
          : undefined,
      phone: phoneNumber
        ? phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL)
        : undefined,
      time: start_date_time ? startDateTime.getHours().toString() : undefined,
    },
    shouldUseNativeValidation: true,
  });
  const { handleSubmit, setValue } = form;

  const onSubmit = useCallback(
    async (data: FormFields) => {
      setErrorMessage(undefined);
      const parsedPhone = phoneUtil.parse(
        data.phone,
        data.country_iso_code ?? Configuration.defaultCountryISOCode,
      );

      await setDate(setHours(data.date!, Number(data.time)));

      await changeAddress({
        address: data.address,
        city: data.city,
        country_iso_code: data.country_iso_code,
        country: data.country,
        location_id: String(data.location?.place_id),
        phone: String(parsedPhone.getNationalNumber()),
        state: data.state,
        zipcode: data.zipcode,
      });
    },
    [changeAddress, setDate, phoneUtil],
  );

  useEffect(() => {
    if (profile.location_id) {
      getPlaceDetails(profile.location_id).then(fullAddress => {
        setValue('address', fullAddress.address);
        setValue('city', fullAddress.city);
        setValue('country_iso_code', fullAddress.country_iso_code);
        setValue('country', fullAddress.country);
        setValue('state', fullAddress.state);
        setValue('zipcode', fullAddress.zipcode);
      });
    }
  }, [getPlaceDetails, profile, setValue]);

  const hasAddressInformation =
    start_date_time && profile.address && phoneNumber;

  useEffect(() => {
    if (hasAddressInformation) {
      dispatch(orderSlice.actions.fillAddress());
    }
  }, [dispatch, hasAddressInformation]);

  const startDate = useMemo((): Date => addDays(new Date(), 8), []);

  const filterDate = useCallback(
    (date: Date): boolean => differenceInDays(date, startDate) >= 0,
    [startDate],
  );

  if (success && hasAddressInformation) {
    return (
      <Widget
        interactive
        onClick={() => setSuccess(false)}
        title="Location details">
        <div className="grid grid-cols-2 gap-0.5">
          <p className="md:flex md:gap-2">
            <span className="block paragraph-s text-navy-60">Location:</span>
            <span className="block paragraph-s text-navy-100">
              {profile.address}
            </span>
          </p>
          <p className="md:flex md:gap-2">
            <span className="block paragraph-s text-navy-60">Date:</span>
            <span className="block paragraph-s text-navy-100">
              {format(new Date(start_date_time), 'dd/MM/yyyy')}
            </span>
          </p>
          <p className="md:flex md:gap-2">
            <span className="block paragraph-s text-navy-60">
              Contact phone:
            </span>
            <span className="block paragraph-s text-navy-100">
              {phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL)}
            </span>
          </p>
          <p className="md:flex md:gap-2">
            <span className="block paragraph-s text-navy-60">Time:</span>
            <span className="block paragraph-s text-navy-100">
              {format(new Date(start_date_time), 'h aa')}
            </span>
          </p>
        </div>
      </Widget>
    );
  }

  return (
    <FormProvider {...form}>
      <Widget
        interactive={Boolean(hasAddressInformation)}
        actionText="Cancel"
        onClick={() => setSuccess(true)}
        title="Location details">
        <form
          noValidate
          className="grid gap-2.5"
          onSubmit={handleSubmit(onSubmit, errors => console.error(errors))}>
          <div className="grid items-start gap-2.5 md:grid-cols-2">
            <LocationField required label="Location:*" />
            <PhoneField required />
          </div>
          <div className="grid gap-2.5 md:grid-cols-2">
            <DateField
              required
              filterDate={filterDate}
              label="Date"
              name="date"
            />
            <TimeSlotField />
          </div>
          <Alert className="mb-0">{errorMessage}</Alert>
          <Button block type="submit">
            Confirm location & date
          </Button>
        </form>
      </Widget>
    </FormProvider>
  );
};
