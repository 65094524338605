import { plainToInstance } from 'class-transformer';
import { Address, Chef, Photo, PhotoType, User } from '../model';
import { HttpClient } from './HttpClient';

interface PresignedImageResponse {
  image_url: string;
  image_url_key: string;
}

export type UpdateChefDTO = Partial<Omit<Chef, 'profile'>> & {
  profile?: Partial<User>;
};

export interface UserDTO extends Partial<Omit<User, 'date_of_birth'>> {
  date_of_birth?: string;
}

export class UsersApi {
  static async get(id: User['id']): Promise<Chef> {
    const { data } = await HttpClient.getInstance().get(`/users/${id}/chef`);

    return data;
  }

  static async updateChef(id: User['id'], chef: UpdateChefDTO): Promise<Chef> {
    const { data } = await HttpClient.getInstance().put(
      `/users/${id}/chef`,
      chef,
    );

    return data;
  }

  static async getPhotos(id: User['id']): Promise<Photo[]> {
    const { data } = await HttpClient.getInstance().get<Photo[]>(
      `/users/${id}/user-photos/`,
    );

    return plainToInstance(Photo, data);
  }

  static async changeAvatar(
    id: User['id'],
    imageUrlKey: PresignedImageResponse['image_url_key'],
  ): Promise<any> {
    const { data } = await HttpClient.getInstance().post(
      `/users/${id}/user-photos/`,
      { image_url_key: imageUrlKey },
    );

    return data;
  }

  static async me(): Promise<User> {
    const { data } = await HttpClient.getInstance().get<User>('/users/me/');

    return plainToInstance(User, data);
  }

  static async changeAddress(
    userId: number,
    address: Address,
  ): Promise<User | { error: string }> {
    const { data } = await HttpClient.getInstance().patch<User>(
      `/users/${userId}/update/`,
      address,
    );

    return plainToInstance(User, data);
  }

  static async createPresignedImageUrl(
    type: PhotoType,
  ): Promise<PresignedImageResponse> {
    const { data } = await HttpClient.getInstance().post(
      '/users/presigned-image-url/',
      { photo_type: type },
    );

    return data;
  }

  static async update(id: number, user: UserDTO): Promise<User> {
    const { data } = await HttpClient.getInstance().patch(
      `/users/${id}/update/`,
      user,
    );

    if ('error' in data) {
      throw new Error(data.error);
    }

    return plainToInstance(User, data);
  }
}
