import { FC } from 'react';
import { useQuery } from 'react-query';
import { FoodApi } from '../../api';
import { useModal } from '../../hooks';
import { ShoppingCartItem } from '../../model';
import { Button, Card, Heading, Money, Paragraph } from '../atoms';
import { FoodCategories } from '../molecules';
import { FoodDetails } from './FoodDetails';
import { Modal } from './Modal';

interface Props {
  item: ShoppingCartItem;
}

export const OrderItemCard: FC<Props> = ({ item }) => {
  const { data: food } = useQuery(['food', item.food_service_pk], () =>
    FoodApi.get(item.food_service_pk),
  );
  const { modalRef, openModal } = useModal();

  return (
    <Card
      className="md:flex-row"
      preview={
        <img
          alt={item.name}
          className="block rounded-t-lg md:rounded-t-none md:rounded-l-lg object-cover h-[251px] md:h-full md:max-w-[192px]"
          src={item.image}
        />
      }>
      <header className="mb-2.5 md:mb-1.5 flex-1">
        <div className="mb-0.5 flex items-center justify-between">
          <Paragraph semiBold size="M">
            {item.name}
          </Paragraph>

          <Heading className="text-blue-100" level={6}>
            <Money amount={Number(item.amount)} />
          </Heading>
        </div>
        <FoodCategories categories={item.food_categories} />
      </header>
      <div className="flex flex-col md:flex-row justify-between gap-1.5">
        <Button
          className="justify-center"
          onClick={() => openModal()}
          theme="secondary">
          View details
        </Button>
        <Paragraph
          className="px-[15px] py-[9px] border border-navy-20 rounded-md text-center text-navy-60"
          size="S">
          {item.servings}&nbsp;{item.servings === 1 ? 'portion' : 'portions'}
        </Paragraph>
      </div>
      {!!food && (
        <Modal ref={modalRef}>
          <FoodDetails readonly food={food} />
        </Modal>
      )}
    </Card>
  );
};
