import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { Logo, TestimonialCard } from '../components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper';
import styles from './AuthLayout.module.css';
import classNames from 'classnames';

export const AuthLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [index, setIndex] = useState(0);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const slideTo = useCallback(
    (index: number) => {
      if (!swiper) {
        return;
      }

      swiper.slideTo(index);
    },
    [swiper],
  );

  return (
    <div className="min-h-screen bg-blue-5 md:grid md:grid-cols-2">
      <header className="p-2 md:hidden">
        <Logo />
      </header>
      <main className="p-2 md:p-3.5 md:flex md:flex-col md:gap-3.5">
        <header className="hidden md:block">
          <Logo />
        </header>
        <div className="md:flex-1 md:flex md:flex-col md:items-center md:justify-center">
          <div className="md:w-full md:mx-auto md:max-w-[384px]">
            {children}
          </div>
        </div>
      </main>
      <aside className="hidden relative overflow-hidden md:flex md:items-center md:justify-center md:p-3.5 md:pt-10 md:bg-blue-100">
        <div className={styles.shape} />
        <Swiper
          className="max-w-[500px]"
          onSwiper={setSwiper}
          slidesPerView={1}
          onSlideChange={swiper => setIndex(swiper.realIndex)}
          spaceBetween={50}>
          <SwiperSlide>
            <TestimonialCard
              avatar={'https://media.getchefnow.com/images/Jenny-edited.jpg'}
              name="Jenny"
              location="Toronto, CA">
              "The food was fantastic! She cooked, cleaned up after, answered
              any questions I had, was super friendly and I genuinely enjoyed
              the overall experience. Something I would definitely do again.
              Highly recommend!"
            </TestimonialCard>
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialCard
              avatar={'https://media.getchefnow.com/images/Vincent-edited.jpg'}
              name="Vincent"
              location="Toronto, CA">
              "It was a delightful and interesting experience. Me and my friends
              were able to watch the chef cook. The shrimp pasta was delicious.
              I recommend GetChefNow with friends or family. I will definitely
              do it again."
            </TestimonialCard>
          </SwiperSlide>
          <ul className="mt-3 flex justify-center gap-1.5">
            <li
              className={classNames(
                'w-[16px] h-[16px] rounded-full cursor-pointer bg-blue-40',
                index === 0 && 'bg-white',
              )}
              onClick={() => slideTo(0)}
            />
            <li
              className={classNames(
                'w-[16px] h-[16px] rounded-full cursor-pointer bg-blue-40',
                index === 1 && 'bg-white',
              )}
              onClick={() => slideTo(1)}
            />
          </ul>
        </Swiper>
      </aside>
    </div>
  );
};
