import { Card } from '@stripe/stripe-js';
import { OrderStatus } from './OrderStatus';
import { ShoppingCart } from './ShoppingCart';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Expose, Type } from 'class-transformer';
import { format } from 'date-fns';
import { generatePath } from 'react-router-dom';
import { RoutePath } from '../config';

export class Order {
  readonly address!: string;
  readonly amount!: number;
  readonly cart!: ShoppingCart;
  readonly chef_name?: string;
  readonly chef_user_pk?: number;
  readonly completed_by!: string;
  readonly customer_name!: string;
  readonly customer_supplying_groceries: boolean = false;
  readonly has_visitor_parking: boolean = false;
  readonly id!: number;
  readonly notes?: string;
  readonly payment_method?: Card;

  @Expose({ name: 'start_date_time' })
  @Type(() => Date)
  readonly date: Date = new Date();

  readonly status: OrderStatus = OrderStatus.Requested;
  readonly subtotal_amount!: number;
  readonly tax_amount!: number;

  formatDate(): string {
    return format(this.date, 'dd/MM/yyyy hh:mm a');
  }

  get url(): string {
    return generatePath(RoutePath.Order, {
      id: this.id.toString(),
    });
  }

  get hasChef(): boolean {
    return Boolean(this.chef_user_pk);
  }

  get isCanceled(): boolean {
    return this.status === OrderStatus.Canceled;
  }

  get isCancelable(): boolean {
    return !this.hasChef && this.status !== OrderStatus.Canceled;
  }

  get isCompleted(): boolean {
    return this.status === OrderStatus.Completed;
  }

  get isCompletable(): boolean {
    return this.hasChef && this.status !== OrderStatus.Completed;
  }
}
