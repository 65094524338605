import { PhoneTelephone } from '@icon-park/react';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { usePhoneUtil } from '../../hooks';
import { Input } from '../atoms/Input';

interface Props {
  required?: boolean;
}

export const PhoneField: FC<Props> = ({ required = false }) => {
  const phoneUtil = usePhoneUtil();
  const [countryCode, setCountryCode] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const { watch } = useFormContext();
  const country = watch('country_iso_code');

  useEffect(() => {
    if (country && phoneUtil) {
      try {
        const examplePhoneNumber = phoneUtil.getExampleNumber(country);
        const countryCode = examplePhoneNumber.getCountryCode();

        if (countryCode !== undefined) {
          setCountryCode(countryCode.toString());
        }

        setPlaceholder(
          phoneUtil.format(examplePhoneNumber, PhoneNumberFormat.INTERNATIONAL),
        );
      } catch (error) {
        console.error(error);
      }
    }
  }, [country, phoneUtil, setPlaceholder]);

  const mask = useMemo(() => {
    const phoneNumberBody = placeholder.replace(`+${countryCode}`, '');

    return `+${countryCode}${phoneNumberBody
      .split('')
      .map(item => {
        if (item === ' ') {
          return item;
        }

        if (!Number.isNaN(Number(item))) {
          return '9';
        }

        return item;
      })
      .join('')}`;
  }, [countryCode, placeholder]);

  return (
    <Input
      required={required}
      label="Contact phone:"
      name="phone"
      minLength={mask.length}
      mask={mask}
      placeholder={placeholder}
      suffix={<PhoneTelephone className="text-navy-60" size={24} />}
    />
  );
};
