import { HttpClient } from './../api/HttpClient';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  isAuthorized: boolean;
  token: string | null;
}

const initialState: State = {
  isAuthorized: false,
  token: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut(state) {
      state.isAuthorized = false;
      state.token = null;
      localStorage.removeItem('token');
      HttpClient.clearToken();
    },
    setCredentials: (
      state,
      { payload: { token } }: PayloadAction<{ token: string }>,
    ) => {
      state.token = token;
      localStorage.setItem('token', token);
      HttpClient.setToken(token);
      state.isAuthorized = true;
    },
  },
});
