import type { FC } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { FoodApi } from '../../api';
import { RoutePath } from '../../config';
import { Button, Content, Heading } from '../atoms';
import { FoodCard } from '../organisms/FoodCard';

export const HomeFoodSection: FC = () => {
  const { data, isSuccess } = useQuery('foods', () => FoodApi.getAll(true));

  if (!isSuccess) {
    return null;
  }

  return (
    <section className="py-8 md:py-12">
      <Content>
        <header className="mb-6 md:mb-8 flex justify-between items-end">
          <Heading className="text-navy-100" level={2}>
            Featured menus
          </Heading>
          <Link className="hidden lg:inline-flex" to={RoutePath.Cuisines}>
            <Button>Explore menu</Button>
          </Link>
        </header>
        <ul className="grid lg:grid-cols-3 gap-4">
          {data?.map(food => (
            <FoodCard key={food.id} food={food} />
          ))}
        </ul>
        <Link className="lg:hidden" to={RoutePath.Cuisines}>
          <Button block className="mt-4">
            Explore menu
          </Button>
        </Link>
      </Content>
    </section>
  );
};
