import classNames from 'classnames';
import { FC, PropsWithChildren, useCallback } from 'react';
import type {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { AuthApi } from '../../api';
import { ReactComponent as Icon } from '../../assets/icons/facebook-social-auth.svg';
import { useAppDispatch, useAppSelector, useRoleFromQueryString } from '../../hooks';
import { authSlice, selectConfig } from '../../redux';
import { Button } from './Button';

interface Props {
  className?: string;
}

export const FacebookAuthButton: FC<PropsWithChildren<Props>> = ({
  className,
  children,
}) => {
  const dispatch = useAppDispatch();
  const role = useRoleFromQueryString();
  const { facebookLoginAppId } = useAppSelector(selectConfig);

  const handleRespone = useCallback(
    async (userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
      if ('id' in userInfo) {
        const { token } = await AuthApi.facebook({
          access_token: userInfo.accessToken,
          active_profile: role,
          email: userInfo.email,
          id: userInfo.userID,
          name: userInfo.name,
          picture: userInfo.picture?.data.url,
        });

        dispatch(
          authSlice.actions.setCredentials({
            token,
          }),
        );
      }
    },
    [dispatch, role],
  );

  return (
    <FacebookLogin
      appId={facebookLoginAppId}
      callback={handleRespone}
      render={renderProps => (
        <Button
          block
          className={classNames('text-center', className)}
          onClick={renderProps.onClick}
          theme="outlined">
          <Icon />
          {children}
        </Button>
      )}
    />
  );
};
