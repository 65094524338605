import { PhoneNumberFormat } from 'google-libphonenumber';
import type { FC } from 'react';
import { Configuration } from '../../config';
import { usePhoneUtil } from '../../hooks';
import { Chef } from '../../model';
import { Widget } from './Widget';

interface Props {
  chef: Chef;
}

export const OrderChefWidget: FC<Props> = ({ chef }) => {
  const phoneUtil = usePhoneUtil();
  const phoneNumber = phoneUtil.parse(
    chef.profile.phone,
    chef.profile.country_iso_code ?? Configuration.defaultCountryISOCode,
  );

  return (
    <Widget title="Assigned chef">
      <div className="flex items-start gap-1.5">
        <img
          alt="Chef"
          className="flex-[0_0_80px] rounded-md"
          height={80}
          src={chef.profile.photoURL ?? ''}
          width={80}
        />
        <p>
          <span className="mb-[2px] block paragraph-m font-semibold text-navy-100">
            {chef.profile.first_name} {chef.profile.last_name}
          </span>
          <span className="mb-[2px] block paragraph-s font-semibold text-blue-100">
            {phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL)}
          </span>
          <span className="block text-navy-70">{chef.about}</span>
        </p>
      </div>
    </Widget>
  );
};
