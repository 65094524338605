export enum RoutePath {
  AccountSettings = '/account/settings',
  Apps = '/#apps',
  Checkout = '/checkout',
  Covid19 = '/covid19',
  CreateFood = '/create-food',
  CuisineMenu = '/cuisines/:id',
  Cuisines = '/cuisines',
  FAQ = '/#faq',
  ForgotPassword = '/forgot-password',
  Home = '/',
  Login = '/login',
  MQL = '/mql',
  Order = '/orders/:id',
  OrderHistory = '/orders',
  Pricing = '/#pricing',
  Privacy = '/privacypolicy',
  Referral = '/referral',
  Register = '/register',
  RegisterChef = '/register-chef',
  ShoppingCart = '/shopping-cart',
  Terms = '/terms',
}
