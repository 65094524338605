import { Listbox, Transition } from '@headlessui/react';
import { Down } from '@icon-park/react';
import classNames from 'classnames';
import { FC, Fragment, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

export interface SelectProps {
  className?: string;
  helper?: string;
  label?: string;
  name: string;
  options: SelectOption[];
  placeholder?: string;
  required?: boolean;
}

export interface SelectOption {
  label: string;
  value?: string;
}

export const Select: FC<SelectProps> = ({
  className,
  helper,
  label,
  name,
  options,
  placeholder,
  required = false,
}) => {
  const { formState, getFieldState, register, setValue, watch } =
    useFormContext();
  const { invalid } = getFieldState(name, formState);
  const value = watch(name);
  register(name, {
    required,
  });

  const handleChange = useCallback(
    (newSelected: SelectOption) => setValue(name, newSelected.value),
    [name, setValue],
  );

  const selected = options.find(option => option.value === value);

  return (
    <Listbox value={selected} onChange={handleChange}>
      <div className={classNames('relative', className)}>
        {Boolean(label) && (
          <span className="input-label">
            {label}
            {required && '*'}
          </span>
        )}
        <Listbox.Button
          className={classNames('relative input pr-[46px]', {
            'input-invalid': invalid,
          })}
          name={name}>
          <span className="block truncate text-left">
            {selected?.label ?? (
              <span className="text-navy-60">{placeholder}</span>
            )}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[14px]">
            <Down className="text-navy-60" size={24} />
          </span>
        </Listbox.Button>
        {Boolean(helper) && <span className="input-helper">{helper}</span>}
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <Listbox.Options className="absolute z-10 mt-0.5 max-h-[300px] overflow-auto rounded-md bg-white shadow-dropdown border border-navy-20 w-max min-w-full">
            {options.map(option => (
              <Listbox.Option
                key={option.value ?? option.label}
                className={({ active }) =>
                  `px-[14px] py-1 text-lg leading-24 text-navy-100 cursor-pointer ${
                    active ? 'bg-blue-10' : 'bg-white'
                  }`
                }
                value={option}>
                {({ selected }) => (
                  <>
                    <span>{option.label}</span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
