import classNames from 'classnames';
import type { FC, PropsWithChildren, ReactNode } from 'react';

interface Props {
  className?: string;
  preview: ReactNode;
}

export const Card: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  preview,
}) => {
  return (
    <li
      className={classNames('flex flex-col rounded-lg shadow-card bg-white', className)}>
      {preview}
      <div className="flex-1 flex flex-col p-2">{children}</div>
    </li>
  );
};
