import type { FC } from 'react';
import { AccountSettingsForm, Content } from '../components';
import { ChefSettingsForm } from '../components/organisms/ChefSettingsForm';
import { useChef, useCuisines, useProfile } from '../hooks';
import { Layout } from '../layout';

export const AccountSettingsPage: FC = () => {
  const { profile, isChef, isCustomer } = useProfile();
  const chef = useChef(profile?.id);
  const cuisines = useCuisines();

  return (
    <Layout inner>
      <Content className="py-4 md:py-6">
        <div className="md:mx-auto md:max-w-[600px]">
          {!!profile && isCustomer && <AccountSettingsForm profile={profile} />}
          {!!profile && isChef && !!chef && cuisines.length > 0 && (
            <ChefSettingsForm
              chef={chef}
              cuisines={cuisines}
              profile={profile}
            />
          )}
        </div>
      </Content>
    </Layout>
  );
};
