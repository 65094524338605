import { useCallback } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Address } from '../model';

export function usePlaces() {
  const {
    getPlacePredictions,
    placePredictions,
    placesService,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE,
    debounce: 500,
    sessionToken: true,
  });

  const getPlaceDetails = useCallback(
    async (id: string) => {
      return new Promise<Address>((resolve, reject) => {
        if (!placesService) {
          return reject();
        }

        placesService.getDetails(
          {
            placeId: id,
          },
          (details: google.maps.places.PlaceResult | null) => {
            if (details !== null) {
              const getAddressComponentByType = (
                type: string,
              ): google.maps.GeocoderAddressComponent | undefined => {
                return details.address_components?.find(component =>
                  component.types.some(item => item === type),
                );
              };

              const postalCode = getAddressComponentByType('postal_code');
              const country = getAddressComponentByType('country');
              const state = getAddressComponentByType(
                'administrative_area_level_1',
              );
              const locality = getAddressComponentByType('locality');
              const street = getAddressComponentByType('route');
              const houseNumber = getAddressComponentByType('street_number');

              const fullAddress: Partial<Address> = {
                address:
                  houseNumber || street
                    ? [houseNumber?.long_name, street?.long_name]
                        .filter(Boolean)
                        .join(' ')
                    : undefined,
                city: locality?.long_name,
                country_iso_code: country?.short_name,
                country: country?.long_name,
                state: state?.long_name,
                zipcode: postalCode?.long_name,
              };

              return resolve(fullAddress as Address);
            }

            reject();
          },
        );
      });
    },
    [placesService],
  );

  return {
    getPlaceDetails,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placePredictions,
    placesService,
  };
}
