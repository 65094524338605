import { useRef, useCallback } from "react";
import { ModalRef } from "../components";

export function useModal() {
  const modalRef = useRef<ModalRef>(null);

  const openModal = useCallback(() => modalRef.current?.open(), []);

  return {
    modalRef,
    openModal,
  }
}