import { differenceInDays } from 'date-fns';
import type { FC } from 'react';
import { DateField, LocationField } from '..';
import { useChefOnboardingFlow, useCuisines } from '../../hooks';
import { User } from '../../model';
import { Button, Checkbox, Heading, Textarea } from '../atoms';

interface Props {
  profile: User;
}

export const ChefInformationStep: FC<Props> = ({ profile }) => {
  const cuisines = useCuisines();
  const { goToNextStep } = useChefOnboardingFlow(profile, [
    'description',
    'location_id',
  ]);

  return (
    <div>
      <Heading
        className="mb-0.5 md:mb-[12px] text-navy-100 text-center"
        level={5}>
        Tell us more about you.
      </Heading>
      <h6 className="mb-3.5 paragaph-s text-navy-60 text-center">
        Add in extra information about yourself.
      </h6>
      <div className="grid gap-2.5">
        <DateField
          required
          filterDate={date => differenceInDays(date, new Date()) < 0}
          label="Birth date"
          name="birthDate"
        />
        <LocationField required label="Location*" />
        <Textarea
          required
          label="Short description"
          name="description"
          placeholder="Describe your professional experience"
          rows={3}
        />
        <div>
          <span className="input-label">Cuisines*</span>
          <div className="grid grid-cols-2 gap-1.5">
            {cuisines.map((cuisine, index) => (
              <Checkbox
                key={cuisine.name}
                name={`cuisines[${index}]`}
                value={cuisine.name}>
                {cuisine.name}
              </Checkbox>
            ))}
          </div>
        </div>
        <Button block onClick={goToNextStep}>
          Continue
        </Button>
      </div>
    </div>
  );
};
