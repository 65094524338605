import { ErrorResponse } from './ErrorResponse';
import axios from 'axios';
import { HttpClient } from './HttpClient';
import { ApiError } from './ApiError';
import { Role } from '../model';

export interface SignUpRequest {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  active_profile: Role;
  phone?: string;
}

export interface SignInRequest {
  email: string;
  password: string;
}

interface GoogleAuthRequest {
  access_token: string;
  active_profile: Role;
  email: string;
  first_name: string;
  id_token: string;
  id: string;
  last_name: string;
  picture: string;
}

interface FacebookAuthRequest {
  access_token: string;
  active_profile: Role;
  email?: string;
  id: string;
  name?: string;
  picture?: string;
}

export interface AuthResponse {
  active_profile: 0 | 1;
  active_profile_display: Role;
  expiry: number;
  token: string;
}

export class AuthApi {
  static async signUp(request: SignUpRequest): Promise<AuthResponse> {
    try {
      const { data } = await HttpClient.getInstance().post<AuthResponse>(
        '/users/signup/',
        request,
      );

      return data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const data = error.response.data as ErrorResponse<SignUpRequest>;
        throw new ApiError(data);
      }
    }

    throw new ApiError();
  }

  static async signIn(request: SignInRequest): Promise<AuthResponse> {
    try {
      const { data } = await HttpClient.getInstance().post<AuthResponse>(
        '/token/',
        request,
      );

      return data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const data = error.response.data as ErrorResponse<SignInRequest>;
        throw new ApiError(data);
      }
    }

    throw new ApiError();
  }

  static async forgotPassword(email: string): Promise<void> {
    try {
      await HttpClient.getInstance().post('/users/forgot-password/', { email });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const data = error.response.data as ErrorResponse<SignInRequest>;
        throw new ApiError(data);
      }
    }
  }

  static async google(request: GoogleAuthRequest): Promise<AuthResponse> {
    const { data } = await HttpClient.getInstance().post<AuthResponse>(
      '/users/google-login/',
      request,
    );

    return data;
  }

  static async facebook(request: FacebookAuthRequest): Promise<AuthResponse> {
    const { data } = await HttpClient.getInstance().post<AuthResponse>(
      '/users/fb-login/',
      request,
    );

    return data;
  }
}
