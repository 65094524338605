import { FC } from 'react';
import { useMqlStep } from '../../hooks';
import { Button, Heading, Paragraph } from '../atoms';
import { LocationField } from './LocationField';

export const MqlLocationStep: FC = () => {
  const { update } = useMqlStep(0, ['location_id']);

  return (
    <div>
      <Heading className="mb-0.5 md:mb-[12px] text-navy-100 text-center" level={5}>
        Where do you need your chef?
      </Heading>
      <Paragraph className="mb-3.5 text-navy-60 text-center" size="S">
        Please enter the location of your occasion.
      </Paragraph>
      <LocationField required label="Location:*" />
      <Button block className="mt-2.5" onClick={update}>
        Continue
      </Button>
    </div>
  );
};
