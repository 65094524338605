import type { FC } from 'react';
import { useOrder } from '../../hooks';
import { Order } from '../../model';
import { Button, Money } from '../atoms';
import { Widget } from '../molecules';

interface Props {
  className?: string;
  order: Order;
}

export const OrderSummary: FC<Props> = ({ className, order }) => {
  const { completeOrder, deleteOrder } = useOrder(order.id);

  return (
    <Widget className={className} title="Order details">
      <ul className="mt-2.5">
        <li className="mb-[12px] flex justify-between items-center">
          <span className="text-navy-70">Date of booking</span>
          <span className="text-navy-100 font-semibold">
            {order.formatDate()}
          </span>
        </li>
        <li className="mb-[12px] flex justify-between items-center">
          <span className="text-navy-70">Order ID</span>
          <span className="text-navy-100 font-semibold">{order.id}</span>
        </li>
        {!!order.payment_method && (
          <li className="mb-[12px] flex justify-between items-center">
            <span className="text-navy-70">Card used</span>
            <span className="text-navy-100 font-semibold">
              ****{order.payment_method.last4}
            </span>
          </li>
        )}
      </ul>
      <hr className="mt-[12px] mb-[12px] border-navy-10" />
      <ul>
        {order.cart.cart_items.map(item => (
          <li
            className="mb-[12px] flex justify-between items-center"
            key={item.id}>
            <span className="text-navy-70">
              {item.servings}x&nbsp;{item.name}
            </span>
            <span className="text-navy-100 font-semibold">
              <Money amount={Number(item.amount)} />
            </span>
          </li>
        ))}
        <li className="mb-[12px] flex justify-between items-center">
          <span className="text-navy-70">Grocery cost</span>
          <span className="text-navy-100 font-semibold">
            <Money amount={order.cart.grocery_cost} />
          </span>
        </li>
        {!!order.tax_amount && (
          <li className="mb-[12px] flex justify-between items-center">
            <span className="text-navy-70">Tax charged</span>
            <span className="text-navy-100 font-semibold">
              <Money amount={Number(order.tax_amount)} />
            </span>
          </li>
        )}
        {!!order.cart.multi_item_savings && (
          <li className="mb-[12px] flex justify-between items-center text-green-120">
            <span>Plan savings</span>
            <span className="font-semibold">
              -<Money amount={order.cart.multi_item_savings} />
            </span>
          </li>
        )}
      </ul>
      <hr className="mt-[12px] mb-[12px] border-navy-10" />
      <div className="flex justify-between items-center text-navy-100">
        <span>Total charged</span>
        <span className="text-navy-100 font-semibold">
          <Money amount={Number(order.amount)} />
        </span>
      </div>
      <p className="mt-[12px] text-navy-50 font-semibold">
        This price includes the travelling fees, the service, the cooking, and
        the cleaning. Once you’re charged the separate ingredients fee you’ll
        see it on this page.
      </p>
      {order.isCancelable && (
        <Button block className="mt-2.5" onClick={deleteOrder} theme="danger">
          Cancel order
        </Button>
      )}
      {order.isCompletable && (
        <Button
          block
          className="mt-2.5"
          onClick={completeOrder}
          theme="success">
          Mark complete
        </Button>
      )}
    </Widget>
  );
};
