import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { AuthRoute, LoginForm, Paragraph } from '../components';
import { RoutePath } from '../config';
import { useRoleFromQueryString } from '../hooks';
import { AuthLayout } from '../layout';

export const LoginPage: FC = () => {
  const role = useRoleFromQueryString();

  return (
    <AuthRoute>
      <AuthLayout>
        <LoginForm withHeader />
        <footer className="mt-2.5">
          <Paragraph className="text-center text-navy-100" size="S">
            Don’t have an account?&nbsp;
            <Link
              className="text-blue-100"
              to={`${RoutePath.Register}?role=${role}`}>
              Sign up
            </Link>
          </Paragraph>
        </footer>
      </AuthLayout>
    </AuthRoute>
  );
};
