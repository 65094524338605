import type { FC } from 'react';
import { Content, Heading, Paragraph } from '../atoms';
import styles from './HomePricingSection.module.css';
import { Check } from '@icon-park/react';

export const HomePricingSection: FC = () => {
  return (
    <section className="py-8 md:py-12" id="pricing">
      <Content>
        <Heading className="mb-1.5 text-navy-100" level={2}>
          Plans for everyone
        </Heading>
        <Paragraph className="mb-6 text-navy-70 md:mb-10" size="M">
          Whether you need small or large quantities, we’ve got you covered.
        </Paragraph>

        <div className="grid grid-cols-1 gap-3 md:grid-cols-3 md:gap-6">
          <div className="flex-1 pt-2 bg-white rounded-lg shadow-card text-center">
            <Heading className="mb-0.5 text-navy-50" level={4}>
              Basic
            </Heading>
            <span className={styles.price} data-suffix="00">
              $20
            </span>
            <Paragraph className="mb-3.5 text-navy-70" size="S">
              per portion
            </Paragraph>
            <hr className={styles.separator} />
            <div className="text-center">
              <ul className="p-2.5 inline-block text-left">
                <li className="mb-1.5 flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    One item
                  </Paragraph>
                </li>
                <li className="mb-1.5 flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    4 portions
                  </Paragraph>
                </li>
                <li className="mb-1.5 flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    Total 4 portions
                  </Paragraph>
                </li>
                <li className="mb-1.5 flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    Any cuisine
                  </Paragraph>
                </li>
                <li className="flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    Good for 4 people
                  </Paragraph>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex-1 bg-white rounded-b-lg shadow-card text-center">
            <span className={styles.label}>Best value</span>
            <Heading className="mt-2 mb-0.5 text-navy-50" level={4}>
              Essential
            </Heading>
            <span className={styles.price} data-suffix="00">
              $15
            </span>
            <Paragraph className="mb-3.5 text-navy-70" size="S">
              per portion
            </Paragraph>
            <hr className={styles.separator} />
            <div className="text-center">
              <ul className="p-2.5 inline-block text-left">
                <li className="mb-1.5 flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    Two items
                  </Paragraph>
                </li>
                <li className="mb-1.5 flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    4 portions each
                  </Paragraph>
                </li>
                <li className="mb-1.5 flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    Total 8 portions
                  </Paragraph>
                </li>
                <li className="mb-1.5 flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    Any cuisine
                  </Paragraph>
                </li>
                <li className="flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    Good for 4-6 people
                  </Paragraph>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex-1 pt-2 bg-white rounded-lg shadow-card text-center">
            <Heading className="mb-0.5 text-navy-50" level={4}>
              Supreme
            </Heading>
            <span className={styles.price} data-suffix="67">
              $12
            </span>
            <Paragraph className="mb-3.5 text-navy-70" size="S">
              per portion
            </Paragraph>
            <hr className={styles.separator} />
            <div className="text-center">
              <ul className="p-2.5 inline-block text-left">
                <li className="mb-1.5 flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    Three items
                  </Paragraph>
                </li>
                <li className="mb-1.5 flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    4 portions each
                  </Paragraph>
                </li>
                <li className="mb-1.5 flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    Total 12 portions
                  </Paragraph>
                </li>
                <li className="mb-1.5 flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    Any cuisine
                  </Paragraph>
                </li>
                <li className="flex items-center gap-0.5">
                  <Check className="text-blue-100" size={24} />
                  <Paragraph semiBold className="text-navy-100" size="S">
                    Good for 6-8 people
                  </Paragraph>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Content>
    </section>
  );
};
