import type { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  AuthRoute,
  Content,
  Logo,
  MqlAuthStep,
  MqlDateTimeStep,
  MqlLocationStep,
  MqlOccasionStep,
  Steps,
} from '../components';
import { Configuration, RoutePath } from '../config';
import { MqlFormFields } from '../hooks';

export const MqlPage: FC = () => {
  const form = useForm<MqlFormFields>({
    defaultValues: {
      country_iso_code: Configuration.defaultCountryISOCode,
      step: 1,
    },
  });
  const activeStep = form.watch('step');

  return (
    <AuthRoute>
      <div className="min-h-screen bg-blue-5">
        <header className="py-1.5 px-2 md:p-2.5">
          <Content className="flex items-center justify-between">
            <Logo />
            <span className="md: block paragraph-s text-navy-60">
              Already have an account?&nbsp;
              <Link className="link" to={RoutePath.Login}>
                Login
              </Link>
            </span>
          </Content>
        </header>
        <main className="pt-4 mx-auto max-w-[400px]">
          <FormProvider {...form}>
            <Steps
              activeStep={activeStep}
              className="mx-auto mb-4 w-[288px]"
              stepsCount={4}
            />
            {activeStep === 0 && <MqlLocationStep />}
            {activeStep === 1 && <MqlOccasionStep />}
            {activeStep === 2 && <MqlDateTimeStep />}
            {activeStep === 3 && <MqlAuthStep />}
          </FormProvider>
        </main>
      </div>
    </AuthRoute>
  );
};
