import type { SelectOption } from '../components';
import { FoodDietary, FoodType, MeasureUnit } from '../model';

export class Configuration {
  static readonly defaultCountryISOCode = 'CA';

  static readonly foodTypes: Required<SelectOption>[] = [
    {
      value: FoodType.Entree,
      label: 'Entree',
    },
    {
      value: FoodType.Appetizer,
      label: 'Appetizer',
    },
    {
      value: FoodType.Dessert,
      label: 'Dessert',
    },
  ];

  static readonly foodDietaries: Required<SelectOption>[] = [
    {
      value: FoodDietary.Meat,
      label: 'Meat',
    },
    {
      value: FoodDietary.Vegetarian,
      label: 'Vegetarian',
    },
    {
      value: FoodDietary.Vegan,
      label: 'Vegan',
    },
    {
      value: FoodDietary.Seafood,
      label: 'Seafood',
    },
    {
      value: FoodDietary.DairyFree,
      label: 'Dairy free',
    },
  ];

  static readonly measureUnits: Required<SelectOption>[] = [
    {
      value: MeasureUnit.Grams,
      label: 'Grams',
    },
    {
      value: MeasureUnit.Milliliters,
      label: 'Milliliters',
    },
    {
      value: MeasureUnit.Litres,
      label: 'Litres',
    },
    {
      value: MeasureUnit.TableSpoon,
      label: 'Table spoon',
    },
    {
      value: MeasureUnit.TeaSpoon,
      label: 'Tea spoon',
    },
  ];
}
