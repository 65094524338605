import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';
import styles from './OrganicShapeBackground.module.css';

interface Props {
  position: 'left' | 'right';
}

export const OrganicShapeBackground: FC<PropsWithChildren<Props>> = ({
  children,
  position,
}) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.right]: position === 'right',
      })}>
      {children}
    </div>
  );
};
