import { Address } from './../model/User';
import { HttpClient } from './HttpClient';

export interface MqlDTO {
  full_address?: Address;
  occasion?: string;
  start_date_time?: Date;
}

export class MqlApi {
  static async update(body: MqlDTO): Promise<void> {
    const { data } = await HttpClient.getInstance().post('/users/mql/', body);

    console.log('debug: data ', data);
  }
}
