import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';
import { ReactComponent as Icon } from '../../assets/icons/google-social-auth.svg';
import { Button } from './Button';
import { useGoogleLogin } from 'react-google-login';
import { useAppDispatch, useAppSelector, useRoleFromQueryString } from '../../hooks';
import { authSlice, selectConfig } from '../../redux';
import { AuthApi } from '../../api';

interface Props {
  className?: string;
}

export const GoogleAuthButton: FC<PropsWithChildren<Props>> = ({
  className,
  children,
}) => {
  const dispatch = useAppDispatch();
  const role = useRoleFromQueryString();
  const { googleLoginClientId } = useAppSelector(selectConfig);
  const { signIn } = useGoogleLogin({
    clientId: googleLoginClientId,
    onSuccess: async response => {
      if ('accessToken' in response) {
        const { token } = await AuthApi.google({
          access_token: response.accessToken,
          active_profile: role,
          email: response.profileObj.email,
          first_name: response.profileObj.givenName,
          id_token: response.tokenId,
          id: response.profileObj.googleId,
          last_name: response.profileObj.familyName,
          picture: response.profileObj.imageUrl,
        });

        dispatch(
          authSlice.actions.setCredentials({
            token,
          }),
        );
      }
    },
  });

  return (
    <Button
      block
      className={classNames('text-center', className)}
      onClick={signIn}
      theme="outlined">
      <Icon />
      {children}
    </Button>
  );
};
