import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import styles from './Steps.module.css';

interface Props {
  activeStep: number;
  stepsCount: number;

  className?: string;
}

enum StepState {
  Active,
  Done,
  Inactive,
}

export const Steps: FC<Props> = ({ activeStep, stepsCount, className }) => {
  const steps = useMemo(() => {
    return Array.from(Array(stepsCount).keys());
  }, [stepsCount]);

  return (
    <ol className={classNames('flex items-center', className)}>
      {steps.map(step => {
        let state = StepState.Inactive;

        if (step < activeStep) {
          state = StepState.Done;
        }

        if (step === activeStep) {
          state = StepState.Active;
        }

        return <Step key={step} state={state} />;
      })}
    </ol>
  );
};

const Step: FC<{ state?: StepState }> = ({ state = StepState.Inactive }) => {
  return (
    <li
      className={classNames(styles.step, {
        [styles.done]: state === StepState.Done,
      })}>
      <span
        className={classNames(styles.indicator, {
          [styles.active]: state === StepState.Active,
          [styles.inactive]: state === StepState.Inactive,
          [styles.done]: state === StepState.Done,
        })}>
        {state !== StepState.Done && (
          <span className="block w-[6px] h-[6px] bg-white rounded-full" />
        )}
        {state === StepState.Done && <CheckIcon />}
      </span>
    </li>
  );
};
