import type { FC } from 'react';
import { Content, Heading, TestimonialCard } from '../atoms';

export const HomeTestimonialsSection: FC = () => {
  return (
    <section className="py-8 bg-blue-5 md:py-12">
      <Content>
        <Heading className="mb-6 text-navy-100" level={2}>
          What our customers say
        </Heading>
        <ul className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6">
          <TestimonialCard
            avatar={'https://media.getchefnow.com/images/Jenny-edited.jpg'}
            name="Jenny"
            location="Toronto, CA">
            "The food was fantastic! She cooked, cleaned up after, answered any
            questions I had, was super friendly and I genuinely enjoyed the
            overall experience. Something I would definitely do again. Highly
            recommend!"
          </TestimonialCard>
          <TestimonialCard
            avatar={'https://media.getchefnow.com/images/Vincent-edited.jpg'}
            name="Vincent"
            location="Toronto, CA">
            "It was a delightful and interesting experience. Me and my friends
            were able to watch the chef cook. The shrimp pasta was delicious. I
            recommend GetChefNow with friends or family. I will definitely do it
            again."
          </TestimonialCard>
        </ul>
      </Content>
    </section>
  );
};
