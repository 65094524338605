import { differenceInDays, format } from 'date-fns';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { FC, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { UpdateChefDTO, UserDTO, UsersApi } from '../../api';
import { Configuration } from '../../config';
import { useIsDesktop, usePhoneUtil, usePhotos } from '../../hooks';
import { Address, Chef, Cuisine, User } from '../../model';
import {
  Alert,
  Button,
  Checkbox,
  Heading,
  Input,
  StripeWrapper,
  Textarea,
  UploadImage,
} from '../atoms';
import {
  ChefBillingInformationForm,
  DateField,
  LocationField,
  PhoneField,
} from '../molecules';

interface FormFields extends Address {
  birthDate: Date | null;
  country_iso_code: string;
  cuisines: string[];
  description: string;
  email: string;
  firstName: string;
  lastName: string;
  location?: google.maps.places.AutocompletePrediction;
  phone: string;
  photo: File | null;
}

interface Props {
  chef: Chef;
  cuisines: Cuisine[];
  profile: User;
}

export const ChefSettingsForm: FC<Props> = ({ chef, cuisines, profile }) => {
  const { add } = usePhotos();
  const isDesktop = useIsDesktop();
  const phoneUtil = usePhoneUtil();
  const queryClient = useQueryClient();
  const phoneNumber = useMemo(
    () =>
      profile.phone
        ? phoneUtil.parse(
            profile.phone,
            profile.country_iso_code ?? Configuration.defaultCountryISOCode,
          )
        : undefined,
    [phoneUtil, profile.country_iso_code, profile.phone],
  );
  const form = useForm<FormFields>({
    defaultValues: {
      address: profile.address,
      birthDate: profile.date_of_birth,
      city: profile.city,
      country: profile.country,
      cuisines: cuisines.map(cuisine =>
        chef.cuisines.includes(cuisine.name) ? cuisine.name : undefined,
      ),
      description: chef.about,
      location_id: profile.location_id ?? undefined,
      photo: profile.photo,
      state: profile.state,
      zipcode: profile.zipcode,
      country_iso_code:
        profile.country_iso_code ?? Configuration.defaultCountryISOCode,
      email: profile.email,
      firstName: profile.first_name,
      lastName: profile.last_name,
      phone: phoneNumber
        ? phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL)
        : undefined,
    },
    shouldUseNativeValidation: true,
  });
  const { mutate: updateChef } = useMutation<Chef, Error, UpdateChefDTO>(
    UsersApi.updateChef.bind(undefined, profile.id),
    {
      onSuccess() {
        queryClient.refetchQueries(['users', profile.id]);
      },
    },
  );
  const {
    isLoading,
    isSuccess,
    mutate: updateUser,
    error,
    isError,
  } = useMutation<User, Error, UserDTO>(
    UsersApi.update.bind(undefined, profile.id),
    {
      onSuccess() {
        queryClient.refetchQueries('me');
      },
    },
  );

  const onSubmit = useCallback(
    async (data: FormFields) => {
      if (data.photo && data.photo.name !== profile.photoURL) {
        await add('primary', data.photo);
      }

      const parsedPhone = data.phone
        ? phoneUtil.parse(
            data.phone,
            data.country_iso_code ?? Configuration.defaultCountryISOCode,
          )
        : undefined;

      await updateUser({
        address: data.address,
        city: data.city,
        country_iso_code: data.country_iso_code,
        country: data.country,
        date_of_birth: data.birthDate
          ? format(data.birthDate, 'yyyy-MM-dd')
          : undefined,
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        location_id: data.location_id,
        phone: parsedPhone
          ? String(parsedPhone.getNationalNumber())
          : undefined,
        state: data.state,
        zipcode: data.zipcode,
      });

      await updateChef({
        about: data.description,
        cuisines: data.cuisines.filter(Boolean),
        dish_specialities: [],
      });
    },
    [add, phoneUtil, profile, updateChef, updateUser],
  );

  return (
    <FormProvider {...form}>
      {isError && !isLoading && <Alert theme="danger">{error.message}</Alert>}
      {isSuccess && !isLoading && (
        <Alert theme="success">
          Account settings have been saved successfully!
        </Alert>
      )}
      <Heading className="mb-[4px] text-navy-100" level={5}>
        Personal settings
      </Heading>
      <p className="mb-2.5 paragraph-s text-navy-70">
        Update your personal details here
      </p>
      <div className="p-2.5 border border-navy-20 bg-white rounded-md">
        <form className="grid gap-2.5" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid md:grid-cols-2 gap-2.5">
            <Input required name="firstName" label="First name" />
            <Input required name="lastName" label="Last name" />
          </div>
          <Input required name="email" label="Email" type="email" />
          <DateField
            required
            filterDate={date => differenceInDays(date, new Date()) < 0}
            label="Birth date"
            name="birthDate"
          />
          <UploadImage isPreviewVisible label="Profile picture" name="photo" />
          <UploadImage label="Action shot" name="actionPhoto" />
          <LocationField required label="Location*" />
          <Textarea
            required
            label="Short description"
            name="description"
            placeholder="Describe your professional experience"
            rows={3}
          />
          <div>
            <span className="input-label">Cuisines*</span>
            <div className="grid grid-cols-2 gap-1.5">
              {cuisines.map((cuisine, index) => (
                <Checkbox
                  key={cuisine.name}
                  name={`cuisines[${index}]`}
                  value={cuisine.name}>
                  {cuisine.name}
                </Checkbox>
              ))}
            </div>
          </div>
          <footer className="md:flex md:justify-end">
            <Button block={!isDesktop} theme="primary" type="submit">
              Save changes
            </Button>
          </footer>
        </form>
      </div>

      <Heading className="mt-4 md:mt-6 mb-[4px] text-navy-100" level={5}>
        Payment setup
      </Heading>
      <p className="mb-2.5 paragraph-s text-navy-70">
        Update your payment settings to get paid here
      </p>
      <div className="p-2.5 border border-navy-20 bg-white rounded-md">
        <StripeWrapper>
          <ChefBillingInformationForm mode="edit" />
        </StripeWrapper>
      </div>

      <Heading className="mt-4 md:mt-6 mb-[4px] text-navy-100" level={5}>
        Shipping & payment
      </Heading>
      <p className="mb-2.5 paragraph-s text-navy-70">
        Update your shipping & payment settings here
      </p>
      <div className="p-2.5 border border-navy-20 bg-white rounded-md">
        <form className="grid gap-2.5" onSubmit={form.handleSubmit(onSubmit)}>
          <LocationField />
          <PhoneField />
          <footer className="md:flex md:justify-end">
            <Button block={!isDesktop} theme="primary" type="submit">
              Save changes
            </Button>
          </footer>
        </form>
      </div>
    </FormProvider>
  );
};
