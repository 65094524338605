import { FC } from 'react';
import { Heading, StripeWrapper } from '../atoms';
import { ChefBillingInformationForm } from './ChefBillingInformationForm';

export const ChefPaymentStep: FC = () => {
  return (
    <div>
      <Heading
        className="mb-0.5 md:mb-[12px] text-navy-100 text-center"
        level={5}>
        Payment setup
      </Heading>
      <h6 className="mb-3.5 paragaph-s text-navy-60 text-center">
        Fill in your bank or Interact details to get paid.
      </h6>
      <StripeWrapper>
        <ChefBillingInformationForm />
      </StripeWrapper>
    </div>
  );
};
