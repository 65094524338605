import { Close, HamburgerButton } from '@icon-park/react';
import { FC, Fragment, useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Content,
  Logo,
  ReferralBonus,
  ShoppingCartIcon,
} from '../components';
import { RoutePath } from '../config';
import { Down } from '@icon-park/react';
import { ReactComponent as UserIcon } from '../assets/icons/user.svg';
import { useAppDispatch, useAppSelector, useProfile } from '../hooks';
import { selectIsAuthorized } from '../redux';
import { authSlice } from '../redux/auth.slice';
import styles from './Header.module.css';
import { Menu, Transition } from '@headlessui/react';

export const Header: FC = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuthorized, isCustomer, profile } = useProfile();
  const [openMenu, setOpenMenu] = useState(false);

  const toggle = useCallback(() => setOpenMenu(current => !current), []);
  const goToLoginPage = useCallback(
    () => navigate(RoutePath.Login),
    [navigate],
  );
  const logOut = useCallback(() => {
    dispatch(authSlice.actions.logOut());
    queryClient.removeQueries();
  }, [dispatch, queryClient]);
  const goToRegisterPage = useCallback(
    () => navigate(RoutePath.Register),
    [navigate],
  );
  const goToShoppingCartPage = useCallback(
    () => navigate(RoutePath.ShoppingCart),
    [navigate],
  );

  const isShoppingCartVisible = !isAuthorized || isCustomer;

  return (
    <header className={styles.container}>
      <Content className="py-1.5 flex items-center justify-between">
        <div className="flex-1 flex gap-5 justify-between lg:justify-start items-center">
          {openMenu ? (
            <Close
              className="text-blue-100 lg:hidden"
              onClick={toggle}
              size={24}
              theme="outline"
            />
          ) : (
            <HamburgerButton
              className="text-blue-100 lg:hidden"
              onClick={toggle}
              size={24}
              theme="outline"
            />
          )}
          <Logo />
          {isShoppingCartVisible && (
            <button
              className="lg:hidden"
              onClick={goToShoppingCartPage}
              title="Shopping cart">
              <ShoppingCartIcon />
            </button>
          )}
          <nav className="hidden lg:flex lg:gap-4">
            <Navigation />
          </nav>
        </div>
        <div className="hidden lg:flex lg:gap-2">
          {isShoppingCartVisible && (
            <Button
              icon
              onClick={goToShoppingCartPage}
              theme="secondary"
              title="Shopping cart">
              <ShoppingCartIcon />
            </Button>
          )}
          {isAuthorized && (
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="flex items-center gap-1">
                  {profile?.photo ? (
                    <img
                      alt="Avatar"
                      className="flex-[0_0_44px] h-[44px] rounded-md"
                      src={URL.createObjectURL(profile.photo)}
                    />
                  ) : (
                    <span className="p-1 rounded-md bg-blue-10 text-blue-100 hover:bg-blue-20 hover:text-blue-110">
                      <UserIcon />
                    </span>
                  )}
                  <Down className="text-blue-100" size={20} />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 mt-2 w-[178px] origin-top-right rounded-md bg-white text-navy-100 shadow-menu">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        className="block px-2 py-[12px] paragraph-s font-semibold text-left text-blue-100 hover:bg-blue-5 rounded-t-md"
                        to={RoutePath.Referral}>
                        Invite and earn <ReferralBonus />
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        className="block px-2 py-[12px] paragraph-s font-semibold text-left hover:bg-blue-5"
                        to={RoutePath.OrderHistory}>
                        Order history
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        className="block px-2 py-[12px] paragraph-s font-semibold text-left hover:bg-blue-5"
                        to={RoutePath.AccountSettings}>
                        Account settings
                      </Link>
                    )}
                  </Menu.Item>
                  {/* <Menu.Item>
                    {({ active }) => (
                      <Link
                        className="block px-2 py-[12px] paragraph-s font-semibold text-left hover:bg-blue-5"
                        to={RoutePath.CreateFood}>
                        Add a new menu item
                      </Link>
                    )}
                  </Menu.Item> */}
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className="block w-full px-2 py-[12px] paragraph-s font-semibold text-left hover:bg-blue-5 rounded-b-md"
                        onClick={logOut}>
                        Sign out
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          )}
          {!isAuthorized && (
            <>
              <Button onClick={goToLoginPage} theme="secondary">
                Login
              </Button>
              <Button onClick={goToRegisterPage}>Sign up</Button>
            </>
          )}
        </div>
      </Content>
      {openMenu && (
        <div className={styles.dropdown} onClick={toggle}>
          <nav className="mb-2.5 grid gap-2.5">
            <Navigation isMobile />
          </nav>
          <Button
            block
            className="mb-1"
            onClick={goToShoppingCartPage}
            theme="secondary">
            View cart
            <ShoppingCartIcon />
          </Button>
          {isAuthorized ? (
            <Button block onClick={logOut}>
              Sign out
            </Button>
          ) : (
            <>
              <Button
                block
                className="mb-1"
                onClick={goToLoginPage}
                theme="secondary">
                Login
              </Button>
              <Button block onClick={goToRegisterPage}>
                Sign up
              </Button>
            </>
          )}
        </div>
      )}
    </header>
  );
};

const Navigation: FC<{ isMobile?: boolean }> = ({ isMobile = false }) => {
  const isAuthorized = useAppSelector(selectIsAuthorized);

  return (
    <>
      <Link
        className="paragraph-s font-semibold text-navy-100"
        to={RoutePath.Cuisines}>
        Menu
      </Link>
      <Link
        className="paragraph-s font-semibold text-navy-100"
        to={RoutePath.Pricing}>
        Pricing
      </Link>
      <Link
        className="paragraph-s font-semibold text-navy-100"
        to={RoutePath.FAQ}>
        FAQ
      </Link>
      <Link
        className="paragraph-s font-semibold text-navy-100"
        to={RoutePath.Covid19}>
        COVID-19
      </Link>
      <Link
        className="paragraph-s font-semibold text-navy-100"
        to={RoutePath.Apps}>
        Download our app
      </Link>
      {isAuthorized && isMobile && (
        <>
          <hr className="border-navy-10" />
          <Link
            className="paragraph-s font-semibold text-blue-100"
            to={RoutePath.Referral}>
            Invite and earn <ReferralBonus />
          </Link>
          <Link
            className="paragraph-s font-semibold text-navy-100"
            to={RoutePath.OrderHistory}>
            Order history
          </Link>
          <Link
            className="paragraph-s font-semibold text-navy-100"
            to={RoutePath.AccountSettings}>
            Account settings
          </Link>
          {/* <Link
            className="paragraph-s font-semibold text-navy-100"
            to={RoutePath.CreateFood}>
            Add a new menu item
          </Link> */}
        </>
      )}
    </>
  );
};
