import type { FC } from 'react';
import { ReactComponent as AppStoreIcon } from '../../assets/icons/app-store.svg';

export const DownloadOnAppStoreButton: FC = () => {
  return (
    <a
      href="https://apps.apple.com/us/app/getchefnow/id1547558170"
      target="_blank"
      rel="noreferrer">
      <AppStoreIcon className="w-[120px] h-[40px] md:w-[144px] md:h-[48px]" />
    </a>
  );
};
