import type { FC, PropsWithChildren } from 'react';
import { Card, Content, Heading, Paragraph, Tag } from '../atoms';

export const HomeChefsSection: FC = () => {
  return (
    <section className="py-8 md:py-12">
      <Content>
        <Heading className="mb-6 text-navy-100 md:mb-8" level={2}>
          Featured chefs
        </Heading>
        <ul className="grid gap-4 md:grid-cols-3 md:gap-3">
          <ChefCard
            location="Toronto ON"
            name="Andrew Siebert"
            photo={require('../../assets/images/Andrew-Siebert.jpg')}
            tags={['Italian', 'French', 'Mexican', 'Asian']}>
            Chef Andrew Siebert hails from Vancouver and has always had a passion for delicious food. 
            Years of traveling while cooking found Andrew now as the private chef for a billionaire on his yacht.
          </ChefCard>

          <ChefCard
            location="Toronto ON"
            name="Kevin Leggat"
            photo={require('../../assets/images/kevin.png')}
            tags={['American', 'Italian', 'French', 'Asian']}>
            Chef Kevin Leggat is a sous chef in the kitchens of Hilton in
            Toronto. He has had a rich and extensive experience.
          </ChefCard>

          <ChefCard
            location="Toronto ON"
            name="Gio Tuazon"
            photo={require('../../assets/images/gio.jpeg')}
            tags={['Mexican', 'Italian', 'French', 'Indian']}>
            Chef Gio Tuazon is currently a chef at Scotiabank Arena for the
            Raptors and was a former banquet assistant Chef at Ted Roger’s
            School of Management at Ryerson University.
          </ChefCard>

        </ul>
      </Content>
    </section>
  );
};

interface ChefCardProps {
  location: string;
  name: string;
  photo: string;
  tags: string[];
}

const ChefCard: FC<PropsWithChildren<ChefCardProps>> = ({
  children,
  location,
  name,
  photo,
  tags,
}) => {
  return (
    <Card
      preview={
        <img
          alt={name}
          className="block rounded-t-lg object-cover h-[228px]"
          src={photo}
        />
      }>
      <span className="block text-navy-50">{location}</span>
      <Heading className="mb-0.5 text-navy-100" level={5}>
        {name}
      </Heading>
      <div className="flex gap-0.5 flex-wrap">
        {tags.map(tag => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </div>
      <Paragraph className="flex-1 mt-1.5 text-navy-70" size="S">
        {children}
      </Paragraph>
    </Card>
  );
};
