import classNames from 'classnames';
import type { FC, HTMLAttributes, PropsWithChildren } from 'react';
import styles from './Tag.module.css';

export const Tag: FC<PropsWithChildren<HTMLAttributes<HTMLSpanElement>>> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <span className={classNames(styles.container, className)} {...rest}>
      {children}
    </span>
  );
};
