import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';
import { Heading } from '../atoms';

interface Props {
  actionText?: string;
  active?: boolean;
  className?: string;
  gap?: number;
  interactive?: boolean;
  onClick?(): void;
  title: string;
}

export const Widget: FC<PropsWithChildren<Props>> = ({
  actionText = 'Change',
  active = true,
  className,
  children,
  gap = 2.5,
  interactive,
  onClick,
  title,
}) => {
  return (
    <div className={classNames('p-2 rounded-lg shadow-card bg-white', className)} data-testid="widget">
      <header
        className={classNames('flex justify-between', {
          [`mb-${gap}`]: active,
        })}>
        <Heading
          className={classNames({
            'text-navy-100': active,
            'text-navy-60': !active,
          })}
          level={5}>
          {title}
        </Heading>
        {interactive && (
          <button
            className="paragraph-s font-semibold text-blue-100"
            onClick={onClick}
            type="button">
            {actionText}
          </button>
        )}
      </header>
      {active && children}
    </div>
  );
};
