import { useStripe } from '@stripe/react-stripe-js';
import { CreateTokenBankAccountData, StripeError } from '@stripe/stripe-js';
import { FC, useCallback } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { BillingApi } from '../../api';
import { RoutePath } from '../../config';
import { ChefOnboardingFormFields } from '../../hooks';
import { Alert, Button, Input, Radio } from '../atoms';

interface Props {
  mode?: 'register' | 'edit';
}

interface FormFields {
  address: string;
  bankAccountNumber: string;
  bankTransitNumber: string;
  email: string;
  institutionNumber: string;
  name: string;
  type: 'eTransfer' | 'directDeposit';
}

export const ChefBillingInformationForm: FC<Props> = ({
  mode = 'register',
}) => {
  const navigate = useNavigate();
  const {
    error,
    isError,
    isLoading,
    mutate: addChefToken,
  } = useMutation<void, StripeError, FormFields>(
    async (data: FormFields) => {
      if (stripe) {
        const bankAccountParams: CreateTokenBankAccountData = {
          country: countryIsoCode === 'USA' ? 'US' : 'CA',
          currency: 'CAD',
          routing_number:
            countryIsoCode === 'CAN'
              ? `${data.bankTransitNumber}-${data.institutionNumber}`
              : data.bankTransitNumber,
          account_number: data.bankAccountNumber,
          account_holder_name: data.name,
          account_holder_type: 'individual',
        };
        const { error, token } = await stripe.createToken(
          'bank_account',
          bankAccountParams,
        );

        if (error) {
          throw error;
        }

        if (token) {
          BillingApi.addChefToken(token.id);
        }
      }
    },
    {
      onSuccess() {
        if (mode === 'register') {
          navigate(RoutePath.Cuisines);
        }
      },
    },
  );
  const chefOnboardingForm = useFormContext<ChefOnboardingFormFields>();
  const stripe = useStripe();
  const form = useForm<FormFields>({
    defaultValues: {
      type: 'eTransfer',
    },
  });
  const countryIsoCode = chefOnboardingForm.watch('country_iso_code');
  const type = form.watch('type');

  const onSubmit = useCallback(
    async (data: FormFields) => {
      if (data.type === 'eTransfer') {
        navigate(RoutePath.Cuisines);
        return;
      }

      addChefToken(data);
    },
    [addChefToken, navigate],
  );

  return (
    <>
      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          {isError && !isLoading && (
            <Alert theme="danger">{error?.message}</Alert>
          )}
          <div className="grid gap-2.5">
            <div>
              <span className="input-label">Payment method</span>
              <div className="grid grid-cols-2">
                <Radio name="type" value="eTransfer">
                  e-Transfer
                </Radio>
                <Radio name="type" value="directDeposit">
                  Direct Deposit
                </Radio>
              </div>
            </div>
            {type === 'directDeposit' && (
              <>
                <Input
                  required
                  label="Name as spelled on the account"
                  name="name"
                  placeholder="ex: John Doe"
                />
                <Input
                  required
                  label="Institution number"
                  name="institutionNumber"
                  placeholder="Your institution number"
                />
                <Input
                  required
                  label="Bank transit number"
                  name="bankTransitNumber"
                  placeholder="Your bank transit number"
                />
                <Input
                  required
                  label="Bank account number"
                  name="bankAccountNumber"
                  placeholder="Your bank account number"
                />
              </>
            )}
            {mode === 'register' ? (
              <Button block type="submit">
                Continue
              </Button>
            ) : (
              <footer className="md:flex md:justify-end">
                <Button theme="primary" type="submit">
                  Save changes
                </Button>
              </footer>
            )}
          </div>
        </form>
      </FormProvider>
    </>
  );
};
