import type { FC } from 'react';
import { Accordion, Content, Heading, Paragraph } from '../atoms';

export const HomeFAQSection: FC = () => {
  return (
    <section className="py-8 md:py-12" id="faq">
      <Content className="grid gap-4 md:grid-cols-[1fr_2fr]">
        <Heading className="text-navy-100" level={2}>
          Frequently asked
          <br />
          questions
        </Heading>
        <div>
          <hr className="mb-2 border-navy-20 opacity-30" />
          <Accordion defaultOpen header="What services will the chef provide?">
            The chef will prep, cook, plate, clean the utensils used to cook,
            and help store away any extra portions. Customers can book a chef
            for a quick, hassle-free 30-minute meal for the family at any time;
            host a fancy cocktail party for 30 or more with an in-home, personal
            chef; or plan a fun, interactive cooking experience with a group
            friends and family.
          </Accordion>
          <hr className="my-2 border-navy-20 opacity-30" />
          <Accordion header="Who pays for the groceries?">
            The customer pays for the groceries. The grocery cost is added to
            the final bill.
          </Accordion>
          <hr className="my-2 border-navy-20 opacity-30" />
          <Accordion header="Can I ask the app to get the groceries?">
            Yes, Getchefnow takes care of all groceries that will be needed for
            your event.
          </Accordion>
          <hr className="my-2 border-navy-20 opacity-30" />
          <Paragraph semiBold className="text-navy-100" size="M">
            View all questions →
          </Paragraph>
        </div>
      </Content>
    </section>
  );
};
