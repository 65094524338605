import { useSearchParams } from 'react-router-dom';
import { Role } from '../model';

export function useRoleFromQueryString(): Role {
  const [searchParams] = useSearchParams();
  const role = searchParams.get('role');

  if (role === 'chef' || role === 'customer') {
    return role;
  }

  return 'customer';
}
