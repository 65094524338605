import type { FC } from 'react';
import { useAppSelector } from '../../hooks';
import { selectConfig } from '../../redux';

const moneyFormatter = new Intl.NumberFormat('en', {
  currency: 'usd',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  style: 'currency',
});

export const ReferralBonus: FC = () => {
  const config = useAppSelector(selectConfig);

  return <>{moneyFormatter.format(config.referralBonus)}</>;
};
