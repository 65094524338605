import classNames from 'classnames';
import { FC, InputHTMLAttributes, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;

  helper?: string;
  label?: string;
  mask?: string;
  suffix?: ReactNode;
  valueAsNumber?: boolean;
}

export const Input: FC<InputProps> = ({
  className,
  helper,
  label,
  mask,
  max,
  maxLength,
  min,
  minLength,
  name = '',
  required = false,
  suffix,
  type = 'text',
  valueAsNumber,
  ...rest
}) => {
  const { control, formState, getFieldState, register } = useFormContext();
  const { invalid } = getFieldState(name, formState);
  const inputClassName = classNames('input', {
    'input-invalid': invalid,
  });

  return (
    <label className={classNames('block', className)}>
      {Boolean(label) && (
        <span className="input-label">
          {label}
          {required && '*'}
        </span>
      )}
      <div className="flex items-center">
        {mask ? (
          <Controller
            control={control}
            name={name}
            rules={{
              maxLength,
              minLength,
              required,
            }}
            render={({ field, fieldState }) => {
              return (
                <InputMask
                  {...field}
                  className={inputClassName}
                  mask={mask}
                  maskPlaceholder={null}
                  placeholder={rest.placeholder}
                  required={required}
                  value={field.value ?? ''}
                />
              );
            }}
          />
        ) : (
          <input
            {...rest}
            {...register(name, {
              max,
              maxLength,
              min,
              minLength,
              required,
              valueAsNumber,
            })}
            className={inputClassName}
            required={required}
            type={type}
          />
        )}
        {Boolean(suffix) && (
          <div className="flex-center w-[46px] ml-[-46px]">{suffix}</div>
        )}
      </div>
      {Boolean(helper) && <span className="input-helper">{helper}</span>}
    </label>
  );
};
