import { FC, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Button, Card, Content, Heading, Paragraph } from '../components';
import { RoutePath } from '../config';
import { useCuisines, useIsDesktop } from '../hooks';
import { Layout } from '../layout';

const VISIBLE_CUISINES_BY_DEFAULT = 4;

export const CuisinesPage: FC = () => {
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const [expanded, setExpanded] = useState(false);
  let cuisines = useCuisines();
  cuisines = expanded
    ? cuisines
    : cuisines.slice(0, VISIBLE_CUISINES_BY_DEFAULT);

  return (
    <Layout inner>
      <Content className="py-4 md:py-6">
        <Heading className="mb-1 text-navy-100" level={4}>
          What are you looking to eat?
        </Heading>
        <Paragraph
          className="mb-3.5 md:mb-4 text-navy-70"
          size={isDesktop ? 'L' : 'M'}>
          Pick the cuisine you’d like your chef to cook or you can browse all to
          pick multiple dishes.
        </Paragraph>
        <ul className="grid md:grid-cols-2 lg:grid-cols-4 gap-3.5">
          {cuisines.map(cuisine => (
            <Card
              key={cuisine.name}
              preview={
                <img
                  alt={cuisine.name}
                  className="block rounded-t-lg object-cover h-[251px] md:h-[210px]"
                  src={cuisine.image}
                />
              }>
              <Paragraph className="mb-[4px] text-navy-100" size="M">
                {cuisine.name}
              </Paragraph>
              <span className="flex-1 block text-navy-70">
                {cuisine.description}
              </span>
              <Button
                block
                className="mt-2"
                onClick={() =>
                  navigate(
                    generatePath(RoutePath.CuisineMenu, {
                      id: cuisine.name.toLowerCase(),
                    }),
                  )
                }
                theme="secondary">
                Browse&nbsp;{cuisine.name}
              </Button>
            </Card>
          ))}
        </ul>
        {!expanded && (
          <div className="mt-3.5 px-2 py-1.5 grid md:grid-cols-2 md:items-center gap-2 border border-navy-10 rounded-md shadow-card bg-white">
            <div>
              <Paragraph semiBold className="mb-[4px] text-navy-100" size="M">
                Feeling more than one?
              </Paragraph>
              <span className="text-navy-70">
                If you’re planning something big, or still not sure
              </span>
            </div>
            <div className="md:text-right">
              <Button
                block={!isDesktop}
                onClick={() => setExpanded(true)}
                theme="secondary">
                Browse all cuisines
              </Button>
            </div>
          </div>
        )}
      </Content>
    </Layout>
  );
};
