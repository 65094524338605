import classNames from 'classnames';
import type { FC, HTMLAttributes, PropsWithChildren } from 'react';

export const Content: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        'px-2 md:px-3.5 md:mx-auto md:max-w-screen-xl',
        className,
      )}
      {...rest}>
      {children}
    </div>
  );
};
