import { FC, useMemo } from 'react';
import { Select, SelectOption } from '../atoms';

export const TimeSlotField: FC = () => {
  const options = useMemo<SelectOption[]>(() => {
    const result: SelectOption[] = [];

    for (let i = 9; i <= 12; i++) {
      result.push({
        label: `${i.toString()} a.m.`,
        value: i.toString(),
      });
    }

    for (let i = 1; i <= 9; i++) {
      result.push({
        label: `${i.toString()} p.m.`,
        value: (i + 12).toString(),
      });
    }

    return result;
  }, []);

  return (
    <Select
      required
      label="Time:"
      name="time"
      options={options}
      placeholder="Select an available timeslot"
    />
  );
};
