import classNames from 'classnames';
import type { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  block?: boolean;
  icon?: boolean;
  theme?: 'primary' | 'secondary' | 'outlined' | 'success' | 'danger';
}

export const Button: FC<PropsWithChildren<Props>> = ({
  block = false,
  children,
  className,
  icon = false,
  theme = 'primary',
  type = 'button',
  ...rest
}) => {
  return (
    <button
      className={classNames(
        'button',
        {
          'button--block': block,
          'button-icon': icon,
          'button--danger': theme === 'danger',
          'button--outlined': theme === 'outlined',
          'button--primary': theme === 'primary',
          'button--secondary': theme === 'secondary',
          'button--success': theme === 'success',
        },
        className,
      )}
      type={type}
      {...rest}>
      {children}
    </button>
  );
};
