import { Card, PaymentIntent } from '@stripe/stripe-js';
import { HttpClient } from './HttpClient';

export class BillingApi {
  static async addPaymentIntent(intentId: string): Promise<void> {
    await HttpClient.getInstance().put(`/v2/billing/intent/${intentId}/`);
  }

  static async createPaymentIntent(): Promise<PaymentIntent> {
    const { data } = await HttpClient.getInstance().post<PaymentIntent>(
      '/v2/billing/intents/',
    );

    return data;
  }

  static async fetchPaymentMethods(): Promise<
    Array<Card & { is_active: boolean }>
  > {
    const { data } = await HttpClient.getInstance().get(
      '/v2/billing/payment-methods/',
    );

    return data;
  }

  static async addChefToken(token: string): Promise<void> {
    await HttpClient.getInstance().post('/v1/billing/stripe-chefs/', {
      bank_token: token,
    });
  }
}
