import { setHours } from 'date-fns';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import { MqlApi } from './../api/Mql.api';
import { Address } from './../model/User';

export interface MqlFormFields extends Address {
  date?: Date;
  location?: google.maps.places.AutocompletePrediction;
  occasion?: string;
  step: number;
  time?: string;
}

export function useMqlStep(step: number, fields: Array<keyof MqlFormFields>) {
  const { mutate } = useMutation(MqlApi.update, {
    onSuccess() {
      goToNextStep();
    },
  });
  const { setValue, getValues, trigger } = useFormContext<MqlFormFields>();

  const goToNextStep = useCallback(
    () => setValue('step', step + 1),
    [setValue, step],
  );

  const update = useCallback(async () => {
    if (!(await trigger(fields))) {
      return;
    }

    const [
      address,
      city,
      countryIsoCode,
      country,
      date,
      location,
      occasion,
      state,
      time,
      zipCode,
    ] = getValues([
      'address',
      'city',
      'country_iso_code',
      'country',
      'date',
      'location',
      'occasion',
      'state',
      'time',
      'zipcode',
    ]);

    const fullAddress: Address = {
      address,
      city,
      country_iso_code: countryIsoCode,
      country,
      location_id: String(location?.place_id),
      state,
      zipcode: zipCode,
    };

    const isFullAddressDefined = Boolean(
      address && city && country && state && zipCode,
    );

    mutate({
      full_address: isFullAddressDefined ? fullAddress : undefined,
      occasion,
      start_date_time: date && time ? setHours(date, Number(time)) : undefined,
    });
  }, [fields, getValues, mutate, trigger]);

  return {
    update,
  };
}
