import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ReactGA from 'react-ga4';

interface State {
  addressFilled: boolean;
  notes?: string;
  notesFilled: boolean;
  paymentMethodFilled: boolean;
}

const initialState: State = {
  addressFilled: false,
  notesFilled: false,
  paymentMethodFilled: false,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    fillAddress(state) {
      ReactGA.event({
        action: 'location_and_time',
        category: 'Checkout',
      });
      state.addressFilled = true;
    },
    fillPaymentMethod(state) {
      ReactGA.event({
        action: 'payment_info',
        category: 'Checkout',
      });
      state.paymentMethodFilled = true;
    },
    reset() {
      return initialState;
    },
    setNotes(state, { payload: { notes } }: PayloadAction<{ notes?: string }>) {
      ReactGA.event({
        action: 'notes_and_allergies',
        category: 'Checkout',
      });
      state.notes = notes;
      state.notesFilled = true;
    },
  },
});
