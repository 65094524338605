import type { FC, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './Radio.module.css';

interface Props {
  name: string;
  value: string;
}

export const Radio: FC<PropsWithChildren<Props>> = ({
  children,
  name,
  value,
}) => {
  const { register } = useFormContext();

  return (
    <label className="flex gap-0.5 cursor-pointer select-none">
      <input
        {...register(name)}
        className={styles.input}
        name={name}
        type="radio"
        value={value}
      />
      <span className={styles.checkbox} />
      <span className="text-lg text-navy-100">{children}</span>
    </label>
  );
};
