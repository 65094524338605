import { FC, useCallback, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Content,
  Heading,
  Paragraph,
  ShoppingCartItemCard,
  ShoppingCartSummary,
} from '../components';
import { ReferralWidget } from '../components/organisms/ReferralWidget';
import { RoutePath } from '../config';
import { useProfile, useShoppingCart } from '../hooks';
import { Layout } from '../layout';

export const ShoppingCartPage: FC = () => {
  const { profile } = useProfile();
  const navigate = useNavigate();
  const { cart_items: items } = useShoppingCart();

  const goToMenu = useCallback(() => navigate(RoutePath.Cuisines), [navigate]);

  useEffect(() => {
    ReactGA.event({
      action: 'begin_checkout_process',
      category: 'Checkout',
    });
  }, []);

  return (
    <Layout inner>
      <Content className="py-4 md:py-6">
        <Heading className="mb-3.5 text-navy-100" level={3}>
          Shopping cart
        </Heading>
        <div className="grid md:grid-cols-[2fr_1fr] md:items-start gap-3.5">
          {items.length > 0 ? (
            <ul className="grid gap-3.5">
              {items.map(item => (
                <ShoppingCartItemCard key={item.id} item={item} />
              ))}
            </ul>
          ) : (
            <div className="p-2 flex justify-center items-center min-h-[200px] rounded-lg shadow-card">
              <div className="grid gap-2">
                <Paragraph className="text-navy-70" size="M">
                  Nothing to show
                </Paragraph>
                <Button onClick={goToMenu}>Add an item</Button>
              </div>
            </div>
          )}
          <div className="grid gap-3.5">
            <ShoppingCartSummary />
            {profile && <ReferralWidget profile={profile} />}
          </div>
        </div>
      </Content>
    </Layout>
  );
};
