import { Left, Right } from '@icon-park/react';
import { FC, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { useFormContext } from 'react-hook-form';

interface Props {
  filterDate?: (date: Date) => boolean;
  label?: string;
  name: string;
  required?: boolean;
}

export const DateField: FC<Props> = ({
  filterDate,
  label,
  name,
  required = false,
}) => {
  const {
    formState: { errors },
    register,
    watch,
    setValue,
  } = useFormContext();
  const date = watch(name);
  register(name, {
    required,
  });

  const handleChange = useCallback(
    (newDate: Date | null): void => {
      if (newDate) {
        setValue(name, newDate, {
          shouldValidate: true,
        });
      }
    },
    [name, setValue],
  );

  return (
    <div className="relative">
      <span className="input-label">
        {label}:{required && '*'}
      </span>
      <DatePicker
        ariaInvalid={errors['date'] ? 'invalid' : undefined}
        dateFormat="dd/MM/yyyy"
        filterDate={filterDate}
        name="date"
        nextMonthButtonLabel={<Right className="text-navy-60" size={12} />}
        onChange={handleChange}
        placeholderText="DD/MM/YYYY"
        popperClassName="react-datepicker-right"
        previousMonthButtonLabel={<Left className="text-navy-60" size={12} />}
        selected={date}
      />
    </div>
  );
};
