import { FC, useCallback } from 'react';
import { useChefOnboardingFlow, usePhotos } from '../../hooks';
import { User } from '../../model';
import { Button, Heading, UploadImage } from '../atoms';

interface Props {
  profile: User;
}

export const ChefImagesStep: FC<Props> = ({ profile }) => {
  const { add } = usePhotos();
  const { form, goToNextStep } = useChefOnboardingFlow(profile, [
    'profilePhoto',
    'actionPhoto',
  ]);
  const [actionPhoto, profilePhoto] = form.watch([
    'actionPhoto',
    'profilePhoto',
  ]);

  const handleClick = useCallback(async () => {
    if (actionPhoto) {
      await add('secondary', actionPhoto);
    }

    if (profilePhoto) {
      await add('primary', profilePhoto);
    }

    goToNextStep();
  }, [actionPhoto, add, goToNextStep, profilePhoto]);

  return (
    <div>
      <Heading
        className="mb-0.5 md:mb-[12px] text-navy-100 text-center"
        level={5}>
        Just a few more steps
      </Heading>
      <h6 className="mb-3.5 paragaph-s text-navy-60 text-center">
        Add in a couple of pictures to allow the customers to see who their chef
        is.
      </h6>
      <div className="grid gap-2.5">
        <UploadImage
          required
          isPreviewVisible
          label="Profile shot"
          name="profilePhoto"
        />
        <UploadImage label="Action shot" name="actionPhoto" />
        <Button block onClick={handleClick}>
          Continue
        </Button>
      </div>
    </div>
  );
};
