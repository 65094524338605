import { Minus, Plus } from '@icon-park/react';
import { FC, useCallback } from 'react';

interface Props {
  onChange(newValue: number): void;
  value: number;
}

const STEP = 4;
const MIN = 4;
const MAX = 12;

export const Counter: FC<Props> = ({ onChange, value }) => {
  const add = useCallback(() => onChange(value + STEP), [onChange, value]);
  const subtract = useCallback(() => onChange(value - STEP), [onChange, value]);

  const isMin = value <= MIN;
  const isMax = value >= MAX;

  return (
    <div
      className="flex gap-1 justify-between px-[15px] py-[9px] border border-navy-20 rounded-md select-none"
      data-testid="counter">
      <button
        disabled={isMin}
        onClick={subtract}
        type="button"
        data-testid="minus">
        <Minus className={isMin ? 'text-navy-20' : 'text-navy-60'} size={14} />
      </button>
      <span className="paragraph-s text-navy-60">
        {value}&nbsp;{value === 1 ? 'portion' : 'portions'}
      </span>
      <button disabled={isMax} onClick={add} type="button" data-testid="plus">
        <Plus className="text-navy-60" size={14} />
      </button>
    </div>
  );
};
