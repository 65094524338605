import { Dialog, Transition } from '@headlessui/react';
import {
  forwardRef,
  Fragment,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { Close } from '@icon-park/react';

export interface ModalRef {
  close(): void;
  open(): void;
}

interface Props {
  defaultOpened?: boolean;
}

export const Modal = forwardRef<ModalRef, PropsWithChildren<Props>>(
  ({ children, defaultOpened = false }, ref) => {
    const [opened, setOpened] = useState(defaultOpened);

    const close = useCallback(() => setOpened(false), []);
    const open = useCallback(() => setOpened(true), []);

    useImperativeHandle(ref, () => ({
      close,
      open,
    }));

    return (
      <Transition appear show={opened} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={close}
          unmount={false}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-navy-100 bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-card transition-all">
                  <button
                    className="absolute top-[12px] right-[12px] z-10 flex justify-center items-center h-[36px] w-[36px] bg-white rounded-md hover:bg-navy-5 transition-all"
                    onClick={close}
                    type="button">
                    <Close className="text-navy-100" size={12} />
                  </button>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  },
);
