import { FC } from 'react';
import { Content, FoodForm, Heading } from '../components';
import { useCuisines } from '../hooks';
import { Layout } from '../layout';

export const CreateFoodPage: FC = () => {
  const cuisines = useCuisines();

  return (
    <Layout inner>
      <Content className="py-4 md:py-6">
        <div className="md:mx-auto md:max-w-[600px]">
          <Heading className="mb-[4px] text-navy-100" level={5}>
            Add a new menu item
          </Heading>
          <p className="mb-2.5 paragraph-s text-navy-70">
            Update your personal details here
          </p>
          {cuisines.length > 0 && <FoodForm cuisines={cuisines} />}
        </div>
      </Content>
    </Layout>
  );
};
