import type { FC } from 'react';
import { Content, Heading, Paragraph } from '../atoms';

export const HomeHowSection: FC = () => {
  return (
    <section className="py-8 md:py-12">
      <Content>
        <Heading className="mb-6 md:mb-8 text-navy-100" level={2}>
          How it works
        </Heading>
        <ol className="grid gap-6 md:grid-cols-3">
          <li>
            <img
              alt="Step one"
              className="mb-2.5 block rounded-lg"
              src={require('../../assets/images/step-one.png')}
            />
            <Heading className="mb-0.5 text-navy-100" level={5}>
              1.&nbsp;Place an order
            </Heading>
            <Paragraph className="text-navy-70" size="S">
              Book a chef from the comfort of your home. We handle everything
              from getting groceries to finding the perfect chef for you.
            </Paragraph>
          </li>
          <li>
            <img
              alt="Step two"
              className="mb-2.5 block rounded-lg"
              src={require('../../assets/images/step-two.png')}
            />
            <Heading className="mb-0.5 text-navy-100" level={5}>
              2.&nbsp;Chef arrives
            </Heading>
            <Paragraph className="text-navy-70" size="S">
              The chef will cook, plate, clean, and leave. (All chefs have clean
              backgrounds checks)
            </Paragraph>
          </li>
          <li>
            <img
              alt="Step three"
              className="mb-2.5 block rounded-lg"
              src={require('../../assets/images/step-three.png')}
            />
            <Heading className="mb-0.5 text-navy-100" level={5}>
              3.&nbsp;Bon Appétit!
            </Heading>
            <Paragraph className="text-navy-70" size="S">
              Enjoy your delicious meal without worrying about cooking or
              cleaning.
            </Paragraph>
          </li>
        </ol>
      </Content>
    </section>
  );
};
