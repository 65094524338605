import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export class HttpClient {
  static readonly #baseURL = process.env.REACT_APP_API_BASE_URL;
  static #instance: AxiosInstance;

  static getInstance(): AxiosInstance {
    if (!HttpClient.#instance) {
      HttpClient.#instance = axios.create(HttpClient.#baseConfig);
    }

    return HttpClient.#instance;
  }

  static setToken(token: string): void {
    HttpClient.#instance = axios.create({
      ...HttpClient.#baseConfig,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static clearToken(): void {
    HttpClient.#instance = axios.create(HttpClient.#baseConfig);
  }

  static get #baseConfig(): AxiosRequestConfig {
    return {
      baseURL: HttpClient.#baseURL,
      withCredentials: true,
    };
  }
}
