import { FC } from 'react';
import { useMqlStep } from '../../hooks';
import { Button, Heading, Paragraph, Select, SelectOption } from '../atoms';

const options: SelectOption[] = [
  {
    label: 'Birthday',
    value: 'birthday',
  },
  {
    label: 'Anniversary',
    value: 'anniversary',
  },
  {
    label: 'Graduation',
    value: 'graduation',
  },
  {
    label: 'Party',
    value: 'party',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const MqlOccasionStep: FC = () => {
  const { update } = useMqlStep(1, ['occasion']);

  return (
    <div>
      <Heading className="mb-0.5 md:mb-[12px] text-navy-100 text-center" level={5}>
        What’s the type of the occasion?
      </Heading>
      <Paragraph className="mb-3.5 text-navy-60 text-center" size="S">
        Select “Other” if yours isn’t on the list.
      </Paragraph>
      <Select
        required
        name="occasion"
        options={options}
        placeholder="Type of occasion"
      />
      <Button block className="mt-2.5" onClick={update}>
        Continue
      </Button>
    </div>
  );
};
