import { Shopping } from '@icon-park/react';
import classNames from 'classnames';
import type { FC } from 'react';
import { useShoppingCart } from '../../hooks';
import styles from './ShoppingCartIcon.module.css';

export const ShoppingCartIcon: FC = () => {
  const { cart_items: items } = useShoppingCart();

  return (
    <span className="relative">
      <Shopping className='text-blue-100' size={24} />
      <span
        className={classNames(
          styles.cart,
          items.length > 0 ? 'bg-green-100' : 'bg-navy-60',
        )}>
        {items.length}
      </span>
    </span>
  );
};
