import { FC } from 'react';
import { useQuery } from 'react-query';
import { ReferralsApi } from '../../api';
import { Heading } from '../atoms';

export const ReferralSentList: FC = () => {
  const { data, isLoading } = useQuery('referrals', ReferralsApi.getList);

  if (isLoading || data?.length === 0) {
    return null;
  }

  return (
    <div>
      <Heading className="mb-2 md:mb-3.5 text-navy-100" level={5}>
        Past invites
      </Heading>
      <table cellSpacing={0} className="nice-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data?.map(referral => (
            <tr key={referral.id}>
              <td className="text-navy-100">{referral.email}</td>
              <td className="text-blue-100 font-semibold">{referral.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
