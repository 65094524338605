import type { FC } from 'react';
import {
  Content,
  DownloadOnAppStoreButton,
  GetItOnGooglePlayButton,
  Heading,
} from '../atoms';

export const HomeCTA: FC = () => {
  return (
    <section className="py-8 md:py-12" id="apps">
      <Content>
        <div className="relative p-2.5 rounded-xl bg-blue-100 md:p-6 md:pr-[350px]">
          <img
            className="mt-[-160px] mx-auto md:hidden"
            alt="Call to action"
            src={require('../../assets/images/cta.png')}
          />
          <img
            className="hidden absolute bottom-[0px] right-[0px] w-[488px] md:block lg:right-[80px]"
            alt="Call to action"
            src={require('../../assets/images/cta-desktop.png')}
          />
          <Heading className="hidden mb-4 text-white md:block" level={2}>
            Download our app and
            <br />
            start saving your time!
          </Heading>
          <Heading className="mb-2.5 text-white md:hidden" level={4}>
            Download our app and
            <br />
            start saving your time!
          </Heading>
          <footer className="flex gap-2">
            <DownloadOnAppStoreButton />
            <GetItOnGooglePlayButton />
          </footer>
        </div>
      </Content>
    </section>
  );
};
