import { FoodDietary } from './../model/FoodDietary';
import { Cuisine, Food, FoodType } from '../model';
import { HttpClient } from './HttpClient';

interface CuisineMenuParams {
  cuisineId: string;
  foodDietary?: FoodDietary;
  foodType?: FoodType;
}

export class CuisinesApi {
  static async getAll(): Promise<Cuisine[]> {
    const { data } = await HttpClient.getInstance().get<{
      cuisines: Cuisine[];
    }>('/v1/foods/cuisines/');

    return data.cuisines;
  }

  static async getCuisineMenu({
    cuisineId,
    foodDietary,
    foodType,
  }: CuisineMenuParams): Promise<Food[]> {
    const { data } = await HttpClient.getInstance().get<{
      data: Food[];
    }>('/v2/foods/', {
      params: {
        cuisine: cuisineId,
        dairy_free: foodDietary === FoodDietary.DairyFree || undefined,
        food_type: foodType,
        meat: foodDietary === FoodDietary.Meat || undefined,
        seafood: foodDietary === FoodDietary.Seafood || undefined,
        vegan: foodDietary === FoodDietary.Vegan || undefined,
        vegetarian: foodDietary === FoodDietary.Vegetarian || undefined,
      },
    });

    return data.data;
  }
}
