import { FC, useMemo } from 'react';
import { Tag } from '../atoms';

interface Props {
  categories: Record<string, boolean>[];
}

export const FoodCategories: FC<Props> = ({ categories }) => {
  const categoriesArr = useMemo(() => {
    return categories.reduce<string[]>((acc, category) => {
      for (const [key, value] of Object.entries(category)) {
        if (value) {
          acc.push(key);
        }
      }

      return acc;
    }, []);
  }, [categories]);

  return (
    <div className="flex gap-0.5 flex-wrap">
      {categoriesArr.map(category => (
        <Tag className="capitalize" key={category}>
          {category}
        </Tag>
      ))}
    </div>
  );
};
