import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as FacebookIcon } from '../assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../assets/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from '../assets/icons/twitter.svg';
import { Heading, Paragraph, ReferralBonus } from '../components';
import { RoutePath } from '../config';
import styles from './Footer.module.css';

export const Footer: FC = () => {
  return (
    <footer className="p-4 md:py-6 bg-navy-100 text-white">
      <div className="mx-auto grid md:grid-cols-4 gap-6 md:max-w-screen-xl">
        <div>
          <Heading className="mb-1.5" level={5}>
            Getchefnow
          </Heading>
          <Paragraph size="S">
            Copyright 2022 © Getchefnow
            <br />
            All rights reserved
          </Paragraph>
        </div>
        <nav>
          <Link className={styles.link} to={RoutePath.Cuisines}>
            Explore menu
          </Link>
          <Link className={styles.link} to={RoutePath.Pricing}>
            Pricing
          </Link>
          <Link className={styles.link} to={RoutePath.FAQ}>
            FAQ
          </Link>
          <Link className={styles.link} to={RoutePath.Referral}>
            Invite and earn <ReferralBonus />
          </Link>
        </nav>
        <nav>
          <Link className={styles.link} to={RoutePath.Home}>
            About us
          </Link>
          <Link className={styles.link} to={RoutePath.Terms}>
            Terms&nbsp;&&nbsp;Conditions
          </Link>
          <Link className={styles.link} to={RoutePath.Privacy}>
            Privacy Policy
          </Link>
          <Link className={styles.link} to={RoutePath.RegisterChef}>
            Work with us
          </Link>
          <Link
            className={styles.link}
            target="_blank"
            to="//blog.getchefnow.com">
            Blog
          </Link>
        </nav>
        <div>
          <Heading className="mb-0.5" level={5}>
            Contact us
          </Heading>
          <a
            className="block paragraph-s mb-0.5"
            href="mailto:support@getchefnow.com">
            support@getchefnow.com
          </a>
          <div className="flex gap-1">
            <a
              href="https://www.facebook.com/getchefnow/"
              target="_blank"
              rel="noreferrer">
              <FacebookIcon />
            </a>
            <a
              href="https://www.instagram.com/getchefnow/"
              target="_blank"
              rel="noreferrer">
              <InstagramIcon />
            </a>
            <a
              href="https://twitter.com/getchefnow/"
              target="_blank"
              rel="noreferrer">
              <TwitterIcon />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
