import type { FC, PropsWithChildren } from 'react';
import { Heading } from './Heading';
import { Paragraph } from './Paragraph';

interface Props {
  avatar: string;
  location: string;
  name: string;
}

export const TestimonialCard: FC<PropsWithChildren<Props>> = ({
  avatar,
  children,
  name,
  location,
}) => {
  return (
    <li className='p-2 bg-white rounded-lg shadow-card md:flex md:gap-3 md:p-3'>
      <img
        alt={name}
        className='hidden w-8 h-8 rounded-lg md:block'
        src={avatar}
      />
      <div>
        <Paragraph className='mb-2 text-navy-70' size='S'>
          {children}
        </Paragraph>
        <footer className='flex gap-2 items-center'>
          <img
            alt='Jenny'
            className='w-6 h-6 rounded-lg md:hidden'
            src={avatar}
          />
          <div>
            <Heading level={5}>{name}</Heading>
            <span className='text-navy-50 font-semibold'>{location}</span>
          </div>
        </footer>
      </div>
    </li>
  );
};
