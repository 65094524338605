import { FC, PropsWithChildren, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

export const ScrollToTop: FC<PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send('pageview');
    let element: HTMLElement | null = null;

    if (location.hash) {
      const elementId = location.hash.replace('#', '');

      if (elementId === 'pricing') {
        ReactGA.event({
          action: 'view_pricing',
          category: 'Landing',
        });
      }

      if (elementId === 'faq') {
        ReactGA.event({
          action: 'view_faq',
          category: 'Landing',
        });
      }

      element = document.getElementById(elementId);
    }

    if (element) {
      element.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{children}</>;
};
