import { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ApiError, AuthApi } from '../../api';
import { RoutePath } from '../../config';
import { useRoleFromQueryString } from '../../hooks';
import { Alert, Button, Heading, Input, Paragraph } from '../atoms';

interface FormFields {
  email: string;
}

export const ForgotPasswordForm: FC = () => {
  const navigate = useNavigate();
  const role = useRoleFromQueryString();
  const [customError, setCustomError] = useState('');
  const form = useForm<FormFields>({
    defaultValues: {
      email: '',
    },
    shouldUseNativeValidation: true,
  });
  const { handleSubmit, getValues, setError } = form;
  const { mutate, isSuccess, reset } = useMutation(
    (email: string) => AuthApi.forgotPassword(email),
    {
      onError(error) {
        if (ApiError.is<FormFields>(error)) {
          if (error.hasNonFieldError()) {
            setCustomError(error.getNonFieldError());
          }

          for (const [key, value] of error.getFieldsErrors()) {
            setError(key, { type: 'custom', message: value.join(', ') });
          }
        }
      },
    },
  );

  const onSubmit = useCallback(
    (data: FormFields) => {
      mutate(data.email);
    },
    [mutate],
  );

  if (isSuccess) {
    return (
      <>
        <Heading className="mb-[12px] text-navy-100" level={3}>
          Check your email
        </Heading>
        <Paragraph className="mb-3.5 text-navy-60" size="S">
          If the email{' '}
          <span className="text-blue-100">{getValues('email')}</span> exists, we
          sent a temporary password to it
        </Paragraph>
        <Button
          block
          className="mb-1"
          onClick={() => navigate(`${RoutePath.Login}?role=${role}`)}
          type="submit">
          Go to Login
        </Button>
        <Button
          block
          className="mb-2.5"
          onClick={() => navigate(RoutePath.Home)}
          theme="outlined">
          Back to Homepage
        </Button>
        <Paragraph className="text-navy-100 text-center" size="S">
          Didn’t receive the email?{' '}
          <span
            className="text-blue-100 cursor-pointer"
            onClick={() => reset()}>
            Click to resend
          </span>
        </Paragraph>
      </>
    );
  }

  return (
    <FormProvider {...form}>
      <Alert>{customError}</Alert>
      <Heading className="mb-0.5 text-navy-100" level={3}>
        Forgot your password?
      </Heading>
      <Paragraph className="mb-3.5 text-navy-60" size="S">
        No worries, we’ll send you reset instructions.
      </Paragraph>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Input
          required
          autoComplete="username"
          className="mb-2"
          label="Email"
          name="email"
          placeholder="Enter your email"
          type="email"
        />
        <Button block className="mb-1" type="submit">
          Reset password
        </Button>
        <Button
          block
          onClick={() => navigate(`${RoutePath.Login}?role=${role}`)}
          theme="outlined">
          Back to Login
        </Button>
      </form>
    </FormProvider>
  );
};
