import { ShoppingCart, ShoppingCartItem } from '../model';
import { HttpClient } from './HttpClient';

export class ShoppingCartApi {
  static async add(
    items: Array<{
      food_service_pk: number;
      name: string;
      servings: number;
    }>,
  ): Promise<ShoppingCart> {
    const { data } = await HttpClient.getInstance().patch<ShoppingCart>(
      '/v1/cart/',
      {
        cart_items: items,
      },
    );

    return data;
  }

  static async setDate(date: Date): Promise<ShoppingCart> {
    const { data } = await HttpClient.getInstance().patch<ShoppingCart>(
      '/v1/cart/',
      {
        start_date_time: date,
      },
    );

    return data;
  }

  static async fetch(): Promise<ShoppingCart> {
    const { data } = await HttpClient.getInstance().get<ShoppingCart>(
      '/v1/cart/',
    );

    return data;
  }

  static async remove(item: ShoppingCartItem): Promise<ShoppingCart> {
    const { data } = await HttpClient.getInstance().delete<ShoppingCart>(
      `/v1/cart/item/${item.id}/`,
    );

    return data;
  }
}
