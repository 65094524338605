import classNames from 'classnames';
import type { FC, HTMLAttributes, PropsWithChildren } from 'react';
import styles from './Alert.module.css';

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  theme?: 'danger' | 'success';
}

export const Alert: FC<Props> = ({
  children,
  className,
  role = 'alert',
  theme = 'danger',
  ...rest
}) => {
  if (!children) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles.danger]: theme === 'danger',
          [styles.success]: theme === 'success',
        },
        className,
      )}
      role={role}
      {...rest}>
      {children}
    </div>
  );
};
