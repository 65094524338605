import { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { MqlApi } from '../../api';
import { Configuration, RoutePath } from '../../config';
import { MqlFormFields, useProfile } from '../../hooks';
import { Address } from '../../model';
import {
  Button,
  Content,
  DownloadOnAppStoreButton,
  GetItOnGooglePlayButton,
  Heading,
  OrganicShape,
  Paragraph,
} from '../atoms';
import { LocationField } from './LocationField';

export const HomeHeroSection: FC = () => {
  const { isAuthorized } = useProfile();
  const navigate = useNavigate();
  const { mutate } = useMutation(MqlApi.update, {
    onSuccess() {
      navigate(RoutePath.MQL);
    },
  });
  const form = useForm<MqlFormFields>({
    defaultValues: {
      country_iso_code: Configuration.defaultCountryISOCode,
      step: 3,
    },
  });

  const onSubmit = useCallback(
    (data: MqlFormFields) => {
      const fullAddress: Address = {
        address: data.address,
        city: data.city,
        country_iso_code: data.country_iso_code,
        country: data.country,
        location_id: String(data.location?.place_id),
        state: data.state,
        zipcode: data.zipcode,
      };

      mutate({
        full_address: fullAddress,
      });
    },
    [mutate],
  );

  return (
    <section className="py-4">
      <Content>
        <div className="grid gap-8 md:grid-cols-2 md:gap-6 md:items-center">
          <div>
            <Heading className="mb-2 text-navy-100" level={1}>
              Book a chef
              <br />
              for your event
            </Heading>
            <Paragraph semiBold className="text-navy-70" size="M">
              Book your own private chef and transform your living rooms,
              backyards and patios into your own private restaurants!
            </Paragraph>
            <footer className="mt-3 md:mt-4 flex gap-2.5">
              {isAuthorized ? (
                <>
                  <DownloadOnAppStoreButton />
                  <GetItOnGooglePlayButton />
                </>
              ) : (
                <FormProvider {...form}>
                  <form
                    className="w-full grid md:flex md:items-end gap-2.5"
                    onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="flex-1">
                      <LocationField
                        required
                        label="Where do you need your chef?"
                      />
                    </div>
                    <Button className="justify-center" type="submit">
                      Get a chef now
                    </Button>
                  </form>
                </FormProvider>
              )}
            </footer>
          </div>
          <div className="flex justify-center">
            <OrganicShape className="px-7 md:px-10">
              <img
                alt="Application"
                className="max-w-[189px] md:max-w-[257px]"
                src={require('../../assets/images/hero.png')}
              />
            </OrganicShape>
          </div>
        </div>
        <div className="mt-8 md:mt-10 md:flex md:gap-6 md:justify-center md:items-center">
          <div className="flex items-center justify-center flex-wrap gap-2.5 md:gap-4.5">
            <img
              alt="DMZ"
              className="opacity-30"
              src={require('../../assets/images/dmz.svg').default}
              width={88}
            />
            <img
              alt="Collision"
              className="opacity-30"
              src={require('../../assets/images/collision.svg').default}
              width={138}
            />
            <img
              alt="Sentry"
              className="opacity-30"
              src={require('../../assets/images/sentry.svg').default}
              width={125}
            />
          </div>
        </div>
      </Content>
    </section>
  );
};
