import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { FC, PropsWithChildren } from 'react';

interface Props {
  options?: StripeElementsOptions;
}

const stripePromise = loadStripe(
  String(process.env.REACT_APP_STRIPE_PUBLIC_KEY),
);

export const StripeWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  options,
}) => {
  return (
    <Elements options={options} stripe={stripePromise}>
      {children}
    </Elements>
  );
};
