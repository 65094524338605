import { useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { ReferralsApi } from '../api';

interface FormFields {
  email: string;
}

export function useReferralForm(onSuccess: () => void) {
  const form = useForm<FormFields>({
    defaultValues: {
      email: '',
    },
    shouldUseNativeValidation: true,
  });
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(ReferralsApi.sendInvite, {
    onSuccess() {
      queryClient.invalidateQueries('referrals');
      form.reset();
      ReactGA.event({
        action: 'referral',
        category: 'Referral',
      });
      onSuccess();
    },
  });

  const sendInvite = useCallback(
    async (data: FormFields) => {
      mutate(data.email);
    },
    [mutate],
  );

  return {
    form,
    isSuccess,
    onSubmit: form.handleSubmit(sendInvite),
  };
}
