import { FC, useCallback, useState } from 'react';
import { useShoppingCart } from '../../hooks';
import { Food } from '../../model';
import { Button, Counter } from '../atoms';
import styles from './AddFoodToCartWidget.module.css';

interface Props {
  food: Food;
}

export const AddFoodToCartWidget: FC<Props> = ({ food }) => {
  const [count, setCount] = useState(4);
  const { addFoodToCart, removeFoodFromCart, cart_items: items } = useShoppingCart();
  const inCart = items.some(item => Number(item.food_service_pk) === food.id);

  const addToCart = useCallback(
    () =>
      addFoodToCart({
        count,
        foodId: food.id,
        name: food.name,
      }),
    [addFoodToCart, count, food],
  );

  const removeFromCart = useCallback(() => {
    const itemToRemove = items.find(
      item => Number(item.food_service_pk) === food.id,
    );
    if (itemToRemove) {
      removeFoodFromCart(itemToRemove);
    }
  }, [food, items, removeFoodFromCart]);

  const handleChangeCounter = useCallback(
    (newCount: number) => {
      if (inCart) {
        addFoodToCart({
          count: newCount,
          foodId: food.id,
          name: food.name,
        });
      }

      setCount(newCount);
    },
    [addFoodToCart, food, inCart],
  );

  if (!inCart) {
    return (
      <div className="grid gap-1.5 md:grid-cols-2">
        <Counter onChange={handleChangeCounter} value={count} />
        <Button
          block
          onClick={addToCart}
          title={`Add ${food.name} to shopping cart`}>
          Add to cart
        </Button>
      </div>
    );
  }

  return (
    <div className="grid gap-1.5 md:grid-cols-2">
      <Counter onChange={handleChangeCounter} value={count} />
      <Button block className={styles.success} theme="success">
        In cart
      </Button>
      <button
        className={styles.remove}
        onClick={removeFromCart}
        title={`Remove ${food.name} from shopping cart`}
        type="button">
        <span className={styles.info}>In cart</span>
        <span className={styles.action}>Remove</span>
      </button>
    </div>
  );
};
