import classNames from 'classnames';
import type { FC, HTMLAttributes, PropsWithChildren } from 'react';
import styles from './Paragraph.module.css';

interface Props extends HTMLAttributes<HTMLParagraphElement> {
  semiBold?: boolean;
  size: 'S' | 'M' | 'L';
}

export const Paragraph: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  semiBold = false,
  size,
  ...rest
}) => {
  return (
    <p
      className={classNames(
        styles.container,
        {
          [styles.bold]: semiBold,
          [styles.l]: size === 'L',
          [styles.m]: size === 'M',
          [styles.s]: size === 'S',
        },
        className,
      )}
      {...rest}>
      {children}
    </p>
  );
};
