import { Menu, Transition } from '@headlessui/react';
import { FC, Fragment } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ReactComponent as CopyIcon } from '../../assets/icons/affiliate/copy.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/affiliate/download.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/affiliate/email.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/affiliate/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/affiliate/twitter.svg';
import { ReactComponent as WhatsAppIcon } from '../../assets/icons/affiliate/whatsapp.svg';
import { useAppSelector, useCopyToClipboard, useModal } from '../../hooks';
import { User } from '../../model';
import { selectConfig } from '../../redux';
import { Input } from '../atoms';
import { Modal } from './Modal';
import { ReferralEmailForm } from './ReferralEmailForm';
import styles from './ReferralForm.module.css';

interface Props {
  block?: boolean;
  profile: User;
}

export const ReferralForm: FC<Props> = ({ block = false, profile }) => {
  const { modalRef, openModal } = useModal();
  const config = useAppSelector(selectConfig);
  const form = useForm<{
    link: string;
  }>({
    defaultValues: {
      link: profile.referral_link,
    },
  });
  const [, copy] = useCopyToClipboard();

  const messageToShare = `Hey, I use Getchefnow to book private chefs. Really easy and takes celebrating events to the next level! Give it a try with $${
    config.referralBonus
  } off with my link - ${encodeURI(profile.referral_link)}`;

  return (
    <>
      <Modal ref={modalRef}>
        <ReferralEmailForm onSuccess={() => modalRef.current?.close()} />
      </Modal>
      <FormProvider {...form}>
        <div className={!block ? 'md:flex gap-2' : ''}>
          <Input
            readOnly
            className="flex-1"
            label="Share your invite link"
            name="link"
          />
          <Menu as="div" className="relative">
            <span className="input-label">&nbsp;</span>
            <Menu.Button className="button button--block button--primary">
              Share&nbsp;&&nbsp;Earn
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="absolute right-0 mt-0.5 w-full min-w-[240px] origin-top-right rounded-md bg-white text-navy-100 shadow-menu">
                <Menu.Item>
                  <a
                    className={styles.item}
                    href={`https://www.facebook.com/sharer/sharer.php?u=${messageToShare}}`}
                    rel="noopener noreferrer"
                    target="_blank">
                    <FacebookIcon />
                    Post on Facebook
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    className={styles.item}
                    href={`https://twitter.com/intent/tweet?text=${messageToShare}`}
                    rel="noopener noreferrer"
                    target="_blank">
                    <TwitterIcon />
                    Tweet about it
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    className="flex items-center w-full gap-[14px] py-[14px] px-[22px] paragraph-s font-semibold hover:bg-blue-5 cursor-pointer"
                    data-action="share/whatsapp/share"
                    href={`whatsapp://send?text=${messageToShare}`}>
                    <WhatsAppIcon />
                    Share via WhatsApp
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className={styles.item}
                    onClick={openModal}
                    type="button">
                    <EmailIcon />
                    Share via Email
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className={styles.item}
                    onClick={() => copy(profile.referral_link)}
                    type="button">
                    <CopyIcon />
                    Copy link
                  </button>
                </Menu.Item>
                {profile.qr_code && (
                  <Menu.Item>
                    <a download className={styles.item} href={profile.qr_code}>
                      <DownloadIcon />
                      Download QR code
                    </a>
                  </Menu.Item>
                )}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </FormProvider>
    </>
  );
};
