import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { RoutePath } from '../../config';
import styles from './Logo.module.css';

export const Logo: FC = () => {
  return (
    <Link className={styles.container} to={RoutePath.Home}>
      <span className={styles.icon}>
        <LogoIcon />
      </span>
      <span>Getchefnow</span>
    </Link>
  );
};
