import type { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useReferralForm } from '../../hooks';
import { Button, Input } from '../atoms';

interface Props {
  onSuccess(): void;
}

export const ReferralEmailForm: FC<Props> = ({ onSuccess }) => {
  const { form, onSubmit } = useReferralForm(onSuccess);

  return (
    <FormProvider {...form}>
      <form className="p-2 bg-white rounded-lg" onSubmit={onSubmit}>
        <Input
          required
          className="mb-2.5"
          label="Your friend’s email"
          name="email"
          placeholder="Enter your friend’s email"
          type="email"
        />
        <Button block type="submit">
          Send invite
        </Button>
      </form>
    </FormProvider>
  );
};
