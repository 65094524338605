import { useCallback, useState, useMemo } from 'react';

export interface PaginationProps {
  page: number;
  pages: number[];
  perPage: number;

  back(): void;
  forward(): void;
  setPage(page: number): void;
}

export function usePagination(
  totalItems: number,
  itemsPerPage = 6,
): PaginationProps {
  const [page, setPage] = useState(0);
  const totalPages = useMemo(
    () =>
      totalItems < itemsPerPage ? 1 : Math.ceil(totalItems / itemsPerPage),
    [itemsPerPage, totalItems],
  );
  const pages = useMemo(
    () => Array.from(Array(totalPages).keys(), item => item + 1),
    [totalPages],
  );

  const back = useCallback(() => setPage(current => current - 1), [setPage]);
  const forward = useCallback(() => setPage(current => current + 1), [setPage]);

  return {
    page,
    pages,
    perPage: itemsPerPage,

    back,
    forward,
    setPage,
  };
}
