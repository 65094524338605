import { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { OrdersApi } from '../api';
import { Button, Content, Heading, Money, Pagination } from '../components';
import { useOrder, usePagination } from '../hooks';
import { Layout } from '../layout';
import { Order } from '../model';

export const OrderHistoryPage: FC = () => {
  const { data: orders } = useQuery('orders', OrdersApi.getAll);

  const pagination = usePagination(orders?.length ?? 0);
  const start = pagination.page * pagination.perPage;
  const end = start + pagination.perPage;

  useEffect(() => {
    ReactGA.event({
      action: 'view_orders',
      category: 'Orders',
    });
  }, []);

  return (
    <Layout inner>
      <Content className="py-4 md:py-6">
        <header className="mb-4">
          <Heading className="mb-0.5 md:mb-[12px] text-navy-100" level={3}>
            Order history
          </Heading>
        </header>

        <ol className="grid gap-3.5 md:grid-cols-2 lg:hidden">
          {orders?.slice(start, end).map(order => (
            <li
              className="flex flex-col rounded-lg shadow-card bg-white p-2 text-navy-100"
              key={order.id}>
              <header className="flex justify-between">
                <p>
                  <span className="block paragraph-m font-semibold">
                    {order.formatDate()}
                  </span>
                  <span className="text-navy-70">{order.status}</span>
                </p>
                <Heading className="text-blue-100 text-right" level={6}>
                  <Money amount={order.amount} />
                </Heading>
              </header>
              <p className="flex-1 flex flex-col mt-1.5 mb-1.5">
                {order.cart.cart_items.map(item => (
                  <span key={item.id} className="mb-[4px] last:mb-0 block">
                    {item.servings}x&nbsp;{item.name}
                  </span>
                ))}
              </p>
              <div className="grid gap-1.5">
                <OrderActions block order={order} />
              </div>
            </li>
          ))}
        </ol>

        <table cellSpacing={0} className="hidden lg:nice-table">
          <thead>
            <tr>
              <th>Menus ordered</th>
              <th>Date of booking</th>
              <th>Total paid</th>
              <th>Order status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders?.slice(start, end).map(order => (
              <tr key={order.id}>
                <td>
                  {order.cart.cart_items.map(item => (
                    <span key={item.id} className="mb-[4px] last:mb-0 block">
                      {item.servings}x&nbsp;{item.name}
                    </span>
                  ))}
                </td>
                <td>{order.formatDate()}</td>
                <td>
                  <Money amount={order.amount} />
                </td>
                <td>{order.status}</td>
                <td>
                  <div className="flex gap-1.5 justify-end">
                    <OrderActions order={order} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination {...pagination} />
      </Content>
    </Layout>
  );
};

const OrderActions: FC<{ block?: boolean; order: Order }> = ({
  block = false,
  order,
}) => {
  const { completeOrder, deleteOrder } = useOrder(order.id);

  return (
    <>
      {order.isCancelable && (
        <Button block={block} onClick={deleteOrder} theme="danger">
          Cancel order
        </Button>
      )}
      {order.isCompletable && (
        <Button block={block} onClick={completeOrder} theme="success">
          Complete order
        </Button>
      )}
      <Link to={order.url}>
        <Button block={block} theme="secondary">
          View details{' '}
        </Button>
      </Link>
    </>
  );
};
