import { Delete } from '@icon-park/react';
import classNames from 'classnames';
import { FC, useCallback } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Configuration } from '../../config';
import { Cuisine, FoodDietary, FoodType, MeasureUnit } from '../../model';
import {
  Button,
  Checkbox,
  Input,
  Radio,
  Select,
  Textarea,
  UploadImage,
} from '../atoms';

interface Props {
  cuisines: Cuisine[];
}

interface FormFields {
  cuisine: string;
  dietary: FoodDietary[];
  ingredients: string;
  ingridientsPer4Portions: IngridientsPer4Portions[];
  name: string;
  shots: FileList | null;
  type: FoodType;
}

interface IngridientsPer4Portions {
  name: string;
  quantity: number;
  unit: MeasureUnit;
}

export const FoodForm: FC<Props> = ({ cuisines }) => {
  const form = useForm<FormFields>({
    defaultValues: {
      cuisine: cuisines[0].name,
      dietary: [],
      ingridientsPer4Portions: [
        {
          name: '',
          quantity: 1,
          unit: MeasureUnit.Grams,
        },
      ],
      shots: null,
      type: FoodType.Entree,
    },
  });

  const onSubmit = useCallback(async (data: FormFields) => {
    console.log('debug: data ', data);
  }, []);

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'ingridientsPer4Portions',
  });

  return (
    <FormProvider {...form}>
      <div className="p-2.5 border border-navy-20 bg-white rounded-md">
        <form
          noValidate
          className="grid gap-2.5"
          onSubmit={form.handleSubmit(onSubmit)}>
          <Input required name="name" label="Menu item name" />
          <Textarea required name="ingredients" label="Ingredients" />
          <UploadImage
            isPreviewVisible
            required
            label="Item shots"
            name="photo"
          />
          <div>
            <span className="input-label">Type of menu*</span>
            <div className="grid grid-cols-2 gap-1.5">
              {Configuration.foodTypes.map(foodType => (
                <Radio key={foodType.label} name="type" value={foodType.value}>
                  {foodType.label}
                </Radio>
              ))}
            </div>
          </div>
          <div>
            <span className="input-label">Cuisines*</span>
            <div className="grid grid-cols-2 gap-1.5">
              {cuisines.map(cuisine => (
                <Radio key={cuisine.name} name="cuisine" value={cuisine.name}>
                  {cuisine.name}
                </Radio>
              ))}
            </div>
          </div>
          <div>
            <span className="input-label">Tags*</span>
            <div className="grid grid-cols-2 gap-1.5">
              {Configuration.foodDietaries.map((dietary, index) => (
                <Checkbox
                  key={dietary.label}
                  name={`dietary[${index}]`}
                  value={dietary.value}>
                  {dietary.label}
                </Checkbox>
              ))}
            </div>
          </div>
          <div>
            <span className="input-label">Ingredients per 4 portions*</span>

            {fields.map((field, index) => (
              <div className="mb-[12px] flex gap-[12px]" key={field.id}>
                <Input
                  required
                  className="flex-1"
                  placeholder="Ingredient name"
                  name={`ingridientsPer4Portions.${index}.name`}
                />
                <Input
                  required
                  valueAsNumber
                  className="max-w-[112px]"
                  placeholder="Quantity"
                  name={`ingridientsPer4Portions.${index}.quantity`}
                />
                <Select
                  required
                  className="max-w-[112px]"
                  name={`ingridientsPer4Portions.${index}.unit`}
                  options={Configuration.measureUnits}
                  placeholder="Unit"
                />
                <button
                  className={classNames(
                    'p-1 rounded-md bg-navy-5 hover:bg-navy-10 cursor-pointer transition-all',
                    index === 0 && 'invisible',
                  )}
                  type="button">
                  <Delete
                    className="text-navy-60"
                    onClick={() => remove(index)}
                    size={24}
                  />
                </button>
              </div>
            ))}
            <button
              className="mt-[12px] paragraph-s font-semibold text-blue-100"
              onClick={() =>
                append({
                  name: '',
                  quantity: 1,
                  unit: MeasureUnit.Grams,
                })
              }
              type="button">
              +&nbsp;Add another ingredient
            </button>
          </div>
          <footer className="flex justify-end">
            <Button type="submit">Submit</Button>
          </footer>
        </form>
      </div>
    </FormProvider>
  );
};
