import { useQuery } from 'react-query';
import { UsersApi } from '../api';
import { Chef } from '../model';

export function useChef(id?: number): Chef | undefined {
  const { data } = useQuery(['users', id], () => UsersApi.get(id!), {
    enabled: Boolean(id),
  });

  return data;
}
