import { FC, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { ApiError, AuthApi, AuthResponse, SignInRequest } from '../../api';
import { RoutePath } from '../../config';
import { useAppDispatch, useRoleFromQueryString } from '../../hooks';
import { authSlice } from '../../redux';
import {
  Alert,
  Button,
  FacebookAuthButton,
  GoogleAuthButton,
  Heading,
  Input,
  InputPassword,
  Paragraph,
} from '../atoms';

interface Props {
  withHeader?: boolean;
}

interface FormFields {
  email: string;
  password: string;
}

export const LoginForm: FC<Props> = ({ withHeader = false }) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const role = useRoleFromQueryString();
  const form = useForm<FormFields>({
    defaultValues: {
      email: '',
      password: '',
    },
    shouldUseNativeValidation: true,
  });
  const { handleSubmit, setError } = form;
  const { error, mutate: signIn } = useMutation<
    AuthResponse,
    ApiError<FormFields>,
    SignInRequest
  >(AuthApi.signIn, {
    onSuccess(response) {
      ReactGA.event({
        action: 'login',
        category: 'Auth',
      });
      dispatch(
        authSlice.actions.setCredentials({
          token: response.token,
        }),
      );
      if (response.active_profile_display === 'customer') {
        queryClient.refetchQueries('cart');
      }
    },
    onError(error) {
      for (const [key] of error.getFieldsErrors()) {
        setError(key, { type: 'custom', message: error.getFieldError(key) });
      }
    },
  });

  const onSubmit = useCallback(
    (data: FormFields) => {
      signIn({
        email: data.email,
        password: data.password,
      });
    },
    [signIn],
  );

  return (
    <FormProvider {...form}>
      <Alert>{error?.getNonFieldError()}</Alert>
      {withHeader && (
        <>
          <Heading className="mb-0.5 text-navy-100" level={3}>
            Login to your account
          </Heading>
          <Paragraph className="mb-3.5 text-navy-60" size="S">
            Welcome back! Please enter your details.
          </Paragraph>
        </>
      )}
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Input
          required
          autoComplete="username"
          className="mb-2"
          label="Email"
          name="email"
          placeholder="Enter your email"
          type="email"
        />
        <InputPassword
          required
          autoComplete="current-password"
          className="mb-2.5"
          label="Password"
          name="password"
        />
        <Link to={`${RoutePath.ForgotPassword}?role=${role}`}>
          <Paragraph semiBold className="my-2.5 block text-blue-100" size="S">
            Forgot password?
          </Paragraph>
        </Link>
        <Button block className="mb-1" type="submit">
          Login
        </Button>
        <GoogleAuthButton className="mb-1">Login with Google</GoogleAuthButton>
        <FacebookAuthButton>Login with Facebook</FacebookAuthButton>
      </form>
    </FormProvider>
  );
};
