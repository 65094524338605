import { addDays, differenceInDays } from 'date-fns';
import { FC, useCallback, useMemo } from 'react';
import { TimeSlotField } from '..';
import { useMqlStep } from '../../hooks';
import { Button, Heading, Paragraph } from '../atoms';
import { DateField } from './DateField';

export const MqlDateTimeStep: FC = () => {
  const { update } = useMqlStep(2, ['date', 'time']);

  const startDate = useMemo((): Date => addDays(new Date(), 8), []);

  const filterDate = useCallback(
    (date: Date): boolean => differenceInDays(date, startDate) >= 0,
    [startDate],
  );

  return (
    <div>
      <Heading
        className="mb-0.5 md:mb-[12px] text-navy-100 text-center"
        level={5}>
        When is it happening?
      </Heading>
      <Paragraph className="mb-3.5 text-navy-60 text-center" size="S">
        Please select the date & time of your occasion.
      </Paragraph>
      <div className="grid gap-2">
        <DateField required filterDate={filterDate} label="Date" name="date" />
        <TimeSlotField />
      </div>
      <Button block className="mt-2.5" onClick={update}>
        Continue
      </Button>
    </div>
  );
};
