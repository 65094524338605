import ReactGA from 'react-ga4';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ShoppingCartApi } from '../api';
import { ShoppingCartItem } from '../model';
import { useProfile } from './useProfile';

interface AddFoodToCardParams {
  count: number;
  foodId: number;
  name: string;
}

export function useShoppingCart() {
  const queryClient = useQueryClient();
  const { isAuthorized, isCustomer } = useProfile();
  const { data: shoppingCart, isSuccess } = useQuery(
    'cart',
    ShoppingCartApi.fetch,
    {
      enabled: !isAuthorized || isCustomer,
    },
  );
  const { mutate: addFoodToCart } = useMutation(
    ({ count, foodId, name }: AddFoodToCardParams) => {
      ReactGA.event({
        action: 'add_to_cart',
        category: 'Food List',
        label: name,
        value: count,
      });

      let newItems =
        shoppingCart?.cart_items.map(item => ({
          food_service_pk: Number(item.food_service_pk),
          name: item.name,
          servings: item.servings,
        })) ?? [];

      if (newItems.some(item => item.food_service_pk === foodId)) {
        newItems = newItems.map(item => {
          if (item.food_service_pk === foodId) {
            return {
              food_service_pk: foodId,
              name: name,
              servings: count,
            };
          }

          return item;
        });
      } else {
        newItems.push({
          food_service_pk: foodId,
          name: name,
          servings: count,
        });
      }

      return ShoppingCartApi.add(newItems);
    },
    {
      onSuccess(data) {
        queryClient.setQueryData('cart', data);
      },
    },
  );
  const { mutate: removeFoodFromCart } = useMutation(
    (item: ShoppingCartItem) => {
      ReactGA.event({
        action: 'remove_from_cart',
        category: 'Food List',
        label: item.name,
      });

      return ShoppingCartApi.remove(item);
    },
    {
      onSuccess(data) {
        queryClient.setQueryData('cart', data);
      },
    },
  );
  const { mutate: setDate } = useMutation(
    (date: Date) => ShoppingCartApi.setDate(date),
    {
      onSuccess(data) {
        queryClient.setQueryData('cart', data);
      },
    },
  );

  return {
    ...shoppingCart,
    addFoodToCart,
    cart_items: shoppingCart?.cart_items ?? [],
    isSuccess,
    removeFoodFromCart,
    setDate,
  };
}
