import { FC } from 'react';
import { FoodDetails, Modal } from '.';
import { useModal } from '../../hooks';
import { Food } from '../../model';
import { AddFoodToFavorites, Button, Card, Paragraph } from '../atoms';
import { FoodCategories } from '../molecules';
import { AddFoodToCartWidget } from '../molecules/AddFoodToCartWidget';

interface Props {
  food: Food;
}

export const FoodCard: FC<Props> = ({ food }) => {
  const { modalRef, openModal } = useModal();

  return (
    <Card
      preview={
        <img
          alt={food.name}
          className="block rounded-t-lg object-cover h-[251px] md:h-[288px]"
          src={food.image_url}
        />
      }>
      <header className="mb-2.5 flex-1">
        <div className="mb-0.5 flex items-center justify-between">
          <Paragraph semiBold size="M">
            {food.name}
          </Paragraph>
          <AddFoodToFavorites food={food} />
        </div>
        <FoodCategories categories={food.food_categories} />
      </header>
      <Button
        block
        className="mb-1.5"
        theme="secondary"
        onClick={openModal}
        title={`View details of ${food.name}`}>
        View details
      </Button>
      <AddFoodToCartWidget food={food} />

      <Modal ref={modalRef}>
        <FoodDetails food={food} />
      </Modal>
    </Card>
  );
};
