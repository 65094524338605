import type { FC } from 'react';
import { AuthRoute, ForgotPasswordForm } from '../components';
import { AuthLayout } from '../layout';

export const ForgotPasswordPage: FC = () => {
  return (
    <AuthRoute>
      <AuthLayout>
        <ForgotPasswordForm />
      </AuthLayout>
    </AuthRoute>
  );
};
