import type { FC } from 'react';
import {
  Content,
  Heading,
  ReferralBonus,
  ReferralForm,
  ReferralSentList,
} from '../components';
import { useProfile } from '../hooks';
import { Layout } from '../layout';
import { Link } from 'react-router-dom';
import { RoutePath } from '../config';

export const ReferralPage: FC = () => {
  const { profile } = useProfile();

  return (
    <Layout inner>
      <Content className="py-4 md:py-6 grid md:grid-cols-2 gap-4 md:gap-x-12 md:gap-y-4 items-center">
        <img
          alt="Referral"
          className="block md:hidden rounded-lg"
          src={require('../assets/images/referral.png')}
        />
        <div>
          <Heading className="mb-2 md:mb-2.5 text-blue-100" level={3}>
            Refer and{' '}
            <span className="py-0.5 px-1.5 bg-green-10 text-green-100 rounded-[12px]">
              earn&nbsp;
              <ReferralBonus />
            </span>
            <br />
            for each new customer
          </Heading>
          <p className="mb-4 md:mb-4.5 paragraph-l text-navy-70">
            We pay <ReferralBonus /> for every new customer you bring in - No
            upper limit to how much you can earn.
          </p>
          {profile ? (
            <ReferralForm profile={profile} />
          ) : (
            <Link
              className="button button--primary"
              to={{
                pathname: RoutePath.Register,
                search: `redirect=${encodeURIComponent(RoutePath.Referral)}`,
              }}>
              Sign up & start earning
            </Link>
          )}
        </div>
        <img
          alt="Referral"
          className="hidden md:block rounded-lg"
          src={require('../assets/images/referral.png')}
        />
        <ReferralSentList />
      </Content>
    </Layout>
  );
};
