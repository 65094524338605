import axios from 'axios';
import { plainToInstance } from 'class-transformer';
import { Order } from '../model';
import { ApiError } from './ApiError';
import { ErrorResponse } from './ErrorResponse';
import { HttpClient } from './HttpClient';

export interface CreateOrderParams {
  cartId: number;
  notes?: string;
}

export class OrdersApi {
  static async create(params: CreateOrderParams): Promise<Order> {
    try {
      const { data } = await HttpClient.getInstance().post('/v2/orders/', {
        cart_id: params.cartId,
        notes: params.notes,
      });

      return plainToInstance(Order, data);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const data = error.response.data as ErrorResponse<CreateOrderParams>;
        throw new ApiError(data);
      }
    }

    throw new ApiError();
  }

  static async get(id: string | number): Promise<Order> {
    const { data } = await HttpClient.getInstance().get(`/v2/order/${id}/`);

    return plainToInstance(Order, data);
  }

  static async getAll(): Promise<Order[]> {
    const { data } = await HttpClient.getInstance().get<Order[]>('/v2/orders/');

    return plainToInstance(Order, data);
  }

  static async complete(id: number): Promise<void> {
    const { data } = await HttpClient.getInstance().put(`/v2/order/${id}/`);

    console.log('debug: data ', data);
  }

  static async delete(id: number): Promise<void> {
    const { data } = await HttpClient.getInstance().delete(`/v2/order/${id}/`);

    console.log('debug: data ', data);
  }
}
