import { Delete } from '@icon-park/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useShoppingCart } from '../../hooks';
import { ShoppingCartItem } from '../../model';
import { Card, Counter, Heading, Money, Paragraph } from '../atoms';
import { FoodCategories } from './FoodCategories';

interface Props {
  item: ShoppingCartItem;
}

export const ShoppingCartItemCard: FC<Props> = ({ item }) => {
  const { addFoodToCart, removeFoodFromCart } = useShoppingCart();
  const [count, setCount] = useState(item.servings);

  const removeFromCart = useCallback(() => {
    removeFoodFromCart(item);
  }, [item, removeFoodFromCart]);

  useEffect(() => {
    if (count !== item.servings) {
      addFoodToCart({
        count,
        foodId: Number(item.food_service_pk),
        name: item.name,
      });
    }
  }, [addFoodToCart, count, item]);

  return (
    <Card
      className="md:flex-row"
      preview={
        <img
          alt={item.name}
          className="block rounded-t-lg md:rounded-t-none md:rounded-l-lg object-cover h-[251px] md:h-full md:max-w-[192px]"
          src={item.image}
        />
      }>
      <header className="mb-2.5 md:mb-1.5 flex-1">
        <div className="mb-0.5 flex items-center justify-between">
          <Paragraph semiBold size="M">
            {item.name}
          </Paragraph>

          <Heading className="text-blue-100" level={6}>
            <Money amount={Number(item.amount)} />
          </Heading>
        </div>
        <FoodCategories categories={item.food_categories} />
      </header>
      <div className="flex justify-between">
        <button
          className="p-1 rounded-md bg-navy-5 hover:bg-navy-10 cursor-pointer transition-all"
          type="button">
          <Delete className="text-navy-60" onClick={removeFromCart} size={24} />
        </button>
        <Counter value={count} onChange={setCount} />
      </div>
    </Card>
  );
};
