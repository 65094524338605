import { Food } from '../model';
import { HttpClient } from './HttpClient';

export class FoodApi {
  static async getAll(isFeatured = false): Promise<Food[]> {
    const { data } = await HttpClient.getInstance().get(`/v2/foods/`, {
      params: {
        is_featured: isFeatured,
      },
    });

    return data.data;
  }

  static async get(id: string): Promise<Food> {
    const { data } = await HttpClient.getInstance().get(`/v2/food/${id}/`);

    return data.data;
  }

  static async addToFavorite(userId: number, foodId: number): Promise<void> {
    await HttpClient.getInstance().patch(`/users/${userId}/favorite-foods/`, {
      food_service_pk: foodId,
    });
  }

  static async removeFromFavorite(
    userId: number,
    foodId: number,
  ): Promise<void> {
    await HttpClient.getInstance().delete(`/users/${userId}/favorite-foods/`, {
      data: {
        food_service_pk: foodId,
      },
    });
  }
}
