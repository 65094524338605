import classNames from 'classnames';
import { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useParams } from 'react-router-dom';
import {
  Content,
  Heading,
  OrderChefWidget,
  OrderItemCard,
  OrderSummary,
} from '../components';
import { ReferralWidget } from '../components/organisms/ReferralWidget';
import { useChef, useOrder, useProfile } from '../hooks';
import { Layout } from '../layout';
import styles from './OrderPage.module.css';

export const OrderPage: FC = () => {
  const { profile } = useProfile();
  const { id } = useParams<{ id: string }>();
  const { order } = useOrder(id);
  const chef = useChef(order?.chef_user_pk);

  let title = 'Order placed successfully!';
  let subtitle: string | undefined =
    'You’ll be notified once you’re assigned a chef and charged the ingredients fee.';

  if (order?.hasChef) {
    title = 'Order confirmed!';
    subtitle =
      'You’ve been assigned a chef, our chef will take care of everything from here.';
  }

  if (order?.isCompleted) {
    title = 'Order complete!';
    subtitle = 'We hope you had an amazing ocassion and wonderful food.';
  }

  if (order?.isCanceled) {
    title = 'Order canceled';
    subtitle = undefined;
  }

  useEffect(() => {
    if (order) {
      ReactGA.event({
        action: 'view_orders',
        category: 'Orders',
        value: order.id,
      });
    }
  }, [order]);

  return (
    <Layout inner>
      <Content className="py-4 md:py-6">
        <header className="mb-4">
          <Heading className="mb-0.5 md:mb-[12px] text-navy-100" level={3}>
            {title}
          </Heading>
          {subtitle && (
            <span className="paragraph-s text-navy-70">{subtitle}</span>
          )}
        </header>
        <div className={styles.container}>
          <div className={classNames(styles.items, 'grid gap-3.5')}>
            <ul className="grid gap-3.5">
              {order?.cart.cart_items.map(item => (
                <OrderItemCard key={item.id} item={item} />
              ))}
            </ul>
            {profile && <ReferralWidget profile={profile} />}
          </div>
          {order && (
            <div className={classNames(styles.summary, 'grid gap-3.5')}>
              {!!chef && <OrderChefWidget chef={chef} />}
              <OrderSummary order={order} />
            </div>
          )}
        </div>
      </Content>
    </Layout>
  );
};
