import { FC } from 'react';
import {
  HomeBenefitsSection,
  HomeChefsSection,
  HomeCTA,
  HomeFAQSection,
  HomeFoodSection,
  HomeHeroSection,
  HomeHowSection,
  HomePricingSection,
  HomeTestimonialsSection,
  OrganicShapeBackground,
} from '../components';
import { Layout } from '../layout';

export const HomePage: FC = () => {
  return (
    <Layout>
      <HomeHeroSection />
      <HomeBenefitsSection />
      <OrganicShapeBackground position="right">
        <HomeHowSection />
        <HomeChefsSection />
        <HomeFoodSection />
      </OrganicShapeBackground>
      <HomeTestimonialsSection />
      <OrganicShapeBackground position="left">
        <HomePricingSection />
        <HomeFAQSection />
      </OrganicShapeBackground>
      <HomeCTA />
    </Layout>
  );
};
