import { FC } from 'react';
import { User } from '../../model';
import { Paragraph, ReferralBonus } from '../atoms';
import { Widget } from '../molecules';
import { ReferralForm } from './ReferralForm';

interface Props {
  className?: string;
  profile: User;
}

export const ReferralWidget: FC<Props> = ({ className, profile }) => {
  return (
    <Widget className={className} gap={0.5} title="Invite your friends">
      <Paragraph className="mb-2.5 text-navy-70" size="S">
        Invite a friend! They get{' '}
        <span className="text-blue-100">
          <ReferralBonus /> off
        </span>{' '}
        their first order and you get paid{' '}
        <span className="text-blue-100">
          <ReferralBonus />
        </span>{' '}
        when they complete it.
      </Paragraph>
      <ReferralForm block profile={profile} />
    </Widget>
  );
};
