import { FC, useCallback, useState } from 'react';
import { Input, InputProps } from './Input';
import { PreviewCloseOne, PreviewOpen } from '@icon-park/react';

export const InputPassword: FC<InputProps> = props => {
  const [visible, setVisible] = useState(false);

  const toggle = useCallback(() => setVisible(current => !current), []);

  return (
    <Input
      {...props}
      suffix={
        visible ? (
          <PreviewOpen className='text-navy-60' onClick={toggle} size={24} />
        ) : (
          <PreviewCloseOne
            className='text-navy-60'
            onClick={toggle}
            size={24}
          />
        )
      }
      type={visible ? 'text' : 'password'}
    />
  );
};
