import { ArrowLeft, ArrowRight } from '@icon-park/react';
import { FC } from 'react';
import { PaginationProps, useIsDesktop } from '../../hooks';
import { Button, Paragraph } from '../atoms';

export const Pagination: FC<PaginationProps> = ({
  back,
  forward,
  page,
  pages,
  setPage,
}) => {
  const isDesktop = useIsDesktop();
  const isFirst = page === 0;
  const isLast = page === pages.length - 1;

  return (
    <div className="py-[44px] md:py-3.5 flex items-center justify-between">
      <Button
        icon={!isDesktop}
        disabled={isFirst}
        onClick={back}
        theme="outlined">
        <span className="hidden md:inline">←&nbsp;Previous</span>
        <ArrowLeft className="md:hidden" size={22} />
      </Button>
      {isDesktop ? (
        <div className="flex-1 flex justify-center">
          {pages.map(item => {
            const isCurrent = item === page + 1;

            if (isCurrent) {
              return (
                <Button
                  icon
                  className="w-[44px] justify-center"
                  key={item}
                  theme="secondary">
                  <Paragraph size="S">{item}</Paragraph>
                </Button>
              );
            }

            return (
              <button
                className="w-[44px] text-center"
                key={item}
                onClick={() => setPage(item - 1)}
                type="button">
                <Paragraph size="S">{item}</Paragraph>
              </button>
            );
          })}
        </div>
      ) : (
        <span className="flex-1 text-navy-70 text-center">
          Page {page + 1} of {pages.length}
        </span>
      )}
      <Button
        icon={!isDesktop}
        disabled={isLast}
        onClick={forward}
        theme={isDesktop ? 'primary' : 'outlined'}>
        <span className="hidden md:inline">Next&nbsp;→</span>
        <ArrowRight className="md:hidden" size={22} />
      </Button>
    </div>
  );
};
