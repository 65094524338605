import { plainToInstance } from 'class-transformer';
import { Referral } from '../model';
import { HttpClient } from './HttpClient';

export class ReferralsApi {
  static async getList(): Promise<Referral[]> {
    const { data } = await HttpClient.getInstance().get<unknown[]>('/v1/referrals/');

    return plainToInstance(Referral, data);
  }

  static async sendInvite(email: string): Promise<void> {
    await HttpClient.getInstance().post('/v1/referrals/', {
      email,
    });
  }
}
