import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  ChefImagesStep,
  ChefInformationStep,
  ChefPaymentStep,
  Content,
  Logo,
  RegisterForm,
  Steps,
} from '../components';
import { RoutePath } from '../config';
import { ChefOnboardingFormFields, useProfile } from '../hooks';

export const RegisterChefPage: FC = () => {
  const { profile } = useProfile();
  const form = useForm<ChefOnboardingFormFields>({
    defaultValues: {
      actionPhoto: null,
      birthDate: null,
      description: '',
      profilePhoto: null,
      step: 0,
    },
  });
  const activeStep = form.watch('step');

  useEffect(() => {
    if (activeStep === 0 && profile) {
      form.setValue('step', 1);
    }
  }, [activeStep, form, profile]);

  return (
    <div className="min-h-screen bg-blue-5">
      <header className="py-1.5 px-2 md:p-2.5">
        <Content className="flex items-center justify-between">
          <Logo />
          <span className="md: block paragraph-s text-navy-60">
            Already a chef?&nbsp;
            <Link className="link" to={RoutePath.Login}>
              Login
            </Link>
          </span>
        </Content>
      </header>
      <main className="py-4 mx-auto max-w-[400px]">
        <FormProvider {...form}>
          <Steps
            activeStep={activeStep}
            className="mx-auto mb-4 w-[288px]"
            stepsCount={4}
          />
          {activeStep === 0 && <RegisterForm role="chef" />}
          {activeStep === 1 && !!profile && (
            <ChefInformationStep profile={profile} />
          )}
          {activeStep === 2 && !!profile && (
            <ChefImagesStep profile={profile} />
          )}
          {activeStep === 3 && <ChefPaymentStep />}
        </FormProvider>
      </main>
    </div>
  );
};
