import type { FC } from 'react';
import { ReactComponent as AffordableIcon } from '../../assets/icons/affordable.svg';
import { ReactComponent as ConvenientIcon } from '../../assets/icons/convenient.svg';
import { ReactComponent as HealthyIcon } from '../../assets/icons/healthy.svg';
import { Content, Heading, OrganicShape, Paragraph } from '../atoms';

export const HomeBenefitsSection: FC = () => {
  return (
    <section className="bg-blue-5 text-navy-100 py-8 md:py-12">
      <Content className="grid gap-6 md:grid-cols-2 md:gap-3 md:items-center">
        <div className="flex justify-center">
          <OrganicShape className="max-w-[277px] md:max-w-[491px]">
            <img
              alt="Benefits"
              src={require('../../assets/images/benefits.png')}
            />
          </OrganicShape>
        </div>
        <div>
          <Heading className="mb-4 md:mb-6" level={2}>
            Our benefits
          </Heading>
          <ul>
            <li className="mb-4 flex gap-2">
              <ConvenientIcon className="flex-[0_0_48px]" />
              <div>
                <Heading className="mb-0.5" level={5}>
                  Convenient
                </Heading>
                <Paragraph className="text-navy-70" size="S">
                  Book a chef from the comfort of your home, and we handle
                  everything else - from getting groceries to finding the
                  perfect chef for you.
                </Paragraph>
              </div>
            </li>
            <li className="mb-4 flex gap-2">
              <AffordableIcon className="flex-[0_0_48px]" />
              <div>
                <Heading className="mb-0.5" level={5}>
                  Affordable
                </Heading>
                <Paragraph className="text-navy-70" size="S">
                  Access special prices that are a fraction of what you pay at
                  restaurants. No hidden costs anywhere.
                </Paragraph>
              </div>
            </li>
            <li className="flex gap-2">
              <HealthyIcon className="flex-[0_0_48px]" />
              <div>
                <Heading className="mb-0.5" level={5}>
                  Healthy
                </Heading>
                <Paragraph className="text-navy-70" size="S">
                  Ingredients do not get any fresher and tastier. Your chef is
                  going to cook them to perfection!
                </Paragraph>
              </div>
            </li>
          </ul>
        </div>
      </Content>
    </section>
  );
};
