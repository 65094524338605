import classNames from 'classnames';
import type { FC } from 'react';
import { ReactNode } from 'react';
import { Footer } from './Footer';
import { Header } from './Header';

interface Props {
  children: ReactNode;
  inner?: boolean;
}

export const Layout: FC<Props> = ({ children, inner }) => {
  return (
    <>
      <Header />
      <main
        className={classNames({
          'bg-blue-5': inner,
        })}>
        {children}
      </main>
      <Footer />
    </>
  );
};
