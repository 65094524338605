import { orderSlice } from './order.slice';
import { configureStore } from '@reduxjs/toolkit';
import { HttpClient } from './../api/HttpClient';
import { authSlice } from './auth.slice';
import { configSlice } from './config.slice';

const token = localStorage.getItem('token');
if (token) {
  HttpClient.setToken(token);
}

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    config: configSlice.reducer,
    order: orderSlice.reducer,
  },
  preloadedState: {
    auth: {
      isAuthorized: Boolean(token),
      token: token,
    },
  },
});
export type AppState = ReturnType<typeof store.getState>;
