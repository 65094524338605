import classNames from 'classnames';
import { FC, TextareaHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;

  helper?: string;
  label?: string;
  optional?: boolean;
}

export const Textarea: FC<Props> = ({
  className,
  helper,
  label,
  maxLength,
  minLength,
  name = '',
  optional = false,
  required = false,
  ...rest
}) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  const isInvalid = name ? Boolean(errors[name]) : false;
  const inputClassName = classNames('input', {
    'input-invalid': isInvalid,
  });

  return (
    <label className={classNames('block', className)}>
      {Boolean(label) && (
        <span className="input-label">
          {label}
          {required && '*'}
          {optional && (
            <>
              &nbsp;
              <span className="font-base font-normal text-navy-60">
                (optional)
              </span>
            </>
          )}
        </span>
      )}
      <div className="flex items-center">
        <textarea
          {...rest}
          {...register(name, {
            maxLength,
            minLength,
            required,
          })}
          className={inputClassName}
          required={required}
        />
      </div>
      {Boolean(helper) && <span className="input-helper">{helper}</span>}
    </label>
  );
};
