import { useQuery } from 'react-query';
import { UsersApi } from './../api/Users.api';
import { selectIsAuthorized } from './../redux/auth.selectors';
import { authSlice } from './../redux/auth.slice';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

export function useProfile() {
  const dispatch = useAppDispatch();
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const { data } = useQuery(
    'me',
    async () => {
      const user = await UsersApi.me();

      try {
        if (user.photoURL) {
          await user.fetchPhoto();
        }
      } catch (error) {
        console.error('Could not fetch user photo', error);
      }

      return user;
    },
    {
      enabled: isAuthorized,
      onError() {
        dispatch(authSlice.actions.logOut());
      },
    },
  );

  return {
    isAuthorized,
    isChef: data?.active_profile_display === 'chef',
    isCustomer: data?.active_profile_display === 'customer',
    profile: data,
  };
}
