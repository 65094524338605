import { Combobox, Transition } from '@headlessui/react';
import { LocalTwo } from '@icon-park/react';
import classNames from 'classnames';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { usePlaces } from '../../hooks';

interface Props {
  label?: string;
  required?: boolean;
}

export const LocationField: FC<Props> = ({ label, required = false }) => {
  const {
    formState: { errors },
    setValue,
    watch,
    register,
  } = useFormContext();
  const {
    getPlaceDetails,
    getPlacePredictions,
    placePredictions,
    isPlacePredictionsLoading,
  } = usePlaces();
  register('location_id', {
    required,
  });
  const [address, location] = watch(['address', 'location']);
  const [localInput, setLocalInput] = useState(address ?? '');

  const handleChangeLocation = useCallback(
    (newLocation: google.maps.places.AutocompletePrediction) => {
      setValue('location_id', newLocation.place_id);
      setValue('location', newLocation);
    },
    [setValue],
  );

  useEffect(() => {
    if (placePredictions.length > 0 && location) {
      getPlaceDetails(location.place_id).then(fullAddress => {
        setValue('address', fullAddress.address);
        setValue('city', fullAddress.city);
        setValue('country_iso_code', fullAddress.country_iso_code);
        setValue('country', fullAddress.country);
        setValue('state', fullAddress.state);
        setValue('zipcode', fullAddress.zipcode);
      });
    }
  }, [getPlaceDetails, placePredictions, location, setValue]);

  return (
    <Combobox value={location} onChange={handleChangeLocation}>
      <div className="relative z-10">
        {label && <span className="input-label">{label}</span>}
        <div className="relative w-full overflow-hidden text-left">
          <Combobox.Input
            autoComplete="off"
            className={classNames('input', {
              'input-invalid': Boolean(errors['location_id']),
            })}
            name="location_id"
            displayValue={(
              prediction?: google.maps.places.AutocompletePrediction,
            ) => prediction?.description ?? localInput}
            placeholder="ex: 15 Canada St., Toronto, ON"
            value={localInput}
            onChange={event => {
              const newValue = event.target.value;
              setLocalInput(newValue);
              getPlacePredictions({
                input: newValue,
              });
            }}
            onBlur={() => {
              if (localInput === '') {
                setValue('location_id', undefined);
              }
            }}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-[14px]">
            <LocalTwo className="text-navy-60" size={24} />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => getPlacePredictions({ input: '' })}>
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto border border-navy-20 rounded-md bg-white shadow-dropdown text-lg leading-24">
            {placePredictions.length === 0 ? (
              <div className="relative select-none py-1 px-[14px] text-navy-60">
                {isPlacePredictionsLoading ? 'Loading...' : 'Nothing found.'}
              </div>
            ) : (
              placePredictions.map(prediction => (
                <Combobox.Option
                  key={prediction.place_id}
                  className={({ active }) =>
                    `relative select-none py-1 px-[14px] text-navy-100 ${
                      active ? 'bg-blue-10' : 'bg-white'
                    }`
                  }
                  value={prediction}>
                  {({ selected, active }) => (
                    <span
                      className={classNames({
                        'font-semibold': selected,
                      })}>
                      {prediction.description}
                    </span>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};
