// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Expose } from 'class-transformer';
import type { PhotoType } from './PhotoType';
import type { User } from './User';

export class Photo {
  #file: File | undefined;

  readonly id!: number;

  @Expose({ name: 'image_url' })
  readonly url!: string;

  @Expose({ name: 'photo_type' })
  readonly type!: PhotoType;

  readonly user!: User['id'];

  get file(): File | undefined {
    return this.#file;
  }

  async fetchFile(): Promise<File> {
    if (this.#file) {
      return this.#file;
    }

    const response = await fetch(this.url);
    const blob = await response.blob();
    this.#file = new File([blob], this.url, { type: blob.type });

    return this.#file;
  }
}
