import classNames from 'classnames';
import type { FC, HTMLAttributes, PropsWithChildren } from 'react';
import styles from './OrganicShape.module.css';

export const OrganicShape: FC<
  PropsWithChildren<HTMLAttributes<HTMLDivElement>>
> = ({ children, className, ...rest }) => {
  return (
    <div className={classNames(styles.container, className)}>{children}</div>
  );
};
