import axios from 'axios';
import type { FC } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ScrollToTop } from './components';
import { RoutePath } from './config';
import { useProfile } from './hooks';
import {
  AccountSettingsPage,
  CheckoutPage,
  Covid19Page,
  CreateFoodPage,
  CuisineMenuPage,
  CuisinesPage,
  ForgotPasswordPage,
  HomePage,
  LoginPage,
  MqlPage,
  OrderHistoryPage,
  OrderPage,
  PrivacyPage,
  ReferralPage,
  RegisterChefPage,
  RegisterPage,
  ShoppingCartPage,
  TermsPage,
} from './pages';
import { authSlice, store } from './redux';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: async (error, query) => {
      if (axios.isAxiosError(error)) {
        if (error.request.status === 403) {
          store.dispatch(authSlice.actions.logOut());
          queryClient.removeQueries(query.queryKey);
        }
      }
    },
  }),
});

export const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <Pages />
          </ScrollToTop>
        </BrowserRouter>
      </ReduxProvider>
    </QueryClientProvider>
  );
};

const Pages: FC = () => {
  const { isAuthorized } = useProfile();

  return (
    <Routes>
      <Route path={RoutePath.Home} element={<HomePage />} />

      <Route path={RoutePath.MQL} element={<MqlPage />} />

      <Route path={RoutePath.Referral} element={<ReferralPage />} />

      <Route path={RoutePath.Login} element={<LoginPage />} />
      <Route path={RoutePath.Register} element={<RegisterPage />} />
      <Route path={RoutePath.RegisterChef} element={<RegisterChefPage />} />
      <Route path={RoutePath.ForgotPassword} element={<ForgotPasswordPage />} />

      {isAuthorized && (
        <Route
          path={RoutePath.AccountSettings}
          element={<AccountSettingsPage />}
        />
      )}
      {isAuthorized && (
        <Route path={RoutePath.CreateFood} element={<CreateFoodPage />} />
      )}

      <Route path={RoutePath.Cuisines} element={<CuisinesPage />} />
      <Route path={RoutePath.CuisineMenu} element={<CuisineMenuPage />} />

      <Route path={RoutePath.ShoppingCart} element={<ShoppingCartPage />} />
      {isAuthorized && (
        <Route path={RoutePath.Checkout} element={<CheckoutPage />} />
      )}

      {isAuthorized && <Route path={RoutePath.Order} element={<OrderPage />} />}
      {isAuthorized && (
        <Route path={RoutePath.OrderHistory} element={<OrderHistoryPage />} />
      )}

      <Route path={RoutePath.Terms} element={<TermsPage />} />
      <Route
        path={`${RoutePath.Terms}.html`}
        element={<Navigate replace to={RoutePath.Terms} />}
      />
      <Route path={RoutePath.Privacy} element={<PrivacyPage />} />
      <Route
        path={`${RoutePath.Privacy}.html`}
        element={<Navigate replace to={RoutePath.Privacy} />}
      />
      <Route path={RoutePath.Covid19} element={<Covid19Page />} />
      <Route
        path={`${RoutePath.Covid19}.html`}
        element={<Navigate replace to={RoutePath.Privacy} />}
      />

      <Route path="*" element={<Navigate replace to={RoutePath.Home} />} />
    </Routes>
  );
};
