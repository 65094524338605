// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Expose, Type } from 'class-transformer';
import type { Role } from './Role';

export class User {
  #photo: File | undefined;

  readonly active_profile_display!: Role;
  readonly active_profile!: 0 | 1;
  readonly address: string = '';
  readonly apple_id: null = null;
  readonly city!: string;
  readonly country_iso_code: string | null = null;
  readonly country: string = '';

  @Expose({ name: 'date_joined' })
  @Type(() => Date)
  readonly dateJined: Date = new Date();

  @Type(() => Date)
  readonly date_of_birth: null | Date = null;
  readonly email!: string;
  readonly facebook_id: null = null;
  readonly favorite_foods: number[] = [];
  readonly first_name!: string;
  readonly gender: null = null;
  readonly google_id: null = null;
  readonly groups: string[] = [];
  readonly id!: number;
  readonly is_active: boolean = false;
  readonly is_background_verified: boolean = false;
  readonly is_completed: boolean = false;
  readonly is_launched: boolean = false;
  readonly last_name!: string;
  readonly location_id: string | null = null;
  readonly phone?: string = '';
  readonly referral_code: string = '';
  readonly referral_link: string = '';
  readonly qr_code?: string;

  @Expose({ name: 'photo' })
  readonly photoURL: string | null = null;

  readonly state: string = '';
  readonly user_permissions: string[] = [];
  readonly zipcode: string = '';

  get photo(): File | undefined {
    return this.#photo;
  }

  async fetchPhoto(): Promise<File> {
    if (!this.photoURL) {
      throw new Error('URL of photo is not defined');
    }

    if (this.#photo) {
      return this.#photo;
    }

    const response = await fetch(this.photoURL);
    const blob = await response.blob();
    this.#photo = new File([blob], this.photoURL, { type: blob.type });

    return this.#photo;
  }
}

export type Address = Pick<
  User,
  | 'address'
  | 'city'
  | 'country_iso_code'
  | 'country'
  | 'location_id'
  | 'phone'
  | 'state'
  | 'zipcode'
>;
