import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { BillingApi } from '../../api';
import { Configuration } from '../../config';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { User } from '../../model';
import { orderSlice, selectOrder } from '../../redux';
import { Alert, Button } from '../atoms';
import { Widget } from '../molecules';

interface Props {
  profile: User;
}

export const PaymentMethodWidget: FC<Props> = ({ profile }) => {
  const dispatch = useAppDispatch();
  const order = useAppSelector(selectOrder);
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { mutate: addPaymentIntent } = useMutation(
    BillingApi.addPaymentIntent,
    {
      retry: false,
      onSuccess() {
        queryClient.invalidateQueries('payment-methods');
      },
    },
  );
  const [success, setSuccess] = useState(false);
  const { data: cards = [] } = useQuery(
    'payment-methods',
    BillingApi.fetchPaymentMethods,
    {
      refetchOnMount: true,
      onSuccess(response) {
        setSuccess(response.length > 0);
        if (response.length > 0) {
          dispatch(orderSlice.actions.fillPaymentMethod());
        }
      },
    },
  );
  const [errorMessage, setErrorMessage] = useState<string>();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const status = searchParams.get('redirect_status');
    const setupIntent = searchParams.get('setup_intent');

    if (status === 'succeeded' && setupIntent) {
      addPaymentIntent(setupIntent);
    }
  }, [addPaymentIntent, searchParams]);

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const returnURL = new URL('/checkout', document.location.origin);

      try {
        const result = await stripe.confirmSetup({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            payment_method_data: {
              billing_details: {
                address: {
                  city: profile.city ?? '',
                  country:
                    profile.country_iso_code ??
                    Configuration.defaultCountryISOCode,
                  line1: profile.address ?? '',
                  line2: '',
                  postal_code: profile.zipcode ?? '',
                  state: profile.state ?? '',
                },
                email: profile.email,
                name: `${profile.first_name} ${profile.last_name}`,
                phone: profile.phone,
              },
            },
            return_url: returnURL.toString(),
          },
          redirect: 'if_required',
        });

        if (result.setupIntent) {
          await addPaymentIntent(result.setupIntent.id);
        }

        if (result.error) {
          // Show error to your customer (for example, payment details incomplete)
          console.error(result.error.message);
          setErrorMessage(result.error.message);
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      } catch (error) {
        console.error(error);

        if (error instanceof Error) {
          setErrorMessage(error.message);
        }
      }
    },
    [addPaymentIntent, elements, profile, stripe],
  );

  const hasCards = cards.length > 0;
  const orderInformationFilled = order.addressFilled && order.notesFilled;
  const activePaymentMethod = cards.find(card => card.is_active);

  if (success && activePaymentMethod) {
    return (
      <Widget
        interactive
        active={orderInformationFilled || hasCards}
        onClick={() => setSuccess(false)}
        title="Payment method">
        <div className="grid grid-cols-2 gap-0.5">
          <p className="md:flex md:gap-2">
            <span className="block paragraph-s text-navy-60">
              Name on card:
            </span>
            <span className="block paragraph-s text-navy-100">
              {profile.first_name.toUpperCase()}&nbsp;
              {profile.last_name.toUpperCase()}
            </span>
          </p>
          <p className="md:flex md:gap-2">
            <span className="block paragraph-s text-navy-60">Expiry:</span>
            <span className="block paragraph-s text-navy-100">
              {activePaymentMethod.exp_month}/{activePaymentMethod.exp_year}
            </span>
          </p>
          <p className="md:flex md:gap-2">
            <span className="block paragraph-s text-navy-60">Card number:</span>
            <span className="block paragraph-s text-navy-100">
              ****{activePaymentMethod.last4}
            </span>
          </p>
          <p className="md:flex md:gap-2">
            <span className="block paragraph-s text-navy-60">CVC:</span>
            <span className="block paragraph-s text-navy-100">•••</span>
          </p>
        </div>
      </Widget>
    );
  }

  return (
    <Widget
      active={orderInformationFilled || hasCards}
      interactive={cards.length > 0}
      actionText="Cancel"
      onClick={() => setSuccess(true)}
      title="Payment method">
      <Alert>{errorMessage}</Alert>
      <form onSubmit={onSubmit}>
        <PaymentElement
          options={{
            fields: {
              billingDetails: 'never',
            },
          }}
        />

        <Button block className="mt-2.5" type="submit">
          Confirm payment
        </Button>
      </form>
    </Widget>
  );
};
