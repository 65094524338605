import type { FC } from 'react';
import { ReactComponent as GooglePlayIcon } from '../../assets/icons/google-play.svg';

export const GetItOnGooglePlayButton: FC = () => {
  return (
    <a
      href="https://play.google.com/store/apps/details?id=com.getchefnow.chefnow_app&hl=en_CA&gl=US"
      target="_blank"
      rel="noreferrer">
      <GooglePlayIcon className="w-[135px] h-[40px] md:w-[162px] md:h-[48px]" />
    </a>
  );
};
