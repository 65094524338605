import type { FC } from 'react';

interface Props {
  amount: number; // Cents
}

const moneyFormatter = new Intl.NumberFormat('en', {
  currency: 'usd',
  style: 'currency',
});

export const Money: FC<Props> = ({ amount }) => {
  return <>{moneyFormatter.format(amount / 100)}</>;
};
