import { FC, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { ApiError, AuthApi, AuthResponse, SignUpRequest } from '../../api';
import { Configuration } from '../../config';
import { useAppDispatch, usePhoneUtil } from '../../hooks';
import { Role } from '../../model';
import { authSlice } from '../../redux';
import {
  Alert,
  Button,
  FacebookAuthButton,
  GoogleAuthButton,
  Heading,
  Input,
  InputPassword,
  Paragraph,
} from '../atoms';
import { PhoneField } from '../molecules';

interface Props {
  role: Role;
  withHeader?: boolean;
}

interface FormFields {
  country_iso_code: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
}

export const RegisterForm: FC<Props> = ({ role, withHeader = false }) => {
  const dispatch = useAppDispatch();
  const phoneUtil = usePhoneUtil();
  const form = useForm<FormFields>({
    defaultValues: {
      country_iso_code: 'CA',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
    },
    shouldUseNativeValidation: true,
  });
  const { handleSubmit, setError } = form;
  const { error, mutate: signUp } = useMutation<
    AuthResponse,
    ApiError<FormFields>,
    SignUpRequest
  >(AuthApi.signUp, {
    onSuccess(response) {
      ReactGA.event({
        action: 'signup',
        category: 'Auth',
      });
      dispatch(
        authSlice.actions.setCredentials({
          token: response.token,
        }),
      );
    },
    onError(error) {
      for (const [key] of error.getFieldsErrors()) {
        setError(key, { type: 'custom', message: error.getFieldError(key) });
      }
    },
  });

  const onSubmit = useCallback(
    (data: FormFields) => {
      const parsedPhone = phoneUtil.parse(
        data.phone,
        data.country_iso_code ?? Configuration.defaultCountryISOCode,
      );

      signUp({
        active_profile: role,
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        password: data.password,
        phone: parsedPhone
          ? String(parsedPhone.getNationalNumber())
          : undefined,
      });
    },
    [phoneUtil, role, signUp],
  );

  return (
    <FormProvider {...form}>
      <Alert>{error?.getNonFieldError()}</Alert>
      {withHeader && (
        <>
          <Heading className="mb-0.5 text-navy-100" level={3}>
            Create an account
          </Heading>
          <Paragraph className="mb-3.5 text-navy-60" size="S">
            Create an account to create your first order.
          </Paragraph>
        </>
      )}
      <form noValidate className="grid gap-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2 grid gap-2 md:grid-cols-2">
          <Input
            required
            label="First name"
            name="firstName"
            placeholder="First Name"
          />
          <Input
            required
            label="Last name"
            name="lastName"
            placeholder="Last Name"
          />
        </div>
        <PhoneField />
        <Input
          required
          autoComplete="username"
          label="Email"
          name="email"
          placeholder="Enter your email"
          type="email"
        />
        <InputPassword
          required
          autoComplete="new-password"
          helper="Must be at least 8 characters."
          label="Password"
          minLength={8}
          name="password"
        />
        <footer className="mt-0.5 grid gap-1">
          <Button block type="submit">
            Sign up
          </Button>
          <GoogleAuthButton>Sign up with Google</GoogleAuthButton>
          <FacebookAuthButton>Sign up with Facebook</FacebookAuthButton>
        </footer>
      </form>
    </FormProvider>
  );
};
