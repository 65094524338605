import { FC, PropsWithChildren } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { RoutePath } from '../../config';
import { useAppSelector } from '../../hooks';
import { selectIsAuthorized } from '../../redux';

export const AuthRoute: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const isAuthorized = useAppSelector(selectIsAuthorized);

  if (isAuthorized) {
    if (redirect !== null) {
      return <Navigate to={decodeURIComponent(redirect)} />;
    }

    return <Navigate to={RoutePath.Cuisines} />;
  }

  return <>{children}</>;
};
