import { ErrorResponse } from './ErrorResponse';

export class ApiError<T extends object> extends Error {
  #nonFieldErrors: string[] = [];
  #fieldsErrors: Map<keyof T, string[]> = new Map();

  constructor(response: ErrorResponse<T> = {}) {
    super('ApiError');

    for (const [key, value] of Object.entries(response)) {
      if (!Array.isArray(value)) {
        continue;
      }

      if (key === 'non_field_errors') {
        this.#nonFieldErrors = value;
      } else {
        this.#fieldsErrors.set(key as keyof T, value);
      }
    }
  }

  static is<K extends object>(value: unknown): value is ApiError<K> {
    return value instanceof ApiError;
  }

  hasNonFieldError(): boolean {
    return this.#nonFieldErrors.length > 0;
  }

  getNonFieldError(): string {
    return this.#nonFieldErrors.join(', ');
  }

  getFieldsErrors(): Map<keyof T, string[]> {
    return this.#fieldsErrors;
  }

  getFieldError(key: keyof T): string | undefined {
    return this.#fieldsErrors.get(key)?.join(', ');
  }
}
