import { FC, useCallback, useState } from 'react';
import { Heading, Paragraph } from '../atoms';
import { LoginForm, RegisterForm } from '../organisms';

export const MqlAuthStep: FC = () => {
  const [mode, setMode] = useState<'login' | 'register'>('register');

  const toggleMode = useCallback(
    () => setMode(current => (current === 'login' ? 'register' : 'login')),
    [],
  );

  return (
    <div>
      <Heading className="mb-0.5 md:mb-[12px] text-navy-100 text-center" level={5}>
        You’re almost there!
      </Heading>
      <Paragraph className="mb-3.5 text-navy-60 text-center" size="S">
        Create an account to complete your first order.
      </Paragraph>
      {mode === 'login' ? <LoginForm /> : <RegisterForm role="customer" />}
      <footer className="mt-2.5 pb-3.5">
        {mode === 'register' ? (
          <Paragraph className="text-center text-navy-100" size="S">
            Already have an account?&nbsp;
            <button className="link" onClick={toggleMode}>
              Login
            </button>
          </Paragraph>
        ) : (
          <Paragraph className="text-center text-navy-100" size="S">
            Don’t have an account?&nbsp;
            <button className="link" onClick={toggleMode}>
              Sign up
            </button>
          </Paragraph>
        )}
      </footer>
    </div>
  );
};
