import classNames from 'classnames';
import { createElement, FC, HTMLAttributes, PropsWithChildren } from 'react';
import styles from './Heading.module.css';

interface Props extends HTMLAttributes<HTMLHeadingElement> {
  level: 1 | 2 | 3 | 4 | 5 | 6;
}

export const Heading: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  level,
  ...rest
}) => {
  return createElement(`h${level}`, {
    className: classNames(styles.container, className),
    children,
    ...rest,
  });
};
