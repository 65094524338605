import { Down, Up } from '@icon-park/react';
import { FC, PropsWithChildren, ReactNode, useCallback, useState } from 'react';

interface Props {
  defaultOpen?: boolean;
  header: ReactNode;
}

export const Accordion: FC<PropsWithChildren<Props>> = ({
  children,
  defaultOpen = false,
  header,
}) => {
  const [open, setOpen] = useState(defaultOpen);

  const toggle = useCallback(() => setOpen(current => !current), []);

  return (
    <div className="grid gap-2">
      <header
        className="flex justify-between items-center cursor-pointer select-none"
        onClick={toggle}>
        <span className="block paragraph-m font-semibold text-navy-100">
          {header}
        </span>
        {open ? (
          <Up className="text-navy-50" size={32} />
        ) : (
          <Down className="text-navy-50" size={32} />
        )}
      </header>
      {open && <div className="paragraph-s text-navy-70">{children}</div>}
    </div>
  );
};