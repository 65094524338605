import { FC, useCallback, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { CuisinesApi } from '../api';
import {
  Button,
  Content,
  FoodCard,
  Heading,
  Pagination,
  Paragraph,
  Select,
  SelectOption,
} from '../components';
import { Configuration, RoutePath } from '../config';
import { useIsDesktop, usePagination } from '../hooks';
import { Layout } from '../layout';
import { FoodDietary, FoodType } from '../model';

const TYPES: SelectOption[] = [
  {
    value: undefined,
    label: 'All',
  },
  ...Configuration.foodTypes,
];

const DIETARIES: SelectOption[] = [
  {
    value: undefined,
    label: 'All',
  },
  ...Configuration.foodDietaries,
];

interface FormFields {
  dietary?: FoodDietary;
  type?: FoodType;
}

export const CuisineMenuPage: FC = () => {
  const form = useForm<FormFields>();
  const foodDietary = form.watch('dietary');
  const foodType = form.watch('type');
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const { id: cuisineId } = useParams<{ id: string }>();
  let { data: foodList = [] } = useQuery(
    ['cuisine', cuisineId, foodDietary, foodType],
    () => {
      return CuisinesApi.getCuisineMenu({
        cuisineId: cuisineId!,
        foodDietary,
        foodType,
      });
    },
    {
      enabled: Boolean(cuisineId),
    },
  );

  const pagination = usePagination(foodList.length);
  const { setPage } = pagination;
  const start = pagination.page * pagination.perPage;
  const end = start + pagination.perPage;

  useEffect(() => {
    ReactGA.event({
      action: 'view_cuisine',
      category: 'Food List',
      label: cuisineId,
    });
  }, [cuisineId]);

  useEffect(() => {
    setPage(0);
  }, [foodList, setPage]);

  const clearAllFilters = useCallback(() => form.reset(), [form]);

  return (
    <Layout inner>
      <Content className="py-4 md:py-6">
        <header className="mb-3 md:mb-4 lg:flex lg:justify-between lg:items-center lg:gap-1.5">
          <Heading className="mb-1.5 lg:mb-0" level={3}>
            <span className="capitalize">{cuisineId}</span>&nbsp;menu
          </Heading>
          <div className="flex flex-col lg:flex-row lg:justify-end gap-[12px]">
            <FormProvider {...form}>
              <div className="grid grid-cols-2 gap-[12px] lg:order-1">
                <Select
                  name="type"
                  options={TYPES}
                  placeholder="Filter by Type"
                />
                <Select
                  name="dietary"
                  options={DIETARIES}
                  placeholder="Filter by Dietary"
                />
              </div>
              <Button
                block={!isDesktop}
                onClick={clearAllFilters}
                theme="outlined">
                Clear all filters
              </Button>
            </FormProvider>
            <Button
              block={!isDesktop}
              className="lg:order-2"
              onClick={() => navigate(RoutePath.ShoppingCart)}>
              Complete order
            </Button>
          </div>
        </header>
        <ul className="grid md:grid-cols-2 xl:grid-cols-3 gap-3.5">
          {foodList.slice(start, end).map(food => (
            <FoodCard key={food.id} food={food} />
          ))}
        </ul>
        {foodList.length === 0 && (
          <Paragraph className="text-center text-navy-60" size="L">
            Nothing to show
          </Paragraph>
        )}
        <Pagination {...pagination} />
      </Content>
    </Layout>
  );
};
