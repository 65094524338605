import { FC, useCallback, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { orderSlice, selectOrder } from '../../redux';
import { Button, Textarea } from '../atoms';
import { Widget } from '../molecules';

interface FormFields {
  notes?: string;
}

export const NotesWidget: FC = () => {
  const [success, setSuccess] = useState(false);
  const order = useAppSelector(selectOrder);
  const dispatch = useAppDispatch();
  const form = useForm<FormFields>();

  const onSubmit: SubmitHandler<FormFields> = useCallback(
    data => {
      setSuccess(true);
      dispatch(orderSlice.actions.setNotes(data));
    },
    [dispatch],
  );

  if (success) {
    return (
      <Widget
        interactive
        onClick={() => setSuccess(false)}
        title="Notes / Allergies">
        <div className="grid grid-cols-2 gap-0.5">
          <p className="md:flex md:gap-2">
            <span className="block paragraph-s text-navy-60">Notes:</span>
            <span className="block paragraph-s text-navy-100">
              {order.notes || 'No notes'}
            </span>
          </p>
        </div>
      </Widget>
    );
  }

  return (
    <Widget active={order.addressFilled} title="Notes / Allergies">
      <FormProvider {...form}>
        <form className="grid gap-2.5" onSubmit={form.handleSubmit(onSubmit)}>
          <Textarea
            optional
            label="Leave notes to the chef"
            name="notes"
            placeholder="Enter notes to the chef"
          />
          <Button block type="submit">
            Continue to payment
          </Button>
        </form>
      </FormProvider>
    </Widget>
  );
};
