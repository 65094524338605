import { createSlice } from '@reduxjs/toolkit';

interface State {
  facebookLoginAppId: string;
  googleLoginClientId: string;
  referralBonus: number;
}

const initialState: State = {
  googleLoginClientId: String(process.env.REACT_APP_GOOGLE_CLIENT_ID),
  facebookLoginAppId: String(process.env.REACT_APP_FACEBOOK_LOGIN_APP_ID),
  referralBonus: 20,
};

export const configSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
});
