import type { FC } from 'react';
import { Accordion, Content, Heading, Paragraph } from '../components';
import { Layout } from '../layout';

export const Covid19Page: FC = () => {
  return (
    <Layout inner>
      <Content className="py-4 md:py-6 text-navy-100">
        <header className="mb-4">
          <Heading level={3}>Covid 19 Information</Heading>
        </header>
        <Paragraph className="mb-2" size="S">
          In just a few weeks, COVID-19 has transformed from something we were
          following on the news to a worldwide pandemic that has had a very
          significant impact on our day to day lives. We have actively been
          monitoring updates from the CDC, state and county to help us manage
          through this rapidly evolving situation.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          As many countries are still trying to contain the spread of the virus,
          it is inevitable that many customers are uncertain whether they should
          pursue their travel plans or not. At Getchefnow, we recommend our
          customers to check the recent updates issued by their respective
          countries, as well as the country they may be visiting before
          requesting a service.
        </Paragraph>
        <Paragraph className="mb-2" size="S">
          Considering the situation, we have expanded our cancellation policy to
          allow our customers to change their plans without being penalized,
          offering them different alternatives to select from. Furthermore, we
          have created specific guidelines for our chefs to adopt strict safety
          food practices in order to mitigate any potential risk in our
          services.
        </Paragraph>

        <Heading className="mt-3 mb-3" level={4}>
          1. Extended Cancellation policy
        </Heading>
        <div className="mb-2 paragraph-s">
          Considering the COVID-19 outbreak, we have revised our terms and
          conditions to include more flexibility with regards to our
          cancellation policy. More specifically, we have added a clause of
          Force Majeure, which would be triggered by the following causes:
          <ul className="mt-1 ml-4 list-disc">
            <li className="mb-[4px]">
              Travel bans and travel restrictions (Travelers to/from high risk
              destinations)
            </li>
            <li className="mb-[4px]">Border closures</li>
            <li className="mb-[4px]">
              Government service interruptions (Transit, public services,
              national park closures)
            </li>
            <li className="mb-[4px]">Flight cancellations</li>
          </ul>
        </div>
        <div className="mb-2 paragraph-s">
          Considering the above, our customers are able to choose among the
          following three options:
          <ul className="mt-1 ml-4 list-disc">
            <li className="mb-[4px]">
              Regular cancellation policy : 100% refund if requested at least 2
              hours before the scheduled service, 25% refund if the chef has
              already arrived at the place of service and at the scheduled time,
              0% refund if the scheduled time has passed. Please note that
              payment transaction fees (3%) are non-refundable.
            </li>
            <li className="mb-[4px]">
              Extended cancellation policy : receive a voucher for 100% of your
              reservation regardless of the number of days prior to the service.
            </li>
            <li className="mb-[4px]">
              Reschedule your reservation : rearrange your service with the same
              chef for a later point in time.
            </li>
          </ul>
        </div>
        <div className="mb-2 p-2 paragraph-s rounded-lg shadow-card bg-white">
          <Accordion header="Eligibility">
            COVID-19 was officially declared a pandemic on March 11th by the WHO
            after over 140 countries have confirmed cases in the recent days.
            Considering this, we choose to be sensitive to the concerns of any
            customer and/or chef that decides to change their plans, regardless
            of their location. IMPORTANT : Please note that this extended
            cancellation policy is applicable to all reservations made with
            Getchefnow, regardless of the booking date.
          </Accordion>
        </div>
        <div className="p-2 paragraph-s rounded-lg shadow-card bg-white">
          <Accordion header="How to redeem your voucher">
            <p className="mb-2">
              Should you decide to postpone your reservation and make use of our
              extended cancellation policy to obtain a voucher, our team will
              send you the corresponding document via email. Your voucher will
              include a reference number, the amount included and its expiration
              date.
            </p>

            <p className="mb-2">
              To redeem the voucher, kindly reach out to our 24/7 support team
              at{' '}
              <a className="link" href="mailto:support@getchefnow.com">
                support@getchefnow.com
              </a>{' '}
              , and one of our agents will gladly assist you throughout the
              process. Please keep in mind that:
            </p>
            <p>
              You have a 2-year period to redeem the voucher after the issue
              date. You can divide the voucher credit into as many reservations
              as you wish. You can cover any excess in case the future
              reservation exceeds the voucher credit.
            </p>
          </Accordion>
        </div>

        <Heading className="mt-3 mb-3" level={4}>
          2. Guidelines for Chef
        </Heading>
        <Paragraph className="mb-2" size="S">
          Due to the current situation caused by the COVID-19 outbreak, we have
          developed the following protocols in order to advise and also minimize
          any possible risks of contagion to our chefs and customers:
        </Paragraph>
        <div className="mb-2 p-2 paragraph-s rounded-lg shadow-card bg-white">
          <Accordion header="Covid-19 and food : The Connection">
            <p className="mb-2">
              Food safety concerns are at the centre of the pandemic. The source
              of COVID-19 is believed to have started at a “wet market” in
              Wuhan, China. Wet markets pose an increased threat of animal
              disease passing from animals to humans, as it’s difficult to
              maintain adequate hygiene standards with animals being slaughtered
              on site.
            </p>

            <p>
              But based on previous outbreaks of similar diseases, experts from
              the European Food Safety Authority claim that there is no evidence
              that food is a source of transmission. The disease is mainly
              spread from person to person through direct contact with an
              infected person's body fluids (for example, droplets from coughing
              or sneezing). Thus, the implications for the food industry mainly
              relate to reduced staff due to sickness throughout the supply
              chain, as well as hygiene, communication on site or at point of
              delivery.
            </p>
          </Accordion>
        </div>
        <div className="mb-2 p-2 paragraph-s rounded-lg shadow-card bg-white">
          <Accordion header="Signs and Symptoms of Covid-19">
            According to the World Health Organization (WHO), the symptoms of
            COVID-19 are fever, fatigue and dry cough. Some patients may
            experience aches, nasal congestion, runny nose, sore throat, or
            diarrhea. These symptoms are usually mild and appear gradually. Some
            people become infected but do not develop any symptoms. However, in
            case you have any visible symptoms, please seek medical attention as
            soon as possible to avoid any further development or spread of the
            virus. Keep in mind that symptoms may appear 2-14 days after
            exposure.
          </Accordion>
        </div>
        <div className="mb-2 p-2 paragraph-s rounded-lg shadow-card bg-white">
          <Accordion header="How does the virus spread?">
            The virus is spread from person-to-person through close contact.
            Some examples of how it can be spread include:
            <ul className="mb-2 mt-1 ml-4 list-disc">
              <li className="mb-[4px]">
                Coughing and/or sneezing by an infected person within a short
                distance (usually 2m or less) of someone.
              </li>
              <li className="mb-[4px]">
                Touching or shaking the hand of an infected person and then
                touching your mouth, eyes or nose without first washing your
                hands.
              </li>
              <li className="mb-[4px]">
                Touching surfaces or objects (eg. door handles) that have been
                exposed to the virus and then touching your mouth, eyes or nose
                without first washing your hands.
              </li>
            </ul>
            <span className="block">
              Data provided by{' '}
              <a
                className="link"
                href="https://www.who.int/"
                target="_blank"
                rel="noreferrer">
                WHO
              </a>
            </span>
          </Accordion>
        </div>
        <div className="mb-2 p-2 paragraph-s rounded-lg shadow-card bg-white">
          <Accordion header="Revised Service Protocols">
            <p className="mb-2">
              Precautions taken by our Chefs We strictly recommend that our
              Chefs adopt safety food practices as well as proper hand hygiene
              to reduce exposure and transmission of a range of illnesses. Below
              you can find obligatory measures and suggested precautions on food
              handling during a service.
            </p>
            <p className="mb-2">
              Mandatory:
              <ul className="mt-1 ml-4 list-disc">
                <li className="mb-[4px]">
                  Frequent washing of hands in between handling raw and cooked
                  foods. Good hygiene and sanitation are important to avoid
                  cross contamination between different types of food.
                </li>
                <li className="mb-[4px]">
                  Thorough cooking and proper handling of raw meat products.
                  Since viruses are very sensitive to heat, cooking food to the
                  proper temperature is essential.
                </li>
                <li className="mb-[4px]">
                  Surfaces and objects, including glasses, plates, bowls,
                  cutlery or any material used to perform the service are to be
                  cleaned thoroughly and as frequently as possible.
                </li>
                <li className="mb-[4px]">
                  Use of gloves and a face mask while performing a service. Note
                  that you must toss and replace the mask afterwards.
                </li>
                <li className="mb-[4px]">
                  Avoid close and direct contact with guests as much as
                  possible. Keep distance of at least one meter.
                </li>
                <li className="mb-[4px]">
                  The dishes are to be set on the table before the service
                  begins (specifically before the guests are seated).
                </li>
              </ul>
            </p>
            <p>
              Recommendations:
              <ul className="mt-1 ml-4 list-disc">
                <li className="mb-[4px]">
                  Frequently wash your hands or use alcohol/gel products to
                  disinfect your hands.
                </li>
                <li className="mb-[4px]">
                  Washing the fresh produce as thoroughly as possible. Although
                  there is no evidence that viruses could survive on the surface
                  of food, doing this precautionary measure helps remove
                  potentially harmful microorganisms.
                </li>
                <li className="mb-[4px]">
                  Purchase the groceries online and have them delivered to you.
                </li>
                <li className="mb-[4px]">
                  If you have a service coming up, please avoid big gatherings
                  and agglomerations before it takes place.
                </li>
              </ul>
            </p>
          </Accordion>
        </div>

        <Heading className="mt-3 mb-3" level={4}>
          Message to our Chefs
        </Heading>
        <div className="mb-2 paragraph-s">
          We are going through an unpredictable situation with severe, global
          implications. Precautions are being taken as well as contingency plans
          to give all the necessary support to our chefs. In case you need any
          further assistance from us, our entire team is available for you 24/7.
          Please,
          <ul className="mt-1 ml-4 list-disc">
            <li className="mb-[4px]">Stay Healthy</li>
            <li className="mb-[4px]">Stay Informed</li>
            <li className="mb-[4px]">Stay close to your loved ones</li>
          </ul>
        </div>
        <Paragraph size="S">
          The travel and hospitality industries have never faced times like
          these. We remain available for you should you desire to raise your
          concerns or seek any kind of assistance. We are confident that this
          challenging situation will be over soon and that we will continue to
          bring joy to our guests’ tables in the near future.
        </Paragraph>
      </Content>
    </Layout>
  );
};
