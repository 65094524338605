import { useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { OrdersApi } from '../api';

export function useOrder(id?: string | number) {
  const queryClient = useQueryClient();
  const { data: order } = useQuery(['orders', id], () => OrdersApi.get(id!), {
    enabled: Boolean(id),
  });

  const { mutate: deleteOrderMutation } = useMutation(OrdersApi.delete, {
    onSuccess() {
      ReactGA.event({
        action: 'cancel_order',
        category: 'Orders',
        value: order?.id,
      });
      queryClient.invalidateQueries('orders');
    },
  });
  const { mutate: completeOrderMutation } = useMutation(OrdersApi.complete, {
    onSuccess() {
      ReactGA.event({
        action: 'complete_order',
        category: 'Orders',
        value: order?.id,
      });
      queryClient.invalidateQueries('orders');
    },
  });

  const completeOrder = useCallback(
    () => (order ? completeOrderMutation(order.id) : undefined),
    [completeOrderMutation, order],
  );

  const deleteOrder = useCallback(
    () => (order ? deleteOrderMutation(order.id) : undefined),
    [deleteOrderMutation, order],
  );

  return {
    completeOrder,
    deleteOrder,
    order,
  };
}
