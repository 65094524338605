import { Left, Right } from '@icon-park/react';
import classNames from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Food } from '../../model';
import { AddFoodToFavorites, Paragraph } from '../atoms';
import { AddFoodToCartWidget, FoodCategories } from '../molecules';
import styles from './FoodDetails.module.css';

interface Props {
  food: Food;
  readonly?: boolean;
}

export const FoodDetails: FC<Props> = ({ food, readonly = false }) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const slideNext = useCallback(() => swiper?.slideNext(), [swiper]);
  const slidePrev = useCallback(() => swiper?.slidePrev(), [swiper]);

  useEffect(() => {
    ReactGA.event({
      action: 'view_item',
      category: 'Food List',
      label: food.name,
      value: food.id,
    });
  }, [food]);

  return (
    <>
      {food.additional_images.length > 0 ? (
        <div className="relative">
          <Swiper onSwiper={setSwiper} slidesPerView={1} spaceBetween={0}>
            <SwiperSlide>
              <img
                alt={food.name}
                className="block object-cover w-full h-[168px] md:h-[296px]"
                src={food.image_url}
              />
            </SwiperSlide>
            {food.additional_images.map(additionalImage => (
              <SwiperSlide key={additionalImage.id}>
                <img
                  alt={food.name}
                  className="block object-cover w-full h-[168px] md:h-[296px]"
                  src={additionalImage.image_url}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <button
            className={classNames(styles.control, styles.back)}
            onClick={slidePrev}
            type="button">
            <Left className="text-navy-40" size={24} />
          </button>
          <button
            className={classNames(styles.control, styles.forward)}
            onClick={slideNext}
            type="button">
            <Right className="text-navy-40" size={24} />
          </button>
        </div>
      ) : (
        <img
          alt={food.name}
          className="block rounded-t-lg object-cover w-full h-[168px] md:h-[296px]"
          src={food.image_url}
        />
      )}
      <div className="p-2 bg-white rounded-lg">
        <header className="mb-1.5">
          <div className="mb-0.5 flex items-center justify-between">
            <Paragraph semiBold size="M">
              {food.name}
            </Paragraph>
            <AddFoodToFavorites food={food} />
          </div>
          <FoodCategories categories={food.food_categories} />
        </header>

        <p className="mb-1.5 text-navy-70">{food.description}</p>

        {food.v2_ingredients.length > 0 && (
          <>
            <Paragraph semiBold className="mb-0.5 text-navy-100" size="S">
              Ingredients
            </Paragraph>
            <p className="mb-2.5 text-navy-70">
              {food.v2_ingredients
                .map(ingredient => ingredient.name)
                .join(', ')}
            </p>
          </>
        )}
        {!readonly && <AddFoodToCartWidget food={food} />}
      </div>
    </>
  );
};
