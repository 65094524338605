import { Like } from '@icon-park/react';
import type { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { FoodApi } from '../../api';
import { RoutePath } from '../../config';
import { useProfile } from '../../hooks';
import { Food } from '../../model';

interface Props {
  food: Food;
}

export const AddFoodToFavorites: FC<Props> = ({ food }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { profile } = useProfile();
  const { mutate: addToFavorite } = useMutation(
    (userId: number) => FoodApi.addToFavorite(userId, food.id),
    {
      onSuccess() {
        queryClient.invalidateQueries('me');
      },
    },
  );
  const { mutate: removeFromFavorite } = useMutation(
    (userId: number) => FoodApi.removeFromFavorite(userId, food.id),
    {
      onSuccess() {
        queryClient.invalidateQueries('me');
      },
    },
  );

  return (
    <span className="cursor-pointer">
      {!!profile && profile.favorite_foods.includes(food.id) ? (
        <Like
          className="text-red-100"
          onClick={() => removeFromFavorite(profile.id)}
          size={20}
          theme="filled"
          title={`Remove ${food.name} from favorites`}
        />
      ) : (
        <Like
          className="text-navy-50"
          onClick={() => {
            if (profile) {
              addToFavorite(profile.id);
            } else {
              navigate(RoutePath.Login);
            }
          }}
          size={20}
          title={`Add ${food.name} to favorites`}
        />
      )}
    </span>
  );
};
