// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Type } from 'class-transformer';

export class Referral {
  @Type(() => Date)
  readonly created_at: Date = new Date();

  readonly email!: string;
  readonly id!: number;
  readonly referring_user!: number;
  readonly status!: string;
}
