import type { FC } from 'react';
import { Content, Heading, Paragraph } from '../components';
import { Layout } from '../layout';

export const PrivacyPage: FC = () => {
  return (
    <Layout inner>
      <Content className="py-4 md:py-6 text-navy-100">
        <header className="mb-4">
          <Heading className="mb-2" level={3}>
            ChefNow Inc. (the “Company”) Privacy Policy
          </Heading>
          <Paragraph size="S">
            <ol className="mt-1 ml-4 list-decimal">
              <li className="mb-[4px]">
                <a className="link" href="#1">
                  Introduction and Consent
                </a>
              </li>

              <li className="mb-[4px]">
                <a className="link" href="#2">
                  Data Collection
                </a>
              </li>
              <li className="mb-[4px]">
                <a className="link" href="#3">
                  Data Management
                </a>
              </li>
              <li className="mb-[4px]">
                <a className="link" href="#4">
                  Data Usage
                </a>
              </li>
              <li className="mb-[4px]">
                <a className="link" href="#5">
                  List of Third Party Processors And End-User Data Storage
                  Providers
                </a>
              </li>
            </ol>
          </Paragraph>
        </header>

        <Heading className="mt-3 mb-3" id="1" level={4}>
          1. INTRODUCTION TO PRIVACY POLICY
        </Heading>
        <Paragraph size="S">
          The Company is committed to maintaining the confidentiality, integrity
          and security of any Personal Information (as defined below) about
          Company's End-Users. To demonstrate Company's commitment to protecting
          Your privacy, Company has developed this privacy policy (“Privacy
          Policy”), which describes how Company will collect, use, disclose and
          protect Your Personal Information through the Product. (a) LICENSE
          AGREEMENT AND END USER LICENCE AGREEMENT This Privacy Policy as well
          as Company's Product License Agreement and End User License Agreement
          (“License Agreements”) govern Your access and use of the Product, as
          owned and operated by the Company, a Canadian corporation having its
          registered address at 12 Rockford Road, Apt 309, Toronto, Ontario M2R
          3A2 (referred to in these Terms as the “Company”). Terms capitalized
          but not defined in this Privacy Policy have the meanings set out in
          the License Agreement. “You”, “Your” and “Yours” refers to you, the
          End-User, or if You are a company that is registering for user
          accounts on behalf of Your employees or contractors, the Client, as
          the case may be, and as defined in the License Agreement. (b) CONSENT
          AND AGREEMENT TO BE BOUND (i) CONSENT PROVIDED BY CONTINUING USE. By
          accessing and/or using the Product You agree to all the terms and
          conditions of this Privacy Policy and the License Agreement and which
          are incorporated here by reference. If You do not agree to all the
          terms and conditions of this Privacy Policy and the License Agreement,
          please do not use the Product. (ii) YOU MAY ALSO HAVE PROVIDED CONSENT
          THROUGH THE GOOGLE PLAY AND APPLE STORES (“App Store”). There are
          certain types of device data that the Product cannot access without
          Your consent. The various application marketplace platforms that
          Company serves the Product through will notify You the first time the
          Product requires permission to access certain types of data and will
          let You decide to consent to that request. You further agree to abide
          by all the terms in the App Store end user licensing agreement, or any
          other applicable App Store agreement, so long as such agreement does
          not conflict with the terms herein or the License Agreement. (iii)
          CHANGES WILL REQUIRE YOUR CONSENT. In the case of a material change to
          the Product as described in the amendment provision set out in the
          License Agreements, and in accordance with the amendment requirements
          set out therein, Company will provide written notice to inform You and
          will obtain consent from You for any new purposes not previously
          identified. (iv) PROVIDING CHANGES TO YOUR CONSENT. Changes can be
          submitted by updating Your data in accordance with the user data
          update and verification provisions set out in the section of this
          Privacy Policy entitled [“3. Data Management”. (NTD: INSERT
          HYPERLINK)] (c) CONSENT TO COLLECTION AND ANALYSIS OF THE INFORMATION
          YOU PROVIDE TO US (i) SPECIFIC CONSENT TO COLLECTION OF INFORMATION.
          By using the Product, You consent to the collection, use and
          disclosure of Your Personal Information by Company in the manner
          described in this Privacy Policy. You may always opt not to disclose
          certain Personal Information, but which may restrict access to certain
          features of the Product. For example, Your name and email address are
          necessary to complete the registration process. At any time after
          registration, You may opt out of most email communication from Company
          by clicking on the opt-out link at the bottom of Company's emails, or
          by contacting Company at the contact details listed above. However,
          Company may still contact You for administrative purposes. Withdrawing
          consent will not apply to actions the Company has already taken based
          on Your prior consent. (ii) CONSENT TO PROCESS THIRD PARTY DATA YOU
          SEND TO US IS YOUR RESPONSIBILITY. Any data sent to Company for
          processing by You is considered to be third party data (“Third Party
          Data”). For all Third Party Data, consent required upon collection of
          third party data shall be obtained by the You (“Third Party Data
          Consent”). (iii) CONSENT TO RECEIVING COMMUNICATIONS FROM US: When You
          sign up for an account, You are opting in to receive emails from the
          Product for administrative or technical issues and You may
          occasionally receive the Company newsletters. a. COMMUNICATIONS IN THE
          EVENT OF BREACH: In the unlikely event that Company believes that the
          security of Your Personal Information in Company's possession or
          control may have been compromised and creates a real risk of
          significant harm to You, or if Company believes that a notification is
          appropriate, Company may seek to notify You of that development,
          pursuant to both Company's desire to keep You informed and Company's
          legal requirement to do so. If a notification is appropriate, Company
          may notify You by the email address registered to Your account.
          Additional details on a Data Breach can be found in the in the section
          of this Privacy Policy entitled “3. Data Management”. b. WE WILL NOT
          REQUEST CONFIDENTIAL PERSONAL INFORMATION: Company will never send
          email messages to customers requesting confidential information such
          as passwords, credit card numbers, or social security or social
          insurance numbers. Please do not act on any such emails as You may
          compromise Your Personal Information by replying or by following links
          to a fraudulent website. (d) AMENDMENTS TO THIS PRIVACY POLICY AND
          VALIDATION TO CONFIRM COMPLIANCE WITH LAW. The Company may amend or
          change this Privacy Policy at its sole discretion at any time, and in
          accordance with the amendment provisions set out in the License
          Agreement. The use of the information Company collects at any given
          point is subject to the Privacy Policy in effect at the time of
          collection. If Company makes any material changes Company will notify
          You by email or by means of notice on the Product prior to the change
          becoming effective. Company will post the most current Privacy Policy
          on the Product, and Your use of the Product is subject to the most
          current Privacy Policy as posted on the Product at any time. (i)
          Company's PERIODIC REVIEW. Company will perform a periodic and timely
          review to ensure that Company's Privacy Policy is compliant with
          Applicable Laws. (ii) YOUR PERIODIC REVIEW. Company encourages You to
          periodically check Company’s Privacy Policy for the latest information
          on Company's current policy. (e) DISCLAIMER IF YOU CHOOSE TO ACCESS
          THE PRODUCT, YOU DO SO AT YOUR OWN RISK, AND ARE RESPONSIBLE FOR
          COMPLYING WITH ALL LOCAL LAWS, RULES AND REGULATIONS. WE MAY LIMIT THE
          AVAILABILITY OF THE PRODUCT, IN WHOLE OR IN PART, TO ANY PERSON,
          GEOGRAPHIC AREA AND/OR JURISDICTION WE CHOOSE, AT ANY TIME AND IN
          COMPANY'S SOLE DISCRETION. COMPANY'S PRIVACY POLICY DOES NOT COVER THE
          INFORMATION PRACTICES OF OTHER COMPANIES AND ORGANIZATIONS WHO
          ADVERTISE COMPANY'S SERVICES, AND WHO MAY USE COOKIES (DEFINED BELOW)
          AND OTHER TECHNOLOGIES TO SERVE AND OFFER RELEVANT ADVERTISEMENTS. SEE
          COMPLETE [LIMITATION OF LIABILITY PROVISION AND DISCLAIMER (NTD:
          INSERT HYPERLINK)], AND [PROHIBITED USE REQUIREMENTS CONTAINED IN THE
          PRODUCT LICENSE (NTD: INSERT HYPERLINK)]. (f) MISCELLANEOUS If any
          portion of this Privacy Policy is deemed unlawful, void or
          unenforceable by any arbitrator or court of competent jurisdiction,
          this Privacy Policy as a whole shall not be deemed unlawful, void or
          unenforceable, but only that portion of this Privacy Policy that is
          unlawful, void or unenforceable shall be stricken from this Privacy
          Policy. The insertions of headings are for convenient reference only
          and are not to affect the interpretation of this Privacy Policy. (g)
          CONTACT INFORMATION If You have questions or concerns regarding
          Company's policy or practices, please contact Company's privacy
          officer by email at support@getchefnow.com or at the following
          address: 12 Rockford Road, Apt 309 Toronto Ontario M2R 3A2 (h)
          EFFECTIVE DATE. This Privacy Policy is effective as of the Effective
          Data as defined in the License Agreements.
        </Paragraph>

        <Heading className="mt-3 mb-3" id="2" level={4}>
          2. COLLECTION OF USER INFORMATION INCLUDING PERSONAL INFORMATION
        </Heading>

        <Paragraph size="S">
          (a) DISCLOSURE OF COLLECTION Within this section of the Privacy
          Policy, Company will provide You with notice that Your information is
          being collected when You first sign in to the Product. In the section
          of this Privacy Policy entitled “3. Data Management”, Company will be
          describing the manner in the section of this Privacy Policy entitled
          “4. Data Usage”, You will also be notified about the nature for which
          the data will be used, how Company processes the data, and how Company
          works with third party service providers who will assist Company to
          process the data. (b) COLLECTION OF PERSONAL INFORMATION When You use
          the Product, Company stores certain information about Your device and
          Your activities that You provide to Company and that Company
          automatically collects, including: (i) REGISTRATION INFORMATION: Your
          user registration information which includes the following personal
          information (“Personal Information”): first and last name, gender,
          email address, telephone number, address, and date of birth; (ii)
          TECHNICAL INFORMATION: technical information about Your device such as
          the type of device, OS version, location, other browser information
          (e.g., size, connection speed and connection type; (iii) USER
          PREFERENCES COLLECTED AUTOMATICALLY: Your User Preferences which
          Company will collect and determine automatically through Cookies and
          traffic data as described below; (iv) USER PREFERENCES SUPPLIED BY
          YOU: Your user experience preferences and settings (time zone,
          language, etc.), as well as content and usage preferences
          (collectively, the “User Preferences”); and (v) CONTENT SUPPLIED BY
          YOU: Company collects content that You upload, post, and/or share to
          Company's Product which includes Company's Social Media Services. (c)
          METHODS OF COLLECTION We may collect electronic information from You
          from the following sources: (i) COLLECTION OF INFORMATION AT
          REGISTRATION. Registration is required if You want to use the Product.
          You may need to register twice, once to the App Store and a second
          time after You have downloaded the Product from the App Store, using
          Your electronic device. As part of this registration, Company will
          require that You submit certain information that is relevant to the
          purposes of the Product. (ii) COLLECTION THROUGH SOCIAL MEDIA: If You
          are logged into social media websites or applications (such as
          Facebook, Instagram, Twitter, among others, and individually and
          collectively, “Social Media Services”) on pages and/or locations that
          are related to Company's Product, Company may receive information from
          such Social Media, in which case Company may collect and store
          information identifying Your account with the Social Media Services;
          (iii) COLLECTED THROUGH Company's COMMUNICATIONS WITH YOU: via email
          or through the Product, through messages or transaction information
          relating to Your use of the Product, through other End-User-generated
          content provided to Company in the normal course of Your use of the
          Product, including but not limited to communications related to
          registration, evaluations, internal surveys, feedback information,
          usage information, correspondence with Company through technical
          support tools and/or email, by and Traffic Data (as described within
          this Privacy Policy); (iv) COLLECTED AUTOMATICALLY THROUGH ANALYTICS
          TOOLS: Company may collect and store information (including Personal
          Information) locally on Your device using mechanisms such as Product
          data caches, “Cookies” (cookies, pixel tags or other similar
          technologies which are small data files that are stored on an
          End-User's device for record-keeping purposes that track where You
          travel on the Product and what You look at, on single sessions or
          cumulated over time. Although Cookies are used by most major Products
          and are accepted by default by most Products, it may be possible to
          disable Cookies via Your settings), and through "traffic data" which
          collects the, route and destination of users and information on and
          through Company's Product, as well as cookies that are stored
          temporarily on Your device. (d) PROCESSING OF COLLECTED INFORMATION In
          the section of this Privacy Policy entitled [“4. Data Usage” (NTD:
          INSERT HYPERLINK)], You will also be notified about the nature for
          which the data will be used, how Company processes the data, and how
          Company works with third party service providers who will assist
          Company to process the data.
        </Paragraph>

        <Heading className="mt-3 mb-3" id="3" level={4}>
          3. DATA MANAGEMENT
        </Heading>
        <Paragraph size="S">
          (a) VALIDATION AND CHANGES TO OF Company's END USER-INFORMATION (i)
          VALIDATION: Company will validate the Personal Information to the best
          of its ability. Company will validate Personal Information wherever
          possible and any discrepancies discovered shall be corrected. (ii)
          CLIENTS COLLECTING INFORMATION ON BEHALF OF THEIR END- USERS. In the
          case that the End-User Personal Information is provided to Company by
          one of Company's clients Company will accept that database as verified
          and accurate. If Company is collecting the data on behalf of Company's
          client, Company will work with the Client to ensure that processes
          will be put in place to ensure that end users are given the chance to
          review and correct any data issues. (iii) REVIEW OF INFORMATION AND
          INDIVIDUAL ACCESS. The Company relies on You to ensure that the
          Personal Information You enter into Company's system is as accurate,
          complete and up-to-date as necessary for the purposes for which it is
          to be used. Until the Personal Information Removal Date (as defined
          below), You may review or update Your Personal Information by
          submitting a request to review or update Your Personal Information to
          support@getchefnow.com, indicating that You are requesting such review
          or update, subject to the identity verification process set out below,
          and with the understanding that the Company may make changes to Your
          Personal Information to meet the technological requirements of
          Company's networks and media. Unless required to comply by law,
          Company may reject access or modification requests that are
          unreasonably repetitive, require disproportionate technical effort,
          risk the privacy of others, or would be extremely impractical. Where
          Company can provide information access and correction, and when
          required by law, Company will do so for free. (iv) REMOVAL OF YOUR
          PERSONAL INFORMATION BY US OR BY YOU: At any time and up to the twelve
          (12 th ) day after Your License Agreements with Company have been
          terminated or the maximum time period allowed by Applicable Law as
          described below, whichever is longer (this is the “Personal
          Information Removal Date”) the End User may request a copy of all of
          the End- User’s User Data from the Product. After the Personal
          Information Removal Date, or upon Your specific request to
          support@getchefnow.com to delete the Personal Information, such
          Personal Information shall be deleted by Company within a reasonable
          period, unless: (a) INFORMATION MAY BE RETAINED UNTIL A SYSTEM-WIDE
          BACKUP IS PURGED: such data may continue to temporarily persist in
          Company's system-wide business recovery back-ups (if any) until such
          time as the system-wide business recovery backup is deleted and
          replaced with data that does not include data collected during Your
          agreement term; however, You have no expectation of data retention
          whatsoever and acknowledge that backing up of Your own data is Your
          responsibility; or (b) INFORMATION MAY BE RETAINED IF REQUIRED TO
          COMPLY WITH LAW: such data may continue to temporarily persist to the
          extent that such information is required to be retained for compliance
          with Applicable Law (for example, to prevent, investigate, or identify
          possible wrongdoing in connection with the Product or to comply with
          legal obligations) and until such time as such information is no
          longer required for this purpose, however, You acknowledge that
          recovery of data is not permitted by You from within this system under
          these circumstances unless Company is required and compelled to do so
          by law, and in such event, at Your sole expense. (v) CHANGE REQUESTS
          MAY REQUIRE IDENTITY VERIFICATION ON YOUR PART: When updating Your
          Personal Information, Company may ask You to verify Your identity
          before Company can act on Your request. (vi) TRACKING YOUR
          PREFERENCES. Company will capture and manage all End-User and Client
          privacy preferences. There preferences will be tracked in the database
          and attached to Your End-User records. If the preferences are changes,
          the modifications will be incremental, and added to an audit log.
          Tracking of Your consent to the collection, storage and use of Your
          Personal Information will be also be recorded for the purposes of an
          audit log for consent. To ensure that the data is traceable, the
          source of the data will be logged, as well as a timestamp for the
          transaction. (b) STORAGE AND RETENTION (i) STORAGE LOCATION. If Your
          information is stored on computer systems in a country other than the
          country in which Your information was collected. In Company's best
          efforts to protect Your data, storage locations will be selected in
          countries that have similar privacy laws. Foreign storage locations,
          if any, that may process or store Your data, will be listed and
          updated within Section 5 of this Privacy Policy, entitled “LIST OF
          THIRD PARTY PROVIDERS AND END-USER DATA STORAGE PROVIDERS”. Any such
          transfers will also be subject to audit and tracking requirements set
          forth in this Privacy Policy. (ii) DATA RETENTION: a. OF NON-PERSONAL
          INFORMATION: Data that is non-Personal Information may be kept by
          Company for an indefinite period however, this does not constitute a
          guarantee that Company will keep the data indefinitely. If a User or
          Client would like to ensure that data is indefinitely kept, that can
          be requested, upon written agreement of the parties of a custom
          services plan. This data will primarily be used in aggregate and
          anonymized format to drive business intelligence and analytics. b. OF
          PERSONAL INFORMATION: Personal Information data will be kept until the
          Personal Information Removal Date (as defined above), with such
          deletion to be initiated by Company or by the User, in the manner
          described above in the section entitled “Removal of Personal
          Information By Company or by You.” c. DATA RECOVERY BY YOU: Other than
          information that Company is required to retain and provide to You by
          law, the Company runs a periodic backup of End User’s User Data and
          may store the End-User’s User Data as long as the End-User’s account
          is current and active until expiry of the Data Retention Period. DATA
          RESTORES: The Company will not restore data unless it is available and
          then only if the Company determines, in its sole discretion that a
          data recovery is necessary. d. PERIODIC AUDIT. Company will perform
          routine audits at its sole discretion or on a schedule as required by
          Applicable Law to confirm deletion of the data has occurred in the
          manner described above in the section entitled “Removal of Personal
          Information By Company or By You.” (c) SECURITY MEASURES: Company
          takes Your privacy very seriously. If You have a security related
          concern, please contact Company at the contact details provided above.
          Company will work closely with You to ensure a quick and personal
          response to Your concerns. In addition, Company restricts unauthorized
          access through protective policies, procedures, and technical
          measures, including: a. SAFEGUARDS PROVIDED BY YOU: To keep Your
          Personal Information secure, You are required to safeguard Your
          End-User name and password information in accordance with the License
          Agreement. b. SAFEGUARDS PROVIDED BY US: Company will provide physical
          and electronic safeguards with regard to the storage of Personal
          Information as required by law, however, and pursuant to disclaimer
          provided in the License Agreements, You understand that in order for
          the Company to operate the Product, End-User Data may be transmitted
          by You to the Company over the internet, public networks or otherwise,
          and You acknowledge that that no such data transmission can be
          guaranteed to be completely secure, and that, beyond Company's
          requirements to provide a warranty on information security as required
          by law or in accordance with the security protocols agreed to by the
          parties hereto in writing, Company cannot warrant the security of any
          information You transmit to us, and that You do so at Your own risk.
          c. ACTIONS IN THE EVENT OF DATA BREACH. A “Data Breach” is defined as
          any non-authorized access to the storage locations of the data, or
          access to a storage location by an individual that is potentially
          suspected of having performed non-authorized activities. In the case
          where a Data Breach has occurred, if the Company believes that the
          breach creates a real risk of significant harm to the end-users, the
          End- User and Client will be notified in the manner as required by
          law, and all details regarding the impact to the End-User and Client
          will be shared. (d) TRAINING Company's STAFF IN DATA MANAGEMENT: a.
          TRAINING OF Company's STAFF FOR HANDLING PERSONAL INFORMATION:
          Company's employees and contractors are required to adhere to
          standards and policies to ensure that Personal Information is secure
          and treated with the utmost care and respect. Furthermore, Company
          limits access to Your Personal Information to those employees or
          contractors who Company reasonably believe need to come into contact
          with that information in order to do their jobs and Personal
          Information will only be reviewed if anonymize of otherwise accessed
          on a “need-to-know” basis. b. EMPLOYEE COMPLIANCE. All employees must
          read, and attest to having read this Privacy Policy. Furthermore, as
          this Privacy Policy is evergreen, any time material changes are made
          to the document, employees will need to attest to having read and
          understand the changes to the document. From time to time, training
          shall be provided to employees on this Policy Privacy and privacy
          issues required to be compliant with the applicable law.
        </Paragraph>

        <Heading className="mt-3 mb-3" id="4" level={4}>
          4. DATA USAGE SCHEDULE TO THE PRODUCT LICENSE AGREEMENT
        </Heading>

        <Paragraph size="S">
          (a) USE AND DISCLOSURE OF PERSONAL INFORMATION. Company will not use
          or disclose Personal Information other than the purposes identified
          below (individually and collectively, the “Purpose”): (i) TO
          COMMUNICATE WITH YOU AND TO PROVIDE CUSTOMER SERVICE: To Provide
          Customer Service and support, administrative messages, updates, and
          security alerts, to resolve disputes, and to troubleshoot problems;
          (ii) TO IMPROVE Company's PRODUCT: To fulfill Your requests or
          Company's product roadmap for certain features of the Product, to
          customize, measure, and improve the Product including by analyzing
          trends, tracking user movements on the Product, gathering demographic
          statistics about Company's user base as a whole, and to assist Company
          to measure Company’s performance and effectiveness of Company’s
          content, and to share Company's performance information with others;
          (iii) TO IMPROVE Company's CONTENT: Company may post Your social media
          content, testimonials, and other information provided by you; (iv) TO
          FULFIL Company's BUSINESS GOALS: to directly or indirectly offer or
          provide You with products and services that are based on Company's
          analysis of Your needs as determined by Company's analytics and the
          analytics of Company's third-party processors, unless You opt out; (v)
          TO ENABLE Company's COLLABORATORS TO FULFIL THEIR Company's BUSINESS
          GOALS: Where a third party to this Privacy Policy directly or
          indirectly provides the Company with the ability to provide the
          Product to You, Company may supply Personal Information to such Third
          Party in exchange for fulfilling Company's Purpose and providing
          corresponding value to the third party, and such third parties are
          listed in Section 5 of this Privacy Policy entitled “List of Third
          Party Processors And End-User Data Storage Providers“; (vi) IN THE
          EVENT OF AN ACQUISITION OF Company's COMPANY. In the event that the
          Company, or all or a portion of Company's business, or one or more of
          its divisions, is acquired by one or more third parties as a result of
          an acquisition, merger, sale, reorganization, consolidation,
          liquidation or another similar transaction, Your Personal Information
          shall be one of the transferred assets. To the extent that Company is
          required to do so by law, You will be notified of any changes in
          ownership or uses of Your Personal Information. (vii) TO ENABLE
          Company's PARENT COMPANY OR AFFILIATED COMPANY’S BUSINESS TO FULFIL
          THEIR BUSINESS GOALS: Company may share information from or about You
          with subsidiaries, joint ventures, or other companies under common
          control, in which case Company will require them to honor this Privacy
          Policy. (viii) TO ENFORCE Company's LICENSE AGREEMENTS AND TO COMPLY
          WITH LAW: (1) to enforce Company's rights against You or in connection
          with a breach by You of this Privacy Policy or the License Agreement;
          (2) to investigate or respond to suspected illegal or fraudulent
          activity or to protect the safety, rights, or property of us,
          Company's users, or others; (3) to prevent prohibited or illegal
          activities; (4) to prevent situations involving potential threats to
          the physical safety of any person; or (5) when required by any
          applicable law, rule, regulation, subpoena, or other legal process.
          (ix) TO PROCESS PAYMENTS: To use certain services on the Product,
          Company may require debit or credit card account information (“Debit
          or Credit Card Information”). By submitting Your Debit or Credit Card
          Information through the Product, You expressly consent to sharing of
          Your information with third-party payment processors and other
          third-party service providers. (x) And to fulfill other purposes
          related to Company's Product, subject to Your explicit consent if
          consent is required by law. (b) USE OF COOKIES AND USAGE DATA: Company
          may use session Cookies and usage data to fulfil the Purpose, by
          tracking information about You as related to Your usage of the
          Product, and correlating to other personally identifiable information
          collected while on the Product or connected to Company's third party
          processors (as listed in Section 5 to this Privacy Policy entitled
          List Of “Third Party Providers And End- User Data Storage Providers”).
          Company may also use Cookies that are created by the Product to secure
          Your login session and to help ensure the security of Your account.
          (c) USE OF THIRD PARTIES TO ASSIST US TO IMPROVE THE PRODUCT AND TO
          ACHIEVE Company's BUSINESS GOALS: To fulfil the Purpose, Company may
          share Personal Information or with Company's affiliates, acquirers or
          third-party collaborator or vendors (as listed in Section 5 to this
          Privacy Policy entitled List Of “Third Party Providers And End-User
          Data Storage Providers”), subject to the following conditions: (i) USE
          LIMITED TO SERVICE PROVIDED OR PURPOSE OF TRANSFER: Company's service
          providers are restricted from using Your Personal Information in any
          way other than for the service they are providing or as it relates to
          Company's Purpose fulfilled by such transfer; this includes the use of
          Cookies by Company's third parties so long as the use on such Cookies
          is to collect the same type of information for the same purposes as
          the Purpose. (ii) THIRD PARTIES MUST ADHERE TO Company's STANDARDS:
          Company ensures that such third parties maintain reasonable and
          appropriate safeguards that do not breach Company's safeguards of
          security requirements set out In Section 3 of this Privacy Policy
          entitled “Data Management”, or as otherwise required by law. If the
          use of Cookies by any third party differs materially from the
          practices already listed, Company will revise this document
          accordingly and notify existing users of the change(s). (d) RIGHTS TO
          CONTENT PROVIDED BY THE END-USER (i) FOR INFORMATION YOU PROVIDE. By
          posting content on the Application (the “User Data”), the End-User and
          Client jointly hereby grants to the Company a worldwide,
          non-revocable, non-exclusive, perpetual, royalty-free, and sub-
          licensable right to use, create derivative works of, modify, and to
          distribute (including without limitation, distribution online, through
          multiple channels, and bundled with other applications or materials)
          such content, and further, the agrees to waive any moral rights to
          such User Data, and agrees that the Company may modify or adapt the
          User Data in order to transmit, display or distribute it over other
          applications and in various media. The Client and/or End- User agrees
          that the Client and/or End-User will individually and jointly defend,
          indemnify and hold harmless the Company from and against any Claims
          (as defined in the Agreement) arising from the nature of the content
          submitted and/or the ownership of End-User Data and any claims of
          infringement of third party intellectual property related to such
          End-User Data. (ii) FOR INFORMATION WE AUTOMATICALLY COLLECT. Company
          Collection and Analysis of Data. The Company creates benefit to all of
          its Clients and End-Users by analyzing the Company Data for the
          purposes of Product improvements. The Client and/or End-User agrees
          that the Company shall have the right to collect and analyze data and
          other information relating to the provision, use and performance of
          various aspects of the Company products and related systems and
          technologies, and the Company will be free (during and after the
          Initial Term or subsequent Renewal Term) to (i) use such information
          and data to improve and enhance the Company products generally, (ii)
          for other development, diagnostic and corrective purposes in
          connection with the Products and Services, and (ii) disclose such data
          solely in aggregate, anonymous, and non-identifiable form that is in
          no way connected Client and/or End-User or its business.
        </Paragraph>

        <Heading className="mt-3 mb-3" id="5" level={4}>
          5. PROVIDERS
        </Heading>

        <Paragraph size="S">
          <ol className="mt-1 ml-4 list-decimal">
            <li className="mb-[4px]">Stripe</li>
            <li className="mb-[4px]">Amazon Web Services</li>
            <li className="mb-[4px]">OneSignal</li>
            <li className="mb-[4px]">Google Analytics</li>
            <li className="mb-[4px]">Circl CI</li>
            <li className="mb-[4px]">Github</li>
            <li className="mb-[4px]">Facebook</li>
            <li className="mb-[4px]">Google</li>
          </ol>
        </Paragraph>
      </Content>
    </Layout>
  );
};
